import { useState } from "react";
import { City, dropDownValueType, Education } from "../Models";

const useEducationState = () => {
  const [isStudent, setIsStudent] = useState(false);
  const [universityName, setUniversityName] = useState<
    dropDownValueType | undefined
  >({ id: "", name: "" });
  const [newUniversityName, setNewUniversityName] = useState<string>("");
  const [eduStartDate, setEduStartDate] = useState<Date | null>(null);
  const [eduEndDate, setEduEndDate] = useState<Date | null>(null);
  const [expectedGraduation, setExpectedGraduation] = useState<Date | null>(
    null,
  );
  const [isPostgraduate, setIsPostgraduate] = useState<boolean>(false);
  const [isRelevantEducation, setIsRelevantEducation] = useState<boolean>(true);
  const [relevantEducation, setRelevantEducation] = useState<string>("");
  const [newUniversityUrl, setnewUniversityUrl] = useState<string>("");
  const [degreeType, setDegreeType] = useState<dropDownValueType | undefined>({
    id: "",
    name: "",
  });
  const [univCity, setUnivCity] = useState<City | undefined>();
  const [univClosestCity, setUnivClosestCity] = useState<City | undefined>();
  const [univSubCity, setUnivSubCity] = useState<string>("");
  const [showDescription, setShowDescription] = useState(false);
  const [education, setEducation] = useState<Education[]>([]);
  const [isBefore21, setIsBefore21] = useState<boolean>(false);
  const [isUGDurationCorrect, setIsUGDurationCorrect] =
    useState<boolean>(false);
  const [isPGDurationCorrect, setIsPGDurationCorrect] =
    useState<boolean>(false);
  const [deleteUG, setDeleteUG] = useState<boolean>(false);
  const [changePGStatus, setChangePGStatus] = useState<boolean>(false);
  const [pgStatus, setPGStatus] = useState("");
  const [isInternshipAllowed, setIsInternshipAllowed] = useState<string>("");

  return {
    isStudent,
    setIsStudent,
    universityName,
    setUniversityName,
    newUniversityName,
    setNewUniversityName,
    eduStartDate,
    setEduStartDate,
    eduEndDate,
    setEduEndDate,
    expectedGraduation,
    setExpectedGraduation,
    isPostgraduate,
    setIsPostgraduate,
    isRelevantEducation,
    setIsRelevantEducation,
    relevantEducation,
    setRelevantEducation,
    newUniversityUrl,
    setnewUniversityUrl,
    degreeType,
    setDegreeType,
    univCity,
    setUnivCity,
    univClosestCity,
    setUnivClosestCity,
    univSubCity,
    setUnivSubCity,
    showDescription,
    setShowDescription,
    education,
    setEducation,
    isBefore21,
    setIsBefore21,
    isUGDurationCorrect,
    setIsUGDurationCorrect,
    isPGDurationCorrect,
    setIsPGDurationCorrect,
    deleteUG,
    setDeleteUG,
    changePGStatus,
    setChangePGStatus,
    pgStatus,
    setPGStatus,
    isInternshipAllowed,
    setIsInternshipAllowed,
  };
};

export default useEducationState;
