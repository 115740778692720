import { useState } from "react";
import { City, dropDownValueType, Education } from "../../../../Models";

const useShippingEducationStates = () => {
  const [isStudent, setIsStudent] = useState(false);
  const [universityName, setUniversityName] = useState<
    dropDownValueType | undefined
  >({ id: "", name: "" });
  const [newUniversityName, setNewUniversityName] = useState<string>("");
  const [eduStartDate, setEduStartDate] = useState<Date | null>(null);
  const [eduEndDate, setEduEndDate] = useState<Date | null>(null);
  const [expectedGraduation, setExpectedGraduation] = useState<Date | null>(
    null,
  );
  const [weeklyAvailability, setWeeklyAvailability] = useState<
    number | undefined
  >();
  const [isPostgraduate, setIsPostgraduate] = useState<boolean>();
  const [isRelevantEducation, setIsRelevantEducation] = useState<boolean>(true);
  const [relevantEducation, setRelevantEducation] = useState<string>("");
  const [newUniversityUrl, setnewUniversityUrl] = useState<string>("");
  const [instituteName, setInstituteName] = useState<string>("");
  const [degreeType, setDegreeType] = useState<dropDownValueType | undefined>({
    id: "",
    name: "",
  });
  const [studyField, setStudyField] = useState<dropDownValueType | undefined>({
    id: "",
    name: "",
  });
  const [univCity, setUnivCity] = useState<City | undefined>();
  const [univClosestCity, setUnivClosestCity] = useState<City | undefined>();
  const [univSubCity, setUnivSubCity] = useState<string>("");
  const [showDescription, setShowDescription] = useState(false);
  const [education, setEducation] = useState<Education[]>([]);
  const [instituteType,setInstituteType] = useState<dropDownValueType>();
  const [newStudyFieldName, setNewStudyFieldName] = useState<string>("");
  const [studyFieldValues, setStudyFieldValues] = useState<dropDownValueType[]>(
    [
      { id: "1", name: "Maritime Studies" },
      { id: "1", name: "Graduate Marine Engineering" },
      { id: "2", name: "Marine Engineering" },
      { id: "3", name: "Nautical Science" },
      { id: "4", name: "International Transport and Logistics" },
      { id: "5", name: "Business Administration" },
      { id: "6", name: "Supply Chain Management" },
      { id: "6", name: "Marine Environmental Science" },
      { id: "6", name: "Customs and Trade Compliance" },
    ],
  );

  return {
    isStudent,
    setIsStudent,
    universityName,
    setUniversityName,
    newUniversityName,
    setNewUniversityName,
    eduStartDate,
    setEduStartDate,
    eduEndDate,
    setEduEndDate,
    expectedGraduation,
    setExpectedGraduation,
    weeklyAvailability,
    setWeeklyAvailability,
    isPostgraduate,
    setIsPostgraduate,
    isRelevantEducation,
    setIsRelevantEducation,
    relevantEducation,
    setRelevantEducation,
    newUniversityUrl,
    setnewUniversityUrl,
    degreeType,
    setDegreeType,
    univCity,
    setUnivCity,
    univClosestCity,
    setUnivClosestCity,
    univSubCity,
    setUnivSubCity,
    showDescription,
    setShowDescription,
    education,
    setEducation,
    studyField,
    setStudyField,
    instituteName,
    setInstituteName,
    instituteType,
    setInstituteType,
    newStudyFieldName,
    setNewStudyFieldName,
    studyFieldValues
  };
};

export default useShippingEducationStates;
