import { ChevronRightIcon } from "@heroicons/react/20/solid";
import mariospic from "../../../img/marios.jpg";
import tonypic from "../../../img/anthony.jpg";
import mitalipic from "../../../img/mitali.jpg";
import omirospic from "../../../img/omiros.jpg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { Role } from "../../../Models";
import { GetRoles } from "../../../WebCalls";
 
export default function Home() {
  const [roles, setRoles] = useState<Role[]>([]);
 
  useEffect(() => {
    GetRoles().then((roles) => setRoles(roles));
  }, []);
 
  const people = [
    {
      name: "Omiros Papakonstantinou",
      role: "Chief Executive Officer",
      imageUrl: omirospic,
      bio: "Omiros is a London-based experienced investor with legal training. With an extensive network of contacts, Omiros’ diverse investments include shipping, luxury real estate and other ventures with high-net-worth individuals, through which he brings a client-centric perspective to the issues commonly encountered by businesses: skills shortage, optimisation and scalability.\n\nOmiros holds a Bachelor of Laws and Master of Laws from the University of Leicester and his legal experience includes acting on one of the most significant recent civil court cases in England.",
    },
    {
      name: "Anthony Matsis",
      role: "Chief Operating Officer",
      imageUrl: tonypic,
      bio: "Anthony is a seasoned Australian lawyer with a decade of experience working for top-tier and Magic Circle law firms, including secondments at a private fund and a leading bank, with a focus on cross-border transactions. As a globetrotter from a young age and with diverse experience working across the world, he brings insights into cross-cultural dynamics and an understanding of the impact of globalisation on international markets.\n\nAnthony holds a Bachelor of Arts from McGill University, a Master of Science from the London School of Economics & Political Science and a Juris Doctor from the University of Sydney. He has experience working in various jurisdictions, including Australia, Qatar, Türkiye and the United Arab Emirates.",
    },
    {
      name: "Marios Sarrigiannis",
      role: "Partner",
      imageUrl: mariospic,
      bio: "Marios is an accomplished software engineer with several milestones in his nascent career to date. From assuming the role of Technical Lead and managing ten developers located worldwide at a well-established shipping software company, Marios joined Microsoft where, through its collaboration with Open AI, he was at the forefront of AI work that included developing and integrating customised AI co-pilots for clients.\n\nMarios holds a Bachelor of Science in Computer Science and Telecommunications from the National and Kapodistrian University of Athens and a Master of Science in Artificial Intelligence from the National Centre for Scientific Research “Demokritos” and the University of Piraeus.",
    },
    {
      name: "Mitali Joshi",
      role: "Commercial Director",
      imageUrl: mitalipic,
      bio: "Mitali started her professional career at ENTNT in December 2021. Mitali's skill-set includes the full spectrum of business operations including recruitment, internal controls and finance with a focus on process improvement and scalability.\n\nMitali holds a Bachelor of Science in Banking & Finance offered by the University of London International Programmes with academic direction from the London School of Economics and Political Science at their affiliated teaching centre, Podar World College in Mumbai and is currently studying for a Master of Science in Strategic Entrepreneurship and Innovation from King's College London.",
    },
  ];
 
  return (
    <div>
      <div className="relative isolate overflow-hidden bg-gray-900 px-6 py-24 sm:py-32 lg:px-8">
        <img
          src="https://images.unsplash.com/photo-1521737604893-d14cc237f11d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&crop=focalpoint&fp-y=.8&w=2830&h=1500&q=80&blend=111827&sat=-100&exp=15&blend-mode=multiply"
          alt=""
          className="absolute inset-0 -z-10 h-full w-full object-cover"
        />
        <div
          className="hidden sm:absolute sm:-top-10 sm:right-1/2 sm:-z-10 sm:mr-10 sm:block sm:transform-gpu sm:blur-3xl"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#F88201] to-[#0D42EC] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div
          className="absolute -top-52 left-1/2 -z-10 -translate-x-1/2 transform-gpu blur-3xl sm:top-[-28rem] sm:ml-16 sm:translate-x-0 sm:transform-gpu"
          aria-hidden="true"
        >
          <div
            className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-tr from-[#F88201] to-[#0D42EC] opacity-20"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-4xl font-bold font-display tracking-tight text-white sm:text-6xl">
            Work with us
          </h2>
          <p className="mt-6 text-lg leading-8 text-white">
            Join us as we transform remote work!
          </p>
        </div>
      </div>
 
      <div className="bg-white px-6 py-32 lg:px-8" id="about">
        <div className="mx-auto max-w-3xl text-base leading-7 text-gray-700">
          <h2 className="text-2xl mb-20 font-display font-bold tracking-tight text-center text-entntblue sm:text-6xl justify-self-center">
            About us
          </h2>
          <p className="mt-6 text-xl leading-8 text-justify">
            ENTNT is a complete workforce solutions provider, offering an
            integrated remote service that combines recruitment, training and
            management of personnel alongside customised software and AI to
            allow businesses to optimise or grow their operations. Combined with
            our own recruitment and corporate memory tools, we use the latest
            research and data management to help businesses with their
            resourcing, scaling and project requirements.
          </p>
          <p className="mt-6 text-xl leading-8 text-justify">
            With an ongoing mandate to a large New York property management,
            ENTNT is actively engaged in both broadening its service portfolio
            and expanding its clientele across diverse industries.
          </p>
          <p className="mt-6 text-xl leading-8 text-justify">
            Our founding team comprises of cosmopolitan individuals with a
            background in shipping, software, business and law and credentials
            from top-tier employers, including former Microsoft engineers. ENTNT
            is well capitalised from its founders, allowing it to operate as an
            independent company with the ability to steadfastly uphold its
            strategy and values, unencumbered by compromises from external
            funding partners.
          </p>
          <p className="mt-6 text-xl leading-8 text-justify">
            As a fully remote company and with a diverse ethos, we select top
            talents worldwide based on competency. We prioritize individuals who
            exemplify respect for colleagues, collaborate toward shared
            objectives and commit to deliver excellence in their work.
          </p>
        </div>
      </div>
 
      <div className="bg-white" id="leadership">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl sm:text-center">
            <h2 className="text-2xl font-display font-bold tracking-tight text-center text-entntblue sm:text-6xl justify-self-center">
              Meet our leadership
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              We're a dynamic group of individuals who are passionate about what
              we do and dedicated to delivering the best results for our
              clients.
            </p>
          </div>
          <ul className="mx-auto mt-20 mb-20 grid max-w-2xl grid-cols-1 gap-x-6 gap-y-20 sm:grid-cols-2 lg:max-w-4xl lg:gap-x-8 xl:max-w-none">
            {people.map((person) => (
              <li key={person.name}>
                <img
                  className="aspect-[4/5] w-96 mx-auto rounded-2xl object-cover"
                  src={person.imageUrl}
                  alt=""
                />
                <h3 className="mt-6 text-lg font-semibold leading-8 text-gray-900">
                  {person.name}
                </h3>
                <p className="text-base leading-7 text-gray-600">
                  {person.role}
                </p>
                <p className="mt-4 text-base leading-7 text-gray-600 whitespace-pre-line text-justify">
                  {person.bio}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
 
      <h2
        id="roles"
        className="text-2xl mt-20 mb-20 font-display font-bold tracking-tight text-center text-entntblue sm:text-6xl justify-self-center"
      >
        Open Roles
      </h2>
      {roles.filter((job) => job.isActive && !job.isOnCampus).length !== 0 ? (
        <>
          {roles.filter(
            (job) => job.isActive && job.department === "IT" && !job.isOnCampus,
          ).length !== 0 && (
            <div className="px-6 ml-[8rem] text-gray-600">IT</div>
          )}
          <ul className="mt-6 mb-20 px-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
            {roles
              .filter(
                (job) =>
                  job.isActive && job.department === "IT" && !job.isOnCampus,
              )
              .map((job) => (
                <Link to={`/role/${job.id}/overview`} key={job.id}>
                  <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                    <div className="flex w-full items-center justify-between space-x-6 p-6">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="truncate text-sm font-medium text-gray-900">
                            {job.title}
                          </h3>
                        </div>
                        <p className="mt-1 truncate text-sm text-gray-500">
                          {job.employmentType}
                        </p>
                      </div>
                      <p className="text-sm text-gray-500">Apply</p>
                      <ChevronRightIcon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                </Link>
              ))}
          </ul>
          {roles.filter((job) => job.isActive && job.department === "Shipping")
            .length !== 0 && (
            <div className="px-6 ml-[8rem] text-gray-600">Shipping</div>
          )}
          <ul className="mt-6 mb-20 px-6 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
            {roles
              .filter(
                (job) =>
                  job.isActive &&
                  job.department === "Shipping" &&
                  !job.isOnCampus,
              )
              .map((job) => (
                <Link to={`/role/${job.id}/overview`} key={job.id}>
                  <li className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
                    <div className="flex w-full items-center justify-between space-x-6 p-6">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="truncate text-sm font-medium text-gray-900">
                            {job.title}
                          </h3>
                        </div>
                        <p className="mt-1 truncate text-sm text-gray-500">
                          {job.employmentType}
                        </p>
                      </div>
                      <p className="text-sm text-gray-500">Apply</p>
                      <ChevronRightIcon
                        className="h-5 w-5 flex-none text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                </Link>
              ))}
          </ul>
        </>
      ) : (
        <div className="sm:mt-6 sm:mb-20 px-6 max-w-4xl mx-auto text-center text-gray-600">
          <h1>
            We appreciate your interest, however, there are currently no
            openings available. We encourage you to revisit our site in the
            future for any updates on new opportunities.
          </h1>
        </div>
      )}
    </div>
  );
}
