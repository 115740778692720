import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import useCareerTimelineState from "../../../hooks/useCareerTimelineState";
import { CareerTimelineHeader } from "../application/CareerTimelineHeader";
import { DummyTimeline } from "../../shared/DummyTimeline";
import { TimelineColorInfo } from "../../shared/TimelineColorInfo";
import {
  calculatePeriodOfEducation,
  calculatePeriodOfExperience,
  careerWithGap,
  combinedTimelineData,
  getColorShade,
  hasCareerGap,
  hasExpectedGradDate,
  hasNoCurrentExperiences,
  hasOverlappingEducation,
  hasOverlappingExpinEdu,
  isOverlappingEducation,
  isValidUrl,
  previewShippingCareerData,
  validatePersonalInfo,
} from "../../../Helpers";
import {
  Candidate,
  City,
  Company,
  Education,
  Experience,
  PreviewEducation,
  PreviewExperience,
  ShippingExperience,
  University,
  dropDownValueType,
} from "../../../Models";
import useCityandState from "../../../hooks/useCityandState";
import { WorkExperience } from "./Components/WorkExperience";
import {
  checkUniversityExists,
  GetCities,
  GetCompanies,
  getRank,
  GetRoles,
  GetStates,
  PostCandidate,
  PostCity,
  PostCompany,
  PostUniversity,
  removeEducation,
  removeExperience,
  updatedCandidate,
} from "../../../WebCalls";
import { ProfileHeader } from "../application/ProfileHeader";
import { CareerOverview } from "../application/CareerOverview";
import ConfirmModal from "../../shared/ConfirmModal";
import useIdToken from "../../../hooks/useIdToken";
import { ShippingEducation } from "./Components/ShippingEducation";
import { useNavigate, useParams } from "react-router-dom";
import useCandidateProfile from "../../../hooks/useCandidateProfile";
import ActionButtons from "../../shared/ActionButtons";
import OverlappingGapConfirmModal from "../../shared/OverlappingGapConfirmModal";
import useShippingEducationStates from "./Hooks/useShippingEducationStates";
import SerpentineTimeline from "./Components/SerpentineTimeline";
import StraightTimeline from "./Components/StraightTimeline";
import Stepper from "../../shared/Stepper";
import useShippingExperienceState from "./Hooks/useShippingCareerExp";
import { ResumeUpload } from "../application/ResumeUpload";
import useShippingCareerAction from "../../../hooks/useShippingCareerAction";
import { ShippingProfileHeader } from "./Components/ShippingProfileHeader";
import { Spinner } from "../../shared/Spinner";

function ShippingApplication() {
  const [openDeclaration, setOpenDeclaration] = useState(false);
  const cityandState = useCityandState();
  const [openError, setOpenError] = useState(false);
  const [showExpEdit, setShowExpEdit] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showEduEdit, setshowEduEdit] = useState(false);
  const [selectedCareerId, setSelectedCareerId] = useState<string>("");
  const { cities, setCities, state, setState, setStates } = cityandState;
  const [updateNotification, setUpdateNotification] = useState(false);
  const [deleteCareer, setDeleteCareer] = useState(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [showCV, setShowCV] = useState<boolean>(false);
  const [showSubcityConfirmation, setShowSubcityConfirmation] = useState(false);
  const [isOverlappingEdu, setIsOverlappingEdu] = useState<boolean>(false);
  const [hasOverlappingEdu, setHasOverlappingEdu] = useState<boolean>(false);
  const [isOverlappingExp, setIsOverlappingExp] = useState<boolean>(false);
  const [hasOverlappingExp, setHasOverlappingExp] = useState<boolean>(false);
  const [isOverlappingGap, setIsOverlappingGap] = useState<boolean>(false);
  const [loading, setloading] = useState(false);
  const renderStep1 =
    windowWidth >= 1024 || (windowWidth < 1024 && currentStep === 1);
  const renderStep2 =
    windowWidth >= 1024 || (windowWidth < 1024 && currentStep === 2);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [cvFileName, setCvFileName] = useState<string>("");

  type RoleParams = {
    id: string;
  };
  const idToken = useIdToken();
  const { id } = useParams<RoleParams>();

  const educationState = useShippingEducationStates();
  const {
    isStudent,
    setIsStudent,
    universityName,
    setUniversityName,
    newUniversityName,
    setNewUniversityName,
    eduStartDate,
    setEduStartDate,
    eduEndDate,
    setEduEndDate,
    expectedGraduation,
    setExpectedGraduation,
    weeklyAvailability,
    setWeeklyAvailability,
    isPostgraduate,
    setIsPostgraduate,
    isRelevantEducation,
    setIsRelevantEducation,
    setRelevantEducation,
    newUniversityUrl,
    setnewUniversityUrl,
    setDegreeType,
    univCity,
    setUnivCity,
    univClosestCity,
    setUnivClosestCity,
    univSubCity,
    setUnivSubCity,
    instituteName,
    studyField,
    instituteType,
    setInstituteType,
    newStudyFieldName,
    education,
    setEducation,
    setStudyField,
    studyFieldValues,
  } = educationState;

  const experienceState = useShippingExperienceState();
  const {
    companyName,
    setCompanyName,
    newCompanyName,
    setNewCompanyName,
    expStartDate,
    setExpStartDate,
    expEndDate,
    setExpEndDate,
    noticePeriod,
    setNoticePeriod,
    relevantExperience,
    setRelevantExperience,
    isRelevantExperience,
    setIsRelevantExperience,
    title,
    setTitle,
    newCompanyUrl,
    setnewCompanyUrl,
    companiesData,
    setCompaniesData,
    isSelfEmployed,
    setisSelfEmployed,
    notMentioned,
    setnotMentioned,
    noUrl,
    setnoUrl,
    setShowDescription,
    compCity,
    setCompCity,
    compClosestCity,
    setCompClosestCity,
    compSubCity,
    setCompSubCity,
    currentlyWorking,
    setCurrentlyWorking,
    isMaritime,
    isOnboard,
    ratingState,
    shipType,
    shippingDepartment,
    ratingDepartmentState,
    experience,
    setExperience,
    setIsOnboard,
    setIsMaritime,
    setRatingDepartment,
    setRatingDepartmentState,
    setShippingDepartment,
    setRatingState,
    ratingDepartment,
    ratings,
    setShipType,
    shipTypes,
    shippingDepartments,
  } = experienceState;

  const careerTimelineStates = useCareerTimelineState();
  const {
    firstName,
    lastName,
    dob,
    email,
    cv,
    setCV,
    workLocations,
    linkedinProfile,
  } = careerTimelineStates;

  const timelineData = useMemo(
    () => combinedTimelineData(education, experience),
    [education, experience],
  );

  const previewCareer = useMemo(
    () => previewShippingCareerData(education, experience),
    [education, experience],
  );

  const { Apply, Submit, createShippingModals, updateShippingCandidate } =
    useShippingCareerAction(
      careerTimelineStates,
      educationState,
      experienceState,
      cityandState,
      setOpenError,
    );

  function onSubmit() {
    Submit(setOpenDeclaration, setloading);
  }

  const modalFields = createShippingModals({
    openDeclaration,
    setOpenDeclaration,
    onSubmit,
    openError,
    setOpenError,
    showSubcityConfirmation,
    setShowSubcityConfirmation,
    isOverlappingEdu,
    setIsOverlappingEdu,
    isOverlappingExp,
    setIsOverlappingExp,
    deleteCareer,
    setDeleteCareer,
    removeCareer,
    hasOverlappingEdu,
    setHasOverlappingEdu,
    showCV,
    setShowCV,
    hasOverlappingExp,
    setHasOverlappingExp,
    cvFileName,
    setCvFileName,
  });

  const dataLength = useMemo(
    () => education.length + experience.length,
    [education, experience],
  );

  const timeline = useMemo(
    () => careerWithGap(education, experience),
    [education, experience],
  );

  function onApply() {
    Apply(
      timelineData,
      setIsOverlappingGap,
      idToken,
      setOpenDeclaration,
      onEditSave,
      setHasOverlappingEdu,
      setShowCV,
      timeline,
    );
  }

  const clearExpStates = () => {
    setCompanyName(undefined);
    setNewCompanyName("");
    setExpStartDate(null);
    setExpEndDate(null);
    setIsRelevantExperience(true);
    setRelevantExperience("");
    setTitle("");
    setnewCompanyUrl("");
    setnoUrl(false);
    setisSelfEmployed(false);
    setnotMentioned(false);
    setState(undefined);
    setShowDescription(false);
    setSelectedCareerId("");
    setCompCity({ id: "", name: "" });
    setCompSubCity("");
    setCompClosestCity(undefined);
    setCurrentlyWorking(false);
    setRatingDepartmentState(undefined);
    setRatingState(undefined);
    setShipType(undefined);
  };

  const clearEduStates = () => {
    setUniversityName({ id: "", name: "" });
    setNewUniversityName("");
    setEduStartDate(null);
    setEduEndDate(null);
    setDegreeType(undefined);
    setIsPostgraduate(false);
    setIsRelevantEducation(true);
    setRelevantEducation("");
    setnewUniversityUrl("");
    setState(undefined);
    setSelectedCareerId("");
    setUnivCity({ id: "", name: "" });
    setUnivSubCity("");
    setUnivClosestCity(undefined);
    setInstituteType(undefined);
  };

  useEffect(() => {
    GetStates().then((data) => setStates(data));

    GetCities().then((data) => {
      const confirmedLocation = data.filter(
        (confirmedLoc) => confirmedLoc.isConfirmed,
      );
      setCities(confirmedLocation);
    });
    GetRoles().then((data) => {
      const currentDate = new Date(data[0].currentDate);
      localStorage.setItem("currentDate", currentDate.toDateString());
    });

    return () => localStorage.removeItem("currentDate");
  }, []);

  function removeCareer() {
    setEducation(education.filter((e) => e.id !== selectedCareerId));
    setExperience(experience.filter((e) => e.id !== selectedCareerId));
    setSelectedCareerId("");
    setDeleteCareer(false);
  }

  async function onEditSave() {
    updateShippingCandidate(idToken, setUpdateNotification, setloading);
  }

  const goToNextStep = () => {
    if (currentStep < 3) {
      if (
        (currentStep === 1 && firstName.trim().length === 0) ||
        lastName.trim().length === 0 ||
        email.trim().length === 0 ||
        cv.trim().length === 0 ||
        !dob ||
        workLocations.length === 0
      ) {
        setOpenError(true);
        return;
      }

      if (currentStep === 2 && education.length === 0) {
        setOpenError(true);
        return;
      }
      setCurrentStep(currentStep + 1);
      clearEduStates();
      clearExpStates();
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0 && currentStep <= 3) {
      setCurrentStep(currentStep - 1);
      clearEduStates();
      clearExpStates();
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    setIsMobile(window.innerWidth <= 768);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  function onRemoveCareer(career: PreviewEducation | PreviewExperience) {
    setEducation(education.filter((e) => e.id !== career.id));
    setExperience(experience.filter((e) => e.id !== career.id));
  }

  const onEditCareer = async (career: PreviewEducation | PreviewExperience) => {
    setSelectedCareerId(career.id);

    if ("universityName" in career) {
      const edu = education.find((edu: Education) => edu.id === career.id);
      let existingUni = null;
      if (edu?.university?.id) {
        existingUni = await checkUniversityExists(edu.university.id);
      }

      setshowEduEdit(true);
      setShowExpEdit(false);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      if (edu?.endDate && edu?.endDate < today) {
        setIsStudent(false);
      } else {
        setIsStudent(true);
        edu?.endDate && setExpectedGraduation(edu?.endDate);
      }
      setUniversityName(career.universityName);
      if (!existingUni) {
        setUniversityName({ id: "0", name: "other" });
        edu?.university?.name && setNewUniversityName(edu?.university?.name);
      }
      edu?.startDate && setEduStartDate(edu?.startDate);
      edu?.endDate && setEduEndDate(edu?.endDate);

      edu?.isMaritimeAcademy !== undefined &&
        setInstituteType(() =>
          edu.isMaritimeAcademy
            ? { id: "1", name: "Maritime Academy" }
            : { id: "2", name: "University" },
        );

      edu?.isMaritimeAcademy !== undefined &&
        edu?.isMaritimeAcademy === true &&
        setStudyField(
          studyFieldValues.find(
            (obj: dropDownValueType) => obj.name === edu.fieldofstudy,
          ),
        );

      setIsPostgraduate(career.degreeType);

      setDegreeType(() =>
        career.degreeType
          ? { id: "2", name: "Postgraduate" }
          : { id: "1", name: "Undergraduate" },
      );

      setIsRelevantEducation(career.isRelevant);
      career.comment && setRelevantEducation(career.comment);
      edu?.university.url && setnewUniversityUrl(edu?.university.url);
      edu?.university.state && setState(edu?.university.state);
      if (
        edu?.university.city?.nearestCity?.trim() === "" ||
        edu?.university.city?.nearestCity === null
      ) {
        setUnivCity(edu?.university.city);
      } else {
        setUnivCity({ id: "0", name: "other" });
        setUnivClosestCity({
          id: edu?.university.city?.id ?? "",
          name: edu?.university.city?.nearestCity ?? "",
        });
        edu?.university.city?.nearestCity &&
          setUnivSubCity(edu?.university.city?.name);
      }
    } else {
      const exp = experience.find((exp) => exp.id === career.id);
      const existingCompany = companiesData.some(
        (comp) => comp.id === exp?.company.id,
      );

      const existingCity = cities.some(
        (city) => city.id === exp?.company.city?.id,
      );

      setShowExpEdit(true);
      setshowEduEdit(false);

      if (exp?.isMaritime) {
        setIsMaritime(true);
        setIsOnboard(!!exp.isOnboard);

        if (exp.isOnboard) {
          setRatingDepartmentState(
            ratingDepartment.find(
              (obj: dropDownValueType) => exp.department === obj.name,
            ),
          );
          setRatingState(
            ratings.Deck.find(
              (obj: dropDownValueType) => obj.name === exp.rank,
            ) ||
              ratings.Engine.find(
                (obj: dropDownValueType) => obj.name === exp.rank,
              ) ||
              ratings.Rating.find(
                (obj: dropDownValueType) => obj.name === exp.rank,
              ),
          );
          setShipType(
            shipTypes.find(
              (obj: dropDownValueType) => exp.shipType === obj.name,
            ),
          );
        } else {
          setShippingDepartment(
            shippingDepartments.find(
              (obj: dropDownValueType) => obj.name === exp.department,
            ),
          );
          setRelevantExperience(exp.description || "");
          setTitle(exp.rank || "");
        }
      } else {
        setIsMaritime(false);
        setTitle(exp?.title || "");
        setRelevantExperience(exp?.description || "");
      }

      if (exp?.company?.name === "Self Employed") {
        setCompanyName({ id: "0", name: "Other" });
        setnoUrl(true);
        setisSelfEmployed(true);
        setnotMentioned(false);
        exp?.description ? setShowDescription(true) : setShowDescription(false);
        setCompClosestCity({
          id: exp?.company.city?.id ?? "",
          name: exp?.company.city?.nearestCity ?? "",
        });
      } else if (career?.companyName?.name === "Not Mentioned") {
        setCompanyName({ id: "0", name: "Other" });
        setnoUrl(true);
        setShowDescription(true);
        setnotMentioned(true);
        setisSelfEmployed(false);
        setCompClosestCity({
          id: exp?.company.city?.id ?? "",
          name: exp?.company.city?.nearestCity ?? "",
        });
      } else if (!existingCompany) {
        setCompanyName({ id: "0", name: "Other" });
        career.companyName?.name && setNewCompanyName(career.companyName?.name);
        exp?.company.url && setnewCompanyUrl(exp?.company.url);
        if (existingCity) {
          setCompCity(exp?.company.city);
        } else {
          setCompCity({ id: "0", name: "other" });
          exp?.company.city?.name && setCompSubCity(exp?.company.city?.name);
          setCompClosestCity({
            id: exp?.company.city?.id ?? "",
            name: exp?.company.city?.nearestCity ?? "",
          });
        }
      } else {
        setnoUrl(false);
        setisSelfEmployed(false);
        setCompanyName(career.companyName);
        exp?.description ? setShowDescription(true) : setShowDescription(false);
        setIsRelevantExperience(career.isRelevant);
        career.comment && setRelevantExperience(career.comment);
        setCompCity(exp?.company.city);
      }
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      exp?.startDate && setExpStartDate(exp?.startDate);
      if (exp?.endDate && exp?.endDate < today) {
        setCurrentlyWorking(false);
        setExpEndDate(exp?.endDate);
        setNoticePeriod(0);
      } else {
        setCurrentlyWorking(true);
        setExpEndDate(new Date());
        setNoticePeriod(noticePeriod);
      }
      setState(exp?.company.state);
      setTitle(career.title);
    }
  };

  async function onAddExp(e: React.SyntheticEvent<HTMLButtonElement>) {
    const isOtherCompany = companyName?.name.toLowerCase() === "other";
    const isOtherCity = compCity?.name.toLowerCase() === "other";
    if (
      isMaritime
        ? isOnboard
          ? !ratingState || !shipType
          : !shippingDepartment || !relevantExperience
        : !relevantExperience ||
          (isOtherCompany && noUrl && !isSelfEmployed && !notMentioned) ||
          (!isOnboard &&
            isOtherCompany &&
            noUrl &&
            notMentioned &&
            !relevantExperience) ||
          (isOtherCompany && !noUrl
            ? !newCompanyName || !newCompanyUrl || !isValidUrl(newCompanyUrl)
            : !companyName?.name) ||
          (!isMaritime && !relevantExperience) ||
          (currentlyWorking ? !expStartDate : !expStartDate && !expEndDate)
    ) {
      setOpenError(true);
      return;
    }

    let newid = uuidv4();
    let compCityId = uuidv4();
    let selectedCompany = null;

    if (isSelfEmployed) {
      selectedCompany = { id: newid, name: "Self Employed" };
    } else if (notMentioned) {
      selectedCompany = { id: newid, name: "Not Mentioned" };
    }
    const url =
      (companyName || selectedCompany) &&
      (companiesData.find((company) => company.id === companyName?.id)?.url ??
        "");

    const existingExpIndex = experience.findIndex(
      (exp) => exp.id === selectedCareerId,
    );

    if (existingExpIndex === -1) {
      const companyData =
        newCompanyName === ""
          ? selectedCompany
            ? {
                id: selectedCompany?.id ?? "",
                name: selectedCompany?.name ?? "",
                state: state,
                city:
                  compSubCity.trim() === ""
                    ? compCity
                    : {
                        id: compCityId,
                        name: compSubCity,
                        nearestCity: compClosestCity?.name,
                        state: state,
                        isConfirmed: false,
                      },
                url: url ?? "",
              }
            : {
                id: companyName?.id ?? "",
                name: companyName?.name ?? "",
                state: state,
                city:
                  compSubCity.trim() === ""
                    ? compCity
                    : {
                        id: compCityId,
                        name: compSubCity,
                        nearestCity: compClosestCity?.name,
                        state: state,
                        isConfirmed: false,
                      },
                url: url ?? "",
              }
          : {
              id: newid,
              name: newCompanyName ?? "",
              state: state,
              city:
                compSubCity.trim() === ""
                  ? compCity
                  : {
                      id: compCityId,
                      name: compSubCity,
                      nearestCity: compClosestCity?.name,
                      state: state,
                      isConfirmed: false,
                    },
              url: newCompanyUrl ?? "",
            };

      const candidateExp: ShippingExperience = {
        id: uuidv4(),
        companyName: companyData?.name,
        title: title,
        period: expStartDate
          ? calculatePeriodOfExperience(
              expStartDate,
              expEndDate === null ? new Date() : expEndDate,
            ) ?? ""
          : "",
        description: relevantExperience,
        company: companyData,
        relevant: isRelevantExperience,
        startDate: expStartDate,
        endDate:
          expEndDate === null || currentlyWorking ? new Date() : expEndDate,
        isMaritime: isMaritime,
        isOnboard: isOnboard,
        department: isOnboard
          ? ratingDepartmentState?.name
          : shippingDepartment?.name,
        rank: ratingState?.name,
        shipType: shipType?.name,
        currentlyWorking:currentlyWorking
      };
      setExperience([...experience, candidateExp]);
    }

    clearExpStates();
    setShowExpEdit(false);
    window.scrollTo(0, 0);
  }

  async function onEditExp(e: React.SyntheticEvent<HTMLButtonElement>) {
    const isOtherCompany = companyName?.name.toLowerCase() === "other";
    const isOtherCity = compCity?.name.toLowerCase() === "other";

    if (
      isMaritime
        ? isOnboard
          ? !ratingState || !shipType
          : !shippingDepartment || !relevantExperience
        : !relevantExperience ||
          (isOtherCompany && noUrl && !isSelfEmployed && !notMentioned) ||
          (!isOnboard &&
            isOtherCompany &&
            noUrl &&
            notMentioned &&
            !relevantExperience) ||
          (isOtherCompany && !noUrl
            ? !newCompanyName || !newCompanyUrl || !isValidUrl(newCompanyUrl)
            : !companyName?.name) ||
          (!isMaritime && !relevantExperience) ||
          (currentlyWorking ? !expStartDate : !expStartDate && !expEndDate)
    ) {
      setOpenError(true);
      return;
    }

    let newid = uuidv4();
    let selectedCompany = null;

    if (isSelfEmployed) {
      selectedCompany = companiesData.find(
        (company) => company.name === "Self Employed",
      );
    } else if (notMentioned) {
      selectedCompany = companiesData.find(
        (company) => company.name === "Not Mentioned",
      );
    }

    const url =
      (companyName || selectedCompany) &&
      (companiesData.find((company) => company.id === companyName?.id)?.url ??
        "");

    const existingExpIndex = experience.findIndex(
      (exp) => exp.id === selectedCareerId,
    );

    if (existingExpIndex !== -1) {
      const companyData =
        newCompanyName === ""
          ? selectedCompany
            ? {
                id: selectedCompany?.id ?? "",
                name: selectedCompany?.name ?? "",
                state: state,
                city:
                  compSubCity.trim() === ""
                    ? compCity
                    : {
                        id:
                          experience.find((exp) => exp.id === selectedCareerId)
                            ?.company.city?.id ?? "",
                        name: compSubCity,
                        nearestCity: compClosestCity?.name,
                        state: state,
                      },
                url: url ?? "",
              }
            : {
                id: companyName?.id ?? "",
                name: companyName?.name ?? "",
                state: state,
                city:
                  compSubCity.trim() === ""
                    ? compCity
                    : {
                        id:
                          experience.find((exp) => exp.id === selectedCareerId)
                            ?.company.city?.id ?? "",
                        name: compSubCity,
                        nearestCity: compClosestCity?.name,
                        state: state,
                      },
                url: url ?? "",
              }
          : {
              id: newid,
              name: newCompanyName ?? "",
              state: state,
              city:
                compSubCity.trim() === ""
                  ? compCity
                  : {
                      id: uuidv4(),
                      name: compSubCity,
                      nearestCity: compClosestCity?.name,
                      state: state,
                      isConfirmed: false,
                    },
              url: newCompanyUrl ?? "",
            };

      const updatedExperience = [...experience];
      updatedExperience[existingExpIndex] = {
        id: selectedCareerId,
        companyName: "",
        title: title,
        period:
          expStartDate && expEndDate
            ? calculatePeriodOfExperience(
                expStartDate,
                expEndDate === null ? new Date() : expEndDate,
              ) ?? ""
            : "",
        description: relevantExperience,
        company: companyData,
        relevant: isRelevantExperience,
        startDate: expStartDate,
        endDate:
          expEndDate === null || currentlyWorking ? new Date() : expEndDate,
        isMaritime: isMaritime,
        isOnboard: isOnboard,
        department: isOnboard
          ? ratingDepartmentState?.name
          : shippingDepartment?.name,
        rank: ratingState?.name,
        shipType: shipType?.name,
        currentlyWorking:currentlyWorking
      };

      setExperience(updatedExperience);
    }

    clearExpStates();
    setShowExpEdit(false);
    window.scrollTo(0, 0);
  }

  async function onAddEdu(e: React.SyntheticEvent<HTMLButtonElement>) {
    const isOtherUniversity =
      universityName?.name.toLowerCase() === "other" || !universityName?.name;

    const isOtherCity = univCity?.name.toLowerCase() === "other";

    if (
      eduStartDate &&
      isOverlappingEducation(selectedCareerId, eduStartDate, education)
    ) {
      setIsOverlappingEdu(true);
      return;
    }

    if (
      (isOtherCity && !univSubCity) ||
      (isOtherUniversity
        ? !newUniversityName ||
          !newUniversityUrl ||
          !isValidUrl(newUniversityUrl)
        : !universityName?.name) ||
      !eduStartDate ||
      !eduEndDate
    ) {
      setOpenError(true);
      return;
    }

    let newid = uuidv4();
    let univCityId = uuidv4();

    const existingEducationIndex = education.findIndex(
      (edu) => edu.id === selectedCareerId,
    );

    if (existingEducationIndex === -1) {
      const universityData =
        universityName && newUniversityName === ""
          ? universityName
          : {
              id: newid,
              name: newUniversityName ?? "",
              url: newUniversityUrl ?? "",
              state: state,
              city:
                univSubCity.trim() === ""
                  ? univCity
                  : {
                      id: univCityId,
                      name: univSubCity,
                      nearestCity: univClosestCity?.name,
                      state: state,
                      isConfirmed: false,
                    },
              isConfirmed: false,
            };
      const candidateEdu: Education = {
        id: uuidv4(),
        school: "",
        degree: "",
        fieldofstudy: studyField
          ? studyField.name === "other"
            ? newStudyFieldName
            : studyField.name
          : "",
        period: calculatePeriodOfEducation(eduStartDate, eduEndDate),
        startDate: eduStartDate,
        endDate: eduEndDate,
        university: universityData,
        postGrad: isPostgraduate,
        relevant: isRelevantEducation,
        instituteName: universityData?.name,
        isMaritimeAcademy: instituteType
          ? instituteType.name == "Maritime Academy"
            ? true
            : false
          : undefined,
      };

      setEducation([...education, candidateEdu]);
    }

    clearEduStates();
    setshowEduEdit(false);
    window.scrollTo(0, 0);
  }

  async function onEditEdu(e: React.SyntheticEvent<HTMLButtonElement>) {
    const isOtherUniversity =
      universityName?.name.toLowerCase() === "other" || !universityName?.name;

    const isOtherCity = univCity?.name.toLowerCase() === "other";

    if (
      eduStartDate &&
      isOverlappingEducation(selectedCareerId, eduStartDate, education)
    ) {
      setIsOverlappingEdu(true);
      return;
    }

    if (
      (isOtherCity && !univSubCity) ||
      (isOtherUniversity
        ? !newUniversityName ||
          !newUniversityUrl ||
          !isValidUrl(newUniversityUrl)
        : !universityName?.name) ||
      !eduStartDate ||
      !eduEndDate ||
      (isStudent && (!expectedGraduation || !weeklyAvailability))
    ) {
      setOpenError(true);
      return;
    }

    const existingEducationIndex = education.findIndex(
      (edu) => edu.id === selectedCareerId,
    );

    if (existingEducationIndex !== -1) {
      const universityData =
        universityName && newUniversityName === ""
          ? universityName
          : {
              id: uuidv4(),
              name: newUniversityName ?? "",
              url: newUniversityUrl ?? "",
              state: state,
              city:
                univSubCity.trim() === ""
                  ? univCity
                  : {
                      id: uuidv4(),
                      name: univSubCity,
                      nearestCity: univClosestCity?.name,
                      state: state,
                      isConfirmed: false,
                    },
              isConfirmed: false,
            };
      const updatedEducation = [...education];

      updatedEducation[existingEducationIndex] = {
        ...updatedEducation[existingEducationIndex],
        school: "",
        degree: "",
        fieldofstudy: studyField
          ? studyField.name === "other"
            ? newStudyFieldName
            : studyField.name
          : "",
        period:
          eduStartDate && eduEndDate
            ? calculatePeriodOfEducation(eduStartDate, eduEndDate)
            : "",
        startDate: eduStartDate,
        endDate: eduEndDate,
        university: universityData,
        postGrad: isPostgraduate,
        relevant: isRelevantEducation,
        instituteName: instituteName,
        isMaritimeAcademy: instituteType
          ? instituteType.name == "Maritime Academy"
            ? true
            : false
          : undefined,
      };
      setEducation(updatedEducation);
    }

    clearEduStates();
    setshowEduEdit(false);
    window.scrollTo(0, 0);
  }
  return (
    <>
      <div className="lg:hidden pt-3">
        <Stepper currentStep={currentStep} />
      </div>
      <form className="md:block px-5 pt-6 max-w-[82rem] mx-auto">
        <div className="space-y-1">
          <div className="w-full flex flex-col border-b border-gray-900/10">
            {renderStep1 && (
              <CareerTimelineHeader
                careerTimelineStates={careerTimelineStates}
                cityandState={cityandState}
                openError={openError}
                setOpenError={setOpenError}
                timeline={timelineData}
                isStudentRole={false}
              />
            )}
            {windowWidth > 1024 && (
              <div
                className={`hidden lg:block w-full text-xs ${
                  previewCareer.length === 0 ? "py-16" : "pb-6"
                }`}
              >
                {timelineData.length === 0 ? (
                  <DummyTimeline />
                ) : (
                  <>
                    {dataLength <= 3 ? (
                      <StraightTimeline
                        timeline={timelineData}
                        isMobile={isMobile}
                      />
                    ) : (
                      <SerpentineTimeline
                        timeline={timelineData}
                        isMobile={isMobile}
                      />
                    )}
                    <TimelineColorInfo roleType="shipping" />
                  </>
                )}
                {renderStep2 && (
                  <CareerOverview
                    previewCareer={previewCareer}
                    onRemoveCareer={onRemoveCareer}
                    onEditCareer={onEditCareer}
                  />
                )}
              </div>
            )}
            {windowWidth < 1024 && currentStep === 3 && (
              <div
                className={`w-full text-xs ${
                  previewCareer.length === 0 ? "py-16" : "pb-6"
                }`}
              >
                {timelineData.length === 0 ? (
                  <DummyTimeline />
                ) : (
                  <>
                    {dataLength <= 3 ? (
                      <StraightTimeline
                        timeline={timelineData}
                        isMobile={isMobile}
                      />
                    ) : (
                      <SerpentineTimeline
                        timeline={timelineData}
                        isMobile={isMobile}
                      />
                    )}
                    <TimelineColorInfo roleType="shipping" />
                  </>
                )}
              </div>
            )}
            {renderStep2 && (
              <div
                className={`${
                  previewCareer.length >= 4 && !renderStep2 ? "pt-0" : "pt-4"
                }`}
              >
                <ShippingProfileHeader
                  showEduEdit={showEduEdit}
                  showExpEdit={showExpEdit}
                  setshowEduEdit={setshowEduEdit}
                  setShowExpEdit={setShowExpEdit}
                  clearEduStates={clearEduStates}
                  clearExpStates={clearExpStates}
                  validatePersonalInfo={validatePersonalInfo(
                    careerTimelineStates,
                  )}
                />
                <div>
                  {showEduEdit && (
                    <div className="mx-auto">
                      <div className="rounded-md bg-gray-100 px-6 py-4">
                        <ShippingEducation
                          educationState={educationState}
                          cityandState={cityandState}
                          inEditMode={selectedCareerId.trim().length !== 0}
                          setOpenError={setOpenError}
                          dob={dob}
                        />
                        <div className="pt-8 flex gap-4 justify-end">
                          <button
                            type="button"
                            onClick={() => {
                              setshowEduEdit(false);
                              setSelectedCareerId("");
                              clearEduStates();
                              window.scrollTo(0, 0);
                            }}
                            className="block rounded-md bg-red-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={
                              selectedCareerId.trim().length === 0
                                ? onAddEdu
                                : onEditEdu
                            }
                            className="block rounded-md bg-entntblue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  {showExpEdit && (
                    <div className="mx-auto">
                      <div className="rounded-md bg-gray-100 px-6 py-4">
                        <WorkExperience
                          experienceState={experienceState}
                          cityandState={cityandState}
                          setOpenError={setOpenError}
                          inEditMode={selectedCareerId.trim().length !== 0}
                          dob={dob}
                        />
                        <div className="pt-4 flex gap-4 justify-end">
                          <button
                            type="button"
                            onClick={() => {
                              setShowExpEdit(false);
                              setSelectedCareerId("");
                              clearExpStates();
                              window.scrollTo(0, 0);
                            }}
                            className="block rounded-md bg-red-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm"
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            onClick={
                              selectedCareerId.trim().length === 0
                                ? onAddExp
                                : onEditExp
                            }
                            className="rounded-md bg-entntblue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange"
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}

            <div className={`hidden lg:flex items-center justify-center pt-10`}>
              {loading ? (
                <Spinner />
              ) : (
                <button
                  hidden={showEduEdit || showExpEdit}
                  type="button"
                  className="w-32 rounded-md bg-entntblue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange"
                  onClick={onApply}
                >
                  {idToken?.candidateId ? "Save" : "Apply"}
                </button>
              )}
            </div>

          </div>
        </div>
      </form>
      <div className="lg:hidden" hidden={showEduEdit || showExpEdit}>
        <ActionButtons
          currentStep={currentStep}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          onApply={onApply}
        />
      </div>
      {modalFields.map((modal) => (
        <ConfirmModal
          key={modal.modalId}
          type={modal.type}
          open={modal.open}
          setOpen={modal.setOpen}
          title={modal.title}
          descriptionList={modal.descriptionList}
          description={modal.description}
          buttonContents={modal.buttonContents}
        />
      ))}
      <OverlappingGapConfirmModal
        open={isOverlappingGap}
        setOpen={setIsOverlappingGap}
        title="Clarifications in Education or Work Experience"
        type="danger"
        timelineData={timelineData}
        experienceState={experienceState}
        educationState={educationState}
        dob={dob}
        setShowCV={setShowCV}
        setHasOverlappingEdu={setHasOverlappingEdu}
        setOpenDeclaration={setOpenDeclaration}
        onEditSave={onEditSave}
      />
    </>
  );
}

export default ShippingApplication;
