import { useState } from "react";
import { City, Company, Experience } from "../Models";

const useExperienceState = () => {
  const [companyName, setCompanyName] = useState<Company>();
  const [newCompanyName, setNewCompanyName] = useState<string>("");
  const [expStartDate, setExpStartDate] = useState<Date | null>(null);
  const [expEndDate, setExpEndDate] = useState<Date | null>(null);
  const [noticePeriod, setNoticePeriod] = useState<number | undefined>();
  const [relevantExperience, setRelevantExperience] = useState<string>("");
  const [isRelevantExperience, setIsRelevantExperience] =
    useState<boolean>(true);
  const [title, setTitle] = useState<string>("");
  const [newCompanyUrl, setnewCompanyUrl] = useState<string>("");
  const [isSelfEmployed, setisSelfEmployed] = useState(false);
  const [notMentioned, setnotMentioned] = useState(false);
  const [noUrl, setnoUrl] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [compCity, setCompCity] = useState<City>();
  const [compClosestCity, setCompClosestCity] = useState<City | undefined>();
  const [compSubCity, setCompSubCity] = useState<string>("");
  const [currentlyWorking, setCurrentlyWorking] = useState<boolean>(false);
  const [compLocation, setCompLocation] = useState<City>();
  const [newCompLocation, setNewCompLocation] = useState<City>();
  const [compLocationClosestCity, setCompLocationClosestCity] =
    useState<City>();
  const [compLocationSubCity, setCompLocationSubCity] = useState<string>("");
  const [experience, setExperience] = useState<Experience[]>([]);
  const [specificExperienceId, setSpecificExperienceId] = useState<
    string | undefined
  >(undefined);
  const [internStatus, setInternStatus] = useState("");
  const [isInternDurationCorrect, setIsInternDurationCorrect] =
    useState<boolean>(false);
  const [changeInternStatus, setChangeInternStatus] = useState<boolean>(false);
  const [isDuplicateCurrentExp, setIsDuplicateCurrentExp] =
    useState<boolean>(false);
  const [isInternship, setIsInternship] = useState<boolean>(false);

  return {
    companyName,
    setCompanyName,
    newCompanyName,
    setNewCompanyName,
    expStartDate,
    setExpStartDate,
    expEndDate,
    setExpEndDate,
    noticePeriod,
    setNoticePeriod,
    relevantExperience,
    setRelevantExperience,
    isRelevantExperience,
    setIsRelevantExperience,
    title,
    setTitle,
    newCompanyUrl,
    setnewCompanyUrl,
    isSelfEmployed,
    setisSelfEmployed,
    notMentioned,
    setnotMentioned,
    noUrl,
    setnoUrl,
    showDescription,
    setShowDescription,
    compCity,
    setCompCity,
    compClosestCity,
    setCompClosestCity,
    compSubCity,
    setCompSubCity,
    currentlyWorking,
    setCurrentlyWorking,
    compLocation,
    setCompLocation,
    newCompLocation,
    setNewCompLocation,
    compLocationClosestCity,
    setCompLocationClosestCity,
    compLocationSubCity,
    setCompLocationSubCity,
    experience,
    setExperience,
    specificExperienceId,
    setSpecificExperienceId,
    internStatus,
    setInternStatus,
    isInternDurationCorrect,
    setIsInternDurationCorrect,
    changeInternStatus,
    setChangeInternStatus,
    isDuplicateCurrentExp,
    setIsDuplicateCurrentExp,
    isInternship,
    setIsInternship,
  };
};

export default useExperienceState;
