import React from "react";

interface TimelinePropsType{
   roleType?:String;
}
export const TimelineColorInfo = ({roleType}:TimelinePropsType) => {
  return (
    <div className="font-medium w-full flex items-center justify-center gap-4">
      <div className="flex gap-1 items-center">
        <div className="h-3 w-3 bg-entntorange rounded-full" />
        {roleType==="shipping"?
        <span> Post Graduate</span>
        :
        <span> Under Graduate</span>
        }
      </div>
      <div className="flex gap-1 items-center">
        <div className="h-3 w-3 bg-[#e1ad01] rounded-full" />
        {roleType==="shipping"?
        <span> Maritime Academy / Under Graduate </span>
        :
        <span> Post Graduate</span>
        }
      </div>
      <div className="flex gap-1 items-center">
        <div className="h-3 w-3 bg-entntblue rounded-full" />
        <span> Work Experience</span>
      </div>
      <div className="flex gap-1 items-center">
        <div className="h-3 w-3 bg-gray-500 rounded-full" />
        <span> Career Gap</span>
      </div>
    </div>
  );
};
