import { v4 as uuidv4 } from "uuid";

import {
  calculatePeriodOfEducation,
  calculatePeriodOfExperience,
  hasCareerGap,
  hasNoCurrentExperiences,
  hasOverlappingEducation,
  hasOverlappingExpinEdu,
  isOverlappingEducation,
  isValidUrl,
  transformEducationData,
  transformExperienceData,
  validateOnApply,
} from "../Helpers";
import useCityandState from "./useCityandState";
import useEducationState from "./useEducationState";
import {
  Candidate,
  City,
  Company,
  Education,
  Experience,
  idToken,
  PreviewEducation,
  PreviewExperience,
  University,
} from "../Models";
import useExperienceState from "./useExperienceState";
import {
  checkCompanyLocation,
  checkCompanyURLorName,
  checkUniversityExists,
  GetCities,
  GetStates,
  PostCandidate,
  PostCity,
  PostCompany,
  PostUniversity,
  removeEducation,
  removeExperience,
  updatedCandidate,
} from "../WebCalls";
import useCareerTimelineState from "./useCareerTimelineState";
import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useCandidateProfile from "./useCandidateProfile";
import { ResumeUpload } from "../components/pages/application/ResumeUpload";

const useCareerAction = (
  careerTimelineStates: ReturnType<typeof useCareerTimelineState>,
  educationState: ReturnType<typeof useEducationState>,
  experienceState: ReturnType<typeof useExperienceState>,
  cityandState: ReturnType<typeof useCityandState>,
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { id } = useParams<{
    id: string;
  }>();
  const navigate = useNavigate();
  const storedDate = localStorage.getItem("currentDate");
  const currentDate = storedDate && new Date(storedDate);

  const isStudentRoleString = localStorage.getItem("isStudentRole");
  const isStudentRole = isStudentRoleString === "true";

  const [openSuccess, setOpenSuccess] = useState(false);
  const [existingCandidate, setExistingCandidate] = useState(false);
  const [transformedExperience, setTransformedExperience] = useState<any>([]);
  const [isExceedingMaxGap, setIsExceedingMaxGap] = useState(false);
  const [transformedEducation, setTransformedEducation] = useState<any>([]);

  const {
    firstName,
    setFirstName,
    lastName,
    setLastName,
    dob,
    setDOB,
    email,
    setEmail,
    cv,
    setCV,
    workLocations,
    setWorkLocations,
    linkedinProfile,
    setLinkedinProfile,
    setInitialEmail,
    cvFileName,
    setCvFileName,
  } = careerTimelineStates;

  const {
    isStudent,
    setIsStudent,
    universityName,
    setUniversityName,
    newUniversityName,
    setNewUniversityName,
    eduStartDate,
    setEduStartDate,
    eduEndDate,
    setEduEndDate,
    expectedGraduation,
    setExpectedGraduation,
    isPostgraduate,
    setIsPostgraduate,
    isRelevantEducation,
    setIsRelevantEducation,
    setRelevantEducation,
    newUniversityUrl,
    setnewUniversityUrl,
    setDegreeType,
    univCity,
    setUnivCity,
    univClosestCity,
    setUnivClosestCity,
    univSubCity,
    setUnivSubCity,
    education,
    setEducation,
    pgStatus,
    setPGStatus,
    isInternshipAllowed,
  } = educationState;

  const {
    companyName,
    setCompanyName,
    newCompanyName,
    setNewCompanyName,
    expStartDate,
    setExpStartDate,
    expEndDate,
    setExpEndDate,
    noticePeriod,
    setNoticePeriod,
    relevantExperience,
    setRelevantExperience,
    isRelevantExperience,
    setIsRelevantExperience,
    title,
    setTitle,
    newCompanyUrl,
    setnewCompanyUrl,
    isSelfEmployed,
    setisSelfEmployed,
    notMentioned,
    setnotMentioned,
    noUrl,
    setnoUrl,
    showDescription,
    setShowDescription,
    compCity,
    setCompCity,
    compClosestCity,
    setCompClosestCity,
    compSubCity,
    setCompSubCity,
    currentlyWorking,
    setCurrentlyWorking,
    compLocation,
    setCompLocation,
    newCompLocation,
    setNewCompLocation,
    compLocationClosestCity,
    setCompLocationClosestCity,
    compLocationSubCity,
    setCompLocationSubCity,
    experience,
    setExperience,
    setSpecificExperienceId,
    isInternship,
    setIsInternship,
    internStatus,
    setInternStatus,
  } = experienceState;

  const {
    setCities,
    state,
    cities,
    setState,
    setStates,
    tempState,
    setTempState,
  } = cityandState;

  useEffect(() => {
    GetStates().then((data) => setStates(data));
    GetCities().then((data) => setCities(data));
  }, []);

  const applicationModalFields = (modalConfigs: {
    openDeclaration: boolean;
    setOpenDeclaration: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit: () => void;
    openError: boolean;
    setOpenError: React.Dispatch<React.SetStateAction<boolean>>;
    showSubcityConfirmation: boolean;
    setShowSubcityConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    isOverlappingEdu: boolean;
    setIsOverlappingEdu: React.Dispatch<React.SetStateAction<boolean>>;
    isOverlappingExp: boolean;
    setIsOverlappingExp: React.Dispatch<React.SetStateAction<boolean>>;
    deleteCareer: boolean;
    setDeleteCareer: React.Dispatch<React.SetStateAction<boolean>>;
    removeCareer: () => void;
    hasOverlappingEdu: boolean;
    setHasOverlappingEdu: React.Dispatch<React.SetStateAction<boolean>>;
    showCV: boolean;
    setShowCV: React.Dispatch<React.SetStateAction<boolean>>;
    isCorrectAge: boolean;
    setIsCorrectAge: React.Dispatch<React.SetStateAction<boolean>>;
    educationState: ReturnType<typeof useEducationState>;
    experienceState: ReturnType<typeof useExperienceState>;
  }) => {
    const {
      isBefore21,
      setIsBefore21,
      isUGDurationCorrect,
      setIsUGDurationCorrect,
      isPGDurationCorrect,
      setIsPGDurationCorrect,
      deleteUG,
      setDeleteUG,
      changePGStatus,
      setChangePGStatus,
    } = educationState;
    const {
      isInternDurationCorrect,
      setIsInternDurationCorrect,
      changeInternStatus,
      setChangeInternStatus,
      isDuplicateCurrentExp,
      setIsDuplicateCurrentExp,
    } = experienceState;
    return [
      {
        modalId: "declaration-modal",
        type: "info",
        open: modalConfigs.openDeclaration,
        setOpen: modalConfigs.setOpenDeclaration,
        title: "Declaration",
        descriptionList: [
          {
            descId: 1,
            description:
              "I hereby submit my application to work as a contractor with ENTNT and acknowledge that this submission does not establish an employer-employee relationship with ENTNT and does not create any liability, responsibility or commitment on behalf of ENTNT.",
          },
          {
            descId: 2,
            description:
              "I affirm that the information I have provided in this application is accurate and complete. I understand that any misrepresentation may lead to the termination of my contract without prior notice.",
          },
          {
            descId: 3,
            description:
              "I am aware that ENTNT may use the information I have supplied at its sole discretion for the purpose of conducting background checks before entering into a contract agreement with me.",
          },
          {
            descId: 4,
            description:
              "I hereby grant ENTNT the irrevocable and unconditional authorisation to verify and obtain, as necessary, any information related to me during the application and, if applicable, contracting process, at its sole discretion.",
          },
          {
            descId: 5,
            description:
              "I acknowledge that the information provided in this application will be collected, stored and processed by ENTNT in accordance with applicable data protection laws. I confirm and authorize ENTNT to retain my data for the purposes of managing my application, conducting statistical analyses and, if necessary, verifying the information I have provided.",
          },
        ],
        buttonContents: [
          {
            buttonId: 1,
            buttonLabel: "Submit",
            buttonAction: modalConfigs.onSubmit,
          },
          { buttonId: 2, buttonLabel: "Cancel", isOutline: true },
        ],
      },
      {
        modalId: "success-modal",
        type: "info",
        open: openSuccess,
        setOpen: setOpenSuccess,
        title: "Application successful",
        description:
          "Your application has been submitted successfully! You will receive an email with further instructions soon.",
        buttonContents: [
          {
            buttonId: 1,
            buttonLabel: "Go back to home",
            buttonNavigation: "/",
          },
        ],
      },
      {
        modalId: "error-modal",
        type: "danger",
        open: modalConfigs.openError,
        setOpen: modalConfigs.setOpenError,
        title: "Missing required information",
        description: (
          <div>
            {validateOnApply(
              educationState,
              careerTimelineStates,
              experienceState,
            ).length === 0 ? (
              "Please make sure you have filled in all mandatory fields (marked with a red asterisk *)"
            ) : (
              <>
                Please fill in the mandatory fields:
                {validateOnApply(
                  educationState,
                  careerTimelineStates,
                  experienceState,
                ).map((v, index) => (
                  <strong key={index}>{`${
                    index !== 0 ? "," : ""
                  } ${v}`}</strong>
                ))}
                .
              </>
            )}
          </div>
        ),
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "existing-candidate-modal",
        type: "danger",
        open: existingCandidate,
        setOpen: setExistingCandidate,
        title: "Applicant Status",
        description:
          "Thank you for your interest! You've already applied for this role.",
        buttonContents: [
          {
            buttonId: 1,
            buttonLabel: "Go back to home",
            buttonNavigation: "/",
          },
        ],
      },
      {
        modalId: "subcity-modal",
        type: "success",
        open: modalConfigs.showSubcityConfirmation,
        setOpen: modalConfigs.setShowSubcityConfirmation,
        title: "Add State & City Name",
        description: "Please select the state along with your city name.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "isoverlappingedu-modal",
        type: "danger",
        open: modalConfigs.isOverlappingEdu,
        setOpen: modalConfigs.setIsOverlappingEdu,
        title: "Review and Correct Education Entry",
        description:
          "This education overlaps with an existing entry. Please review and correct.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "isoverlappingexp-modal",
        type: "danger",
        open: modalConfigs.isOverlappingExp,
        setOpen: modalConfigs.setIsOverlappingExp,
        title: "Review and Correct Experience Entry",
        description:
          "This experience overlaps with an existing entry. Please review and correct it. If that is correct, you can save it.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "hasoverlappingedu-modal",
        type: "danger",
        open: modalConfigs.hasOverlappingEdu,
        setOpen: modalConfigs.setHasOverlappingEdu,
        title: "Review and Correct Education Entry",
        description:
          "Some of your education overlaps with an existing entry. Please review and correct.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "delete-career-modal",
        type: "danger",
        open: modalConfigs.deleteCareer,
        setOpen: modalConfigs.setDeleteCareer,
        title: "Delete Career",
        description:
          "Are you sure you want to delete? This action cannot be undone.",
        buttonContents: [
          {
            buttonId: 1,
            buttonLabel: "Delete",
            buttonAction: modalConfigs.removeCareer,
          },
          { buttonId: 2, buttonLabel: "Cancel", isOutline: true },
        ],
      },
      {
        modalId: "uploac-cv-modal",
        type: "info",
        open: modalConfigs.showCV,
        setOpen: modalConfigs.setShowCV,
        title: "Upload your resume",
        description: (
          <div className="flex items-center">
            <ResumeUpload
              cvFileName={cvFileName}
              setCvFileName={setCvFileName}
              cv={cv}
              setCV={setCV}
            />
          </div>
        ),
        buttonContents: [
          {
            buttonId: 1,
            buttonLabel: "Continue to Submit",
            buttonAction: () => {
              if (cv.trim().length !== 0) {
                modalConfigs.setOpenDeclaration(true);
                modalConfigs.setShowCV(false);
              }
            },
            isDisabled: cv.trim().length === 0,
          },
        ],
      },
      {
        modalId: "education-age-modal",
        type: "danger",
        open: isBefore21,
        setOpen: setIsBefore21,
        title: "Eligibility Notification",
        description: `It appears that you started your education after the age of 21. Unfortunately, this does not meet the eligibility criteria.
      Please review and adjust your details, if applicable.`,
        buttonContents: [
          {
            buttonId: 1,
            buttonLabel: "Go back to home",
            buttonNavigation: "/",
          },
        ],
      },
      {
        modalId: "education-duration-modal",
        type: "danger",
        open: isUGDurationCorrect,
        setOpen: setIsUGDurationCorrect,
        title: "Duration Error",
        description:
          "Your undergraduate degree duration must be atleast 3 years or no more than 4 years. Please check your information and ensure that your degree meets these requirements.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "check-age-modal",
        type: "danger",
        open: modalConfigs.isCorrectAge,
        setOpen: modalConfigs.setIsCorrectAge,
        title: "Eligibility Notification",
        description: `Thank you for your interest in applying. Unfortunately, based on the information provided, 
      you do not meet the age requirements for this position. We encourage you to explore other opportunities that may be a better fit for your qualifications.`,
        buttonContents: [
          {
            buttonId: 1,
            buttonLabel: "Go back to home",
            buttonNavigation: "/",
          },
        ],
      },
      {
        modalId: "education-pg-duration-modal",
        type: "danger",
        open: isPGDurationCorrect,
        setOpen: setIsPGDurationCorrect,
        title: "Duration Error",
        description:
          "Your postgraduate degree duration must be atleast 1 year or no more than 2 years. Please check your information and ensure that your degree meets these requirements.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "check-max-gap-modal",
        type: "danger",
        open: isExceedingMaxGap,
        setOpen: setIsExceedingMaxGap,
        title: "Eligibility Notification",
        description: `Thank you for your interest. After reviewing your profile, we found that it does not meet our eligibility requirements for career progression. 
      We appreciate your understanding and encourage you to consider future opportunities with us.`,
        buttonContents: [
          {
            buttonId: 1,
            buttonLabel: "Go back to home",
            buttonNavigation: "/",
          },
        ],
      },
      {
        modalId: "delete-ug-modal",
        type: "danger",
        open: deleteUG,
        setOpen: setDeleteUG,
        title: "Undergraduate Deletion Restricted",
        description:
          "You cannot delete your undergraduate education while postgraduate education exists. Please delete your postgraduate education first or edit your undergraduate details.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "pg-status-modal",
        type: "danger",
        open: changePGStatus,
        setOpen: setChangePGStatus,
        title: "Postgraduate Status",
        description:
          "You cannot change your postgraduate education status while postgraduate education records exist. Please delete the postgraduate education first to update your status.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "intern-status-modal",
        type: "danger",
        open: changeInternStatus,
        setOpen: setChangeInternStatus,
        title: "Internship Status",
        description:
          "You cannot change your internship status while internship records exist. Please delete the internship first to update your status.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "intern-duration-modal",
        type: "danger",
        open: isInternDurationCorrect,
        setOpen: setIsInternDurationCorrect,
        title: "Duration Error",
        description:
          "Your internship duration must be at least 1 month and no more than 12 months. Please check your information and ensure that your internship meets these requirements.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
      {
        modalId: "duplicate-intern-duration-modal",
        type: "danger",
        open: isDuplicateCurrentExp,
        setOpen: setIsDuplicateCurrentExp,
        title: "Current Work Conflict",
        description:
          "You cannot have more than one current working experience at the same time. Please review your information and ensure that only one role is marked as your current position.",
        buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
      },
    ];
  };

  const candidate = useCandidateProfile();
  useEffect(() => {
    if (!candidate) return;
    setExperience(candidate.experience);
    setEducation(candidate.education);
    setFirstName(candidate.firstName);
    setLastName(candidate.lastName);
    setDOB(candidate.dateOfBirth);
    setLinkedinProfile(candidate.linkedinProfile ?? "");
    setEmail(candidate.email);
    setInitialEmail(candidate.email);
    setCV(candidate.cv);
    setNoticePeriod(candidate.noticePeriod);
    const currentWorkLocations1 = cities.find(
      (city) => city.id === candidate.workLocation1CityId,
    );
    const currentWorkLocations2 = cities.find(
      (city) => city.id === candidate.workLocation2CityId,
    );
    const workLocationss = [
      currentWorkLocations1,
      currentWorkLocations2,
    ].filter((location): location is City => location !== undefined);
    setWorkLocations(workLocationss);

    if (candidate.education) {
      const pgEducation = candidate.education.find((e) => e.postGrad);

      if (pgEducation?.endDate) {
        const endDate = new Date(pgEducation.endDate);
        const current = new Date(currentDate!);
        if (endDate > current) {
          setPGStatus("studyingpg");
        } else {
          setPGStatus("completedpg");
        }
      } else {
        setPGStatus("nopg");
      }
    }

    if (candidate.experience) {
      const updatedExperience = candidate.experience.map((e) => {
        if (e.isInternship && e.endDate) {
          const endDate = new Date(e.endDate);
          const current = new Date(currentDate!);
          if (endDate > current) {
            setInternStatus("intern");
            return { ...e, currentlyWorking: true };
          }
        }

        if (
          !e.isInternship &&
          (e.endDate === null || e.period.includes("Present"))
        ) {
          return { ...e, currentlyWorking: true };
        }
        return { ...e, currentlyWorking: false };
      });

      setExperience(updatedExperience);

      if (!candidate.experience.some((e) => e.isInternship && e.endDate)) {
        setInternStatus("nointern");
      } else if (updatedExperience.every((e) => !e.currentlyWorking)) {
        setInternStatus("completedintern");
      }
    }
  }, [candidate, cities]);

  //Add Education
  function addEducation(
    setIsOverlappingEdu: React.Dispatch<React.SetStateAction<boolean>>,
    selectedCareerId: string,
    clearEduStates: () => void,
    setshowEduEdit: React.Dispatch<
      React.SetStateAction<{ showUG: boolean; showPG: boolean }>
    >,
  ) {
    const isOtherUniversity =
      universityName?.name.toLowerCase() === "other" || !universityName?.name;

    const isOtherCity = univCity?.name.toLowerCase() === "other";

    if (
      eduStartDate &&
      isOverlappingEducation(selectedCareerId, eduStartDate, education)
    ) {
      setIsOverlappingEdu(true);
      return;
    }

    if (
      (isOtherCity && !univSubCity) ||
      (isOtherUniversity
        ? !newUniversityName ||
          !newUniversityUrl ||
          !isValidUrl(newUniversityUrl)
        : !universityName?.name) ||
      !eduStartDate ||
      !eduEndDate ||
      (isStudent && !expectedGraduation)
    ) {
      // setOpenError(true);
      return;
    }

    const existingEducationIndex = education.findIndex(
      (edu) => edu.id === selectedCareerId,
    );

    if (existingEducationIndex === -1) {
      const universityData =
        universityName && newUniversityName === ""
          ? universityName
          : {
              id: uuidv4(),
              name: newUniversityName ?? "",
              url: newUniversityUrl ?? "",
              state: state,
              city:
                univSubCity.trim() === ""
                  ? univCity
                  : {
                      id: uuidv4(),
                      name: univSubCity,
                      nearestCity: null,
                      state: state,
                      isConfirmed: false,
                    },
              isConfirmed: false,
            };

      const candidateEdu: Education = {
        id: uuidv4(),
        school: "",
        degree: "",
        fieldofstudy: "",
        period: calculatePeriodOfEducation(eduStartDate, eduEndDate),
        startDate: eduStartDate,
        endDate: eduEndDate,
        university: universityData,
        postGrad: isPostgraduate,
        relevant: isRelevantEducation,
        currentlyStudying: isStudent,
      };
      setEducation([...education, candidateEdu]);
    }

    clearEduStates();
    setshowEduEdit({ showUG: false, showPG: false });
    window.scrollTo(0, 0);
  }

  //Edit Education
  function editEducation(
    setIsOverlappingEdu: React.Dispatch<React.SetStateAction<boolean>>,
    selectedCareerId: string,
    clearEduStates: () => void,
    setshowEduEdit: React.Dispatch<
      React.SetStateAction<{ showUG: boolean; showPG: boolean }>
    >,
  ) {
    const isOtherUniversity =
      universityName?.name.toLowerCase() === "other" || !universityName?.name;

    const isOtherCity = univCity?.name.toLowerCase() === "other";

    if (
      eduStartDate &&
      isOverlappingEducation(selectedCareerId, eduStartDate, education)
    ) {
      setIsOverlappingEdu(true);
      return;
    }

    if (
      (isOtherCity && !univSubCity) ||
      (isOtherUniversity
        ? !newUniversityName ||
          !newUniversityUrl ||
          !isValidUrl(newUniversityUrl)
        : !universityName?.name) ||
      !eduStartDate ||
      !eduEndDate ||
      (isStudent && !expectedGraduation)
    ) {
      // setOpenError(true);
      return;
    }

    const existingEducationIndex = education.findIndex(
      (edu) => edu.id === selectedCareerId,
    );

    if (existingEducationIndex !== -1) {
      const universityData =
        universityName && newUniversityName === ""
          ? universityName
          : {
              id: uuidv4(),
              name: newUniversityName ?? "",
              url: newUniversityUrl ?? "",
              state: state,
              city:
                univSubCity.trim() === ""
                  ? univCity
                  : {
                      id: uuidv4(),
                      name: univSubCity,
                      nearestCity: null,
                      state: state,
                      isConfirmed: false,
                    },
              isConfirmed: false,
            };
      const updatedEducation = [...education];

      updatedEducation[existingEducationIndex] = {
        ...updatedEducation[existingEducationIndex],
        school: "",
        degree: "",
        fieldofstudy: "",
        period:
          eduStartDate && eduEndDate
            ? calculatePeriodOfEducation(eduStartDate, eduEndDate)
            : "",
        startDate: eduStartDate,
        endDate: eduEndDate,
        university: universityData,
        postGrad: isPostgraduate,
        relevant: isRelevantEducation,
        currentlyStudying: isStudent,
      };
      setEducation(updatedEducation);
    }

    clearEduStates();
    setshowEduEdit({ showUG: false, showPG: false });
    window.scrollTo(0, 0);
  }

  //Addd Experience
  function addExperience(
    selectedCareerId: string,
    clearExpStates: () => void,
    setShowExpEdit: React.Dispatch<
      React.SetStateAction<{ showIntern: boolean; showExp: boolean }>
    >,
  ) {
    const isOtherCompany = companyName?.name.toLowerCase() === "other";
    const isOtherCity = compCity?.name.toLowerCase() === "other";
    const isNoUrlValid = noUrl && (isSelfEmployed || notMentioned);
    const isNewCompLocationValid =
      !newCompLocation?.name && !compLocationSubCity;
    const isCompLocationValid =
      compLocation?.name.toLowerCase() === "other"
        ? isNewCompLocationValid
        : !compLocation?.name;

    if (
      (isOtherCity && !compSubCity) ||
      (isOtherCompany && !noUrl
        ? !newCompanyName || !newCompanyUrl || !isValidUrl(newCompanyUrl)
        : !noUrl && (!companyName?.name || isCompLocationValid)) ||
      (isOtherCompany && noUrl && !isNoUrlValid) ||
      (isOtherCompany && noUrl && notMentioned && !relevantExperience) ||
      !title ||
      (currentlyWorking ? !expStartDate : !expStartDate || !expEndDate)
    ) {
      // setOpenError(true);
      return;
    }

    let newid = uuidv4();
    let selectedCompany = null;

    if (isSelfEmployed) {
      selectedCompany = { id: newid, name: "Self Employed" };
    } else if (notMentioned) {
      selectedCompany = { id: newid, name: "Not Mentioned" };
    }
    const existingExpIndex = experience.findIndex(
      (exp) => exp.id === selectedCareerId,
    );

    if (existingExpIndex === -1) {
      const companyData =
        newCompanyName === ""
          ? selectedCompany
            ? {
                id: selectedCompany?.id ?? "",
                name: selectedCompany?.name ?? "",
                state: state,
                city:
                  compSubCity.trim() === ""
                    ? compCity
                    : {
                        id: uuidv4(),
                        name: compSubCity,
                        nearestCity: null,
                        state: state,
                        isConfirmed: false,
                      },
                url: "",
              }
            : compLocation?.name.toLowerCase() === "other"
            ? {
                id: companyName?.id!,
                name: companyName?.name!,
                state:
                  compLocationSubCity.trim() === ""
                    ? newCompLocation?.state
                    : tempState,
                city:
                  compLocationSubCity.trim() === ""
                    ? newCompLocation
                    : {
                        id: uuidv4(),
                        name: compLocationSubCity,
                        nearestCity: null,
                        state: tempState,
                        isConfirmed: false,
                      },
                url: companyName?.url,
              }
            : companyName!
          : {
              id: newid,
              name: newCompanyName ?? "",
              state: state,
              city:
                compSubCity.trim() === ""
                  ? compCity
                  : {
                      id: uuidv4(),
                      name: compSubCity,
                      nearestCity: null,
                      state: state,
                      isConfirmed: false,
                    },
              url: newCompanyUrl ?? "",
            };

      const candidateExp: Experience = {
        id: uuidv4(),
        companyName: "",
        title: title,
        period: expStartDate
          ? calculatePeriodOfExperience(expStartDate, expEndDate) ?? ""
          : "",
        description: showDescription ? relevantExperience : "",
        company: companyData,
        relevant: isRelevantExperience,
        startDate: expStartDate,
        endDate: expEndDate,
        currentlyWorking: currentlyWorking,
        noticePeriod: noticePeriod,
        isInternship: isInternship,
      };
      setSpecificExperienceId(candidateExp.id);
      setExperience([...experience, candidateExp]);
    }

    setTimeout(() => clearExpStates(), 500);
    setShowExpEdit({ showIntern: false, showExp: false });
    window.scrollTo(0, 0);
  }
  //  Edit Experience
  function editExperience(
    selectedCareerId: string,
    clearExpStates: () => void,
    setShowExpEdit: React.Dispatch<
      React.SetStateAction<{ showIntern: boolean; showExp: boolean }>
    >,
  ) {
    const isOtherCompany = companyName?.name.toLowerCase() === "other";
    const isOtherCity = compCity?.name.toLowerCase() === "other";
    const isNoUrlValid = noUrl && (isSelfEmployed || notMentioned);
    const isNewCompLocationValid =
      !newCompLocation?.name && !compLocationSubCity;
    const isCompLocationValid =
      compLocation?.name.toLowerCase() === "other"
        ? isNewCompLocationValid
        : !compLocation?.name;

    if (
      (isOtherCity && !compSubCity) ||
      (isOtherCompany && !noUrl
        ? !newCompanyName || !newCompanyUrl || !isValidUrl(newCompanyUrl)
        : !noUrl && (!companyName?.name || isCompLocationValid)) ||
      (isOtherCompany && noUrl && !isNoUrlValid) ||
      (isOtherCompany && noUrl && notMentioned && !relevantExperience) ||
      !title ||
      (currentlyWorking ? !expStartDate : !expStartDate || !expEndDate)
    ) {
      // setOpenError(true);
      return;
    }

    let newid = uuidv4();
    let selectedCompany = null;

    if (isSelfEmployed) {
      selectedCompany = { id: newid, name: "Self Employed" };
    } else if (notMentioned) {
      selectedCompany = { id: newid, name: "Not Mentioned" };
    }

    const existingExpIndex = experience.findIndex(
      (exp) => exp.id === selectedCareerId,
    );

    if (existingExpIndex !== -1) {
      const companyData =
        newCompanyName === ""
          ? selectedCompany
            ? {
                id: selectedCompany?.id ?? "",
                name: selectedCompany?.name ?? "",
                state: state,
                city:
                  compSubCity.trim() === ""
                    ? compCity
                    : {
                        id:
                          experience.find((exp) => exp.id === selectedCareerId)
                            ?.company.city?.id ?? "",
                        name: compSubCity,
                        nearestCity: null,
                        state: state,
                      },
                url: "",
              }
            : compLocation?.name.toLowerCase() === "other"
            ? {
                id: companyName?.id!,
                name: companyName?.name!,
                state:
                  compLocationSubCity.trim() === ""
                    ? newCompLocation?.state
                    : tempState,
                city:
                  compLocationSubCity.trim() === ""
                    ? newCompLocation
                    : {
                        id: uuidv4(),
                        name: compLocationSubCity,
                        nearestCity: null,
                        state: tempState,
                        isConfirmed: false,
                      },
                url: companyName?.url,
              }
            : companyName!
          : {
              id: newid,
              name: newCompanyName ?? "",
              state: state,
              city:
                compSubCity.trim() === ""
                  ? compCity
                  : {
                      id: uuidv4(),
                      name: compSubCity,
                      nearestCity: null,
                      state: state,
                      isConfirmed: false,
                    },
              url: newCompanyUrl ?? "",
            };

      const updatedExperience = [...experience];
      updatedExperience[existingExpIndex] = {
        id: selectedCareerId,
        companyName: "",
        title: title,
        period: expStartDate
          ? calculatePeriodOfExperience(expStartDate, expEndDate) ?? ""
          : "",
        description: showDescription ? relevantExperience : "",
        company: companyData,
        relevant: isRelevantExperience,
        startDate: expStartDate,
        endDate: expEndDate,
        currentlyWorking: currentlyWorking,
        noticePeriod: noticePeriod,
        isInternship: isInternship,
      };

      setSpecificExperienceId(selectedCareerId);
      setExperience(updatedExperience);
    }

    setTimeout(() => clearExpStates(), 500);
    setShowExpEdit({ showIntern: false, showExp: false });
    window.scrollTo(0, 0);
  }

  //Edit Career Summary
  const editCareer = async (
    career: PreviewEducation | PreviewExperience,
    setSelectedCareerId: React.Dispatch<React.SetStateAction<string>>,
    setshowEduEdit: React.Dispatch<
      React.SetStateAction<{ showUG: boolean; showPG: boolean }>
    >,
    setShowExpEdit: React.Dispatch<
      React.SetStateAction<{ showIntern: boolean; showExp: boolean }>
    >,
  ) => {
    setSelectedCareerId(career.id);
    setIsRelevantExperience(career.isRelevant);

    if ("universityName" in career) {
      setCompCity(undefined);
      const edu = education.find((edu: Education) => edu.id === career.id);
      let existingUni = null;
      if (edu?.university?.id) {
        existingUni = await checkUniversityExists(edu.university.id);
      }

      if (career.degreeType) {
        setshowEduEdit({ showUG: false, showPG: true });
      } else {
        setshowEduEdit({ showUG: true, showPG: false });
      }
      setShowExpEdit({ showIntern: false, showExp: false });

      setIsStudent(edu?.currentlyStudying ? true : false);
      setUniversityName(career.universityName);
      if (!existingUni) {
        setUniversityName({ id: "0", name: "other" });
        setNewUniversityName(edu?.university?.name ?? "");
        setnewUniversityUrl(edu?.university.url ?? "");
        setState(edu?.university.state);
      } else {
        setNewUniversityName("");
        setnewUniversityUrl("");
        setState({ id: "", name: "" });
        setTempState(edu?.university.state);
      }
      edu?.startDate && setEduStartDate(edu?.startDate);
      !edu?.currentlyStudying && edu?.endDate && setEduEndDate(edu?.endDate);
      edu?.currentlyStudying && setExpectedGraduation(edu.endDate);
      setIsPostgraduate(career.degreeType);

      setDegreeType(() =>
        career.degreeType
          ? { id: "2", name: "Postgraduate" }
          : { id: "1", name: "Undergraduate" },
      );

      setIsRelevantEducation(career.isRelevant);
      career.comment && setRelevantEducation(career.comment);
      if (
        edu?.university.city?.nearestCity?.trim() === "" ||
        edu?.university.city?.nearestCity === null
      ) {
        setUnivCity(edu?.university.city);
      } else {
        setUnivCity({ id: "0", name: "other" });
        setUnivSubCity(edu?.university.city?.name ?? "");
      }
    } else {
      setUnivCity(undefined);
      const exp = experience.find((exp) => exp.id === career.id);
      let existingCompCity = null;
      if (exp?.company) {
        const company = {
          name: exp?.company.name!,
          cityId: exp?.company.city?.id!,
          url: exp?.company.url!,
        };

        existingCompCity = await checkCompanyLocation(company);
      }
      let existingComp = null;
      if (exp?.company.url && exp?.company.name) {
        existingComp = await checkCompanyURLorName(
          exp?.company.url,
          exp?.company.name,
        );
      }
      const existingCity = cities.some(
        (c) =>
          c.id === exp?.company.city?.id || c.name === exp?.company.city?.name,
      );
      if (exp?.isInternship) {
        setShowExpEdit({ showIntern: true, showExp: false });
        setIsInternship(true);
      } else {
        setShowExpEdit({ showIntern: false, showExp: true });
        setIsInternship(false);
      }

      setshowEduEdit({ showUG: false, showPG: false });
      if (exp?.company?.name === "Self Employed") {
        setCompanyName({ id: "0", name: "Other" });
        setnoUrl(true);
        setisSelfEmployed(true);
        setnotMentioned(false);
        setState(exp?.company.state);
      } else if (career?.companyName?.name === "Not Mentioned") {
        setCompanyName({ id: "0", name: "Other" });
        setnoUrl(true);
        setShowDescription(true);
        setnotMentioned(true);
        setisSelfEmployed(false);
        setState(exp?.company.state);
      } else if (!existingComp) {
        setCompanyName({ id: "0", name: "Other" });
        setNewCompanyName(career.companyName?.name ?? "");
        setnewCompanyUrl(exp?.company.url ?? "");
        setState(exp?.company.state);
        setCompCity(exp?.company.city);
      } else {
        setnoUrl(false);
        setisSelfEmployed(false);
        setCompanyName(career.companyName);
        setIsRelevantExperience(career.isRelevant);
        if (
          existingCompCity ||
          exp?.company.city?.name.toLowerCase() === "outside india"
        ) {
          setCompLocation(exp?.company.city);
        } else if (existingComp && existingCity) {
          setCompLocation({ id: "0", name: "Other" });
          setNewCompLocation(exp?.company.city);
          setCompLocationSubCity("");
          setCompCity({ id: "", name: "" });
          setCompSubCity("");
        } else {
          setCompLocation({ id: "0", name: "Other" });
          setTempState(exp?.company.state);
          setCompLocationSubCity(exp?.company.city?.name!);
        }
      }

      exp?.startDate && setExpStartDate(exp?.startDate);
      setCurrentlyWorking(
        exp?.currentlyWorking || exp?.period.includes("Present") ? true : false,
      );
      setExpEndDate(exp?.endDate ?? null);
      setNoticePeriod(exp?.noticePeriod);
      setTitle(career.title);
      exp?.description ? setShowDescription(true) : setShowDescription(false);
      setRelevantExperience(exp?.description ?? "");
      if (!existingComp) {
        if (
          exp?.company.city?.nearestCity?.trim() === "" ||
          exp?.company.city?.nearestCity === null
        ) {
          setCompCity(exp?.company.city);
        } else {
          setCompCity({ id: "0", name: "other" });
          setCompSubCity(exp?.company.city?.name ?? "");
        }
      }
    }
  };

  function apply(
    timelineData: any,
    setIsOverlappingGap: React.Dispatch<React.SetStateAction<boolean>>,
    idToken: idToken | null,
    setOpenDeclaration: React.Dispatch<React.SetStateAction<boolean>>,
    onEditSave: () => void,
    setHasOverlappingEdu: React.Dispatch<React.SetStateAction<boolean>>,
    setShowCV: React.Dispatch<React.SetStateAction<boolean>>,
    timeline: any,
    isStudentRole: boolean,
  ) {
    const isPGGiven =
      !isStudentRole &&
      (pgStatus === "studyingpg" || pgStatus === "completedpg") &&
      !education.some((e) => e.postGrad);
    const isInternGiven =
      (internStatus === "intern" || internStatus === "completedintern") &&
      !experience.some((e) => e.isInternship);

    if (
      firstName.trim().length === 0 ||
      lastName.trim().length === 0 ||
      email.trim().length === 0 ||
      workLocations.length === 0 ||
      education.length === 0 ||
      (isStudent && !expectedGraduation) ||
      // cv.trim().length === 0 ||
      !dob ||
      (!isStudentRole && pgStatus === "") ||
      internStatus === "" ||
      isPGGiven ||
      isInternGiven
    ) {
      setOpenError(true);
    } else if (hasOverlappingEducation(education)) {
      setHasOverlappingEdu(true);
      return;
    } else if (timeline.some((t: any) => t.gap && t.totalMonths > 24)) {
      setIsExceedingMaxGap(true);
      return;
    } else if (
      hasCareerGap(timelineData) ||
      hasOverlappingExpinEdu(timelineData) ||
      (experience.length > 0 && hasNoCurrentExperiences(experience))
    ) {
      setIsOverlappingGap(true);
      return;
    } else {
      if (!idToken) setShowCV(true);
      else onEditSave();
    }
  }

  useMemo(() => {
    experience.length > 0 &&
      transformExperienceData(experience).then((data) =>
        setTransformedExperience(data),
      );
    transformEducationData(education, isStudentRole, isInternshipAllowed).then(
      (data) => setTransformedEducation(data),
    );
  }, [experience, education, isInternshipAllowed]);

  let allUniversities: University[] = [];
  let allCompanies: Company[] = [];
  let eduexpCities: City[] = [];

  [...transformedEducation, ...experience].forEach(
    (career: Education | Experience) => {
      if ("university" in career) {
        allUniversities.push(career?.university);
        career?.university.city && eduexpCities.push(career?.university.city);
      } else {
        allCompanies.push(career?.company);
        career?.company.city && eduexpCities.push(career?.company.city);
      }
    },
  );

  //Submit Application
  async function submit(
    setOpenDeclaration: React.Dispatch<React.SetStateAction<boolean>>,
    setloading: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    setloading(true);
    setOpenDeclaration(false);
    if (id === undefined) return;

    let yearsOfExp = 0;
    let notice = 0;
    experience.forEach((exp) => {
      if (exp.startDate) {
        const startYear = exp.startDate.getFullYear();
        const startMonth = exp.startDate.getMonth();
        const endYear = exp.endDate
          ? exp.endDate.getFullYear()
          : new Date(currentDate!).getFullYear();
        const endMonth = exp.endDate
          ? exp.endDate.getMonth()
          : new Date(currentDate!).getMonth();

        const totalMonths =
          (endYear - startYear) * 12 + (endMonth - startMonth);
        if (totalMonths <= 18) {
          yearsOfExp = totalMonths;
        } else {
          yearsOfExp += Math.round(totalMonths / 12);
        }
      }
      notice = exp.noticePeriod ?? 0;
    });

    const candidate: Candidate = {
      id: uuidv4(),
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dob,
      email: email,
      address: "",
      cv: cv,
      linkedinProfile: linkedinProfile,
      coverLetter: "",
      education: transformedEducation,
      experience: transformedExperience,
      noticePeriod: notice,
      yearsOfExperience: yearsOfExp,
      expectedGraduationDate: expectedGraduation,
      workLocation1:
        workLocations[0].name.trim() !== ""
          ? workLocations[0].name
          : workLocations[0].nearestCity ?? "",
      workLocation1CityId: workLocations[0].id,
      workLocation2:
        workLocations[1] && workLocations[1].name.trim() !== ""
          ? workLocations[1].name ?? workLocations[1].nearestCity ?? ""
          : workLocations[1]?.nearestCity ?? "",
      workLocation2CityId: workLocations[1] && workLocations[1].id,
    };

    try {
      if ([...eduexpCities, ...workLocations].length !== 0) {
        await PostCity([...eduexpCities, ...workLocations]);
      }
      if (allUniversities.length !== 0) {
        await PostUniversity(allUniversities);
      }
      if (allCompanies.length !== 0) {
        await PostCompany(allCompanies);
      }
      await PostCandidate(candidate, id);
      setOpenSuccess(true);
      setloading(false);
    } catch (error) {
      setExistingCandidate(true);
    } finally {
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  }

  //Update Candidate Application
  async function updateCandidate(
    idToken: idToken | null,
    setUpdateNotification: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    if (idToken) {
      const { candidateId } = idToken;
      const currentEducation = candidate?.education;
      const currentExperience = candidate?.experience;

      const removedEducationIds = await currentEducation
        ?.filter(
          (initialEdu) => !education?.some((edu) => edu.id === initialEdu.id),
        )
        ?.map((edu) => edu.id);

      const removedExperienceIds = await currentExperience
        ?.filter(
          (initialExp) => !experience?.some((exp) => exp.id === initialExp.id),
        )
        ?.map((exp) => exp.id);

      if (removedEducationIds && removedEducationIds?.length > 0) {
        await removeEducation(candidateId, removedEducationIds);
      }
      if (removedExperienceIds && removedExperienceIds?.length > 0) {
        await removeExperience(candidateId, removedExperienceIds);
      }

      if ([...eduexpCities, ...workLocations].length !== 0) {
        await PostCity([...eduexpCities, ...workLocations]);
      }
      if (allUniversities.length !== 0) {
        await PostUniversity(allUniversities);
      }
      if (allCompanies.length !== 0) {
        await PostCompany(allCompanies);
      }

      let yearsOfExp = 0;
      let notice = 0;
      experience.forEach((exp) => {
        if (exp.startDate) {
          const startYear = exp.startDate.getFullYear();
          const startMonth = exp.startDate.getMonth();
          const endYear = exp.endDate
            ? exp.endDate.getFullYear()
            : new Date(currentDate!).getFullYear();
          const endMonth = exp.endDate
            ? exp.endDate.getMonth()
            : new Date(currentDate!).getMonth();

          const totalMonths =
            (endYear - startYear) * 12 + (endMonth - startMonth);
          if (totalMonths <= 18) {
            yearsOfExp = totalMonths;
          } else {
            yearsOfExp += Math.round(totalMonths / 12);
          }
        }
        notice = exp.noticePeriod ?? 0;
      });

      const currentCandidate: Candidate = {
        id: candidateId,
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dob,
        email: email,
        address: "",
        cv: cv,
        linkedinProfile: linkedinProfile,
        coverLetter: "",
        education: transformedEducation,
        experience: transformedExperience,
        noticePeriod: notice ?? 0,
        yearsOfExperience: yearsOfExp,
        expectedGraduationDate: expectedGraduation,
        workLocation1:
          workLocations[0]?.name.trim() !== ""
            ? workLocations[0]?.name
            : workLocations[0]?.nearestCity ?? "",
        workLocation1CityId: workLocations[0]?.id,
        workLocation2:
          workLocations[1] && workLocations[1]?.name.trim() !== ""
            ? workLocations[1]?.name ?? workLocations[1]?.nearestCity ?? ""
            : workLocations[1]?.nearestCity ?? "",
        workLocation2CityId: workLocations[1] && workLocations[1]?.id,
      };
      await updatedCandidate(currentCandidate)
        .then(() => {
          setUpdateNotification(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((er) => {});
    }
  }

  return {
    addEducation,
    editEducation,
    addExperience,
    editExperience,
    editCareer,
    apply,
    submit,
    updateCandidate,
    applicationModalFields,
  };
};

export default useCareerAction;
