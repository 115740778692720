import React, { useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";

import { PreviewEducation, PreviewExperience, Role } from "../../../Models";
import ConfirmModal from "../../shared/ConfirmModal";
import { WorkExperienceFields } from "./WorkExperienceFields";
import { EducationFields } from "./EducationFields";
import { CareerOverview } from "./CareerOverview";
import CareerTimeline from "./CareerTimeline";
import {
  careerWithGap,
  combinedTimelineData,
  hasExpectedGradDate,
  isOverlappingEducation,
  isOverlappingExperience,
  previewCareereData,
  validateEducation,
  validateExperience,
  validatePersonalInfo,
  validateStepFields,
} from "../../../Helpers";
import { CareerTimelineHeader } from "./CareerTimelineHeader";
import OverlappingGapConfirmModal from "../../shared/OverlappingGapConfirmModal";
import { DummyTimeline } from "../../shared/DummyTimeline";
import { TimelineColorInfo } from "../../shared/TimelineColorInfo";
import { ProfileHeader } from "./ProfileHeader";
import useCareerTimelineState from "../../../hooks/useCareerTimelineState";
import useCityandState from "../../../hooks/useCityandState";
import useEducationState from "../../../hooks/useEducationState";
import useExperienceState from "../../../hooks/useExperienceState";
import useIdToken from "../../../hooks/useIdToken";
import Notification from "../../shared/Notification";
import { Spinner } from "../../shared/Spinner";
import ActionButtons from "../../shared/ActionButtons";
import Stepper from "../../shared/Stepper";
import useCareerAction from "../../../hooks/useCareerAction";
import useClearInstitutions from "../../../hooks/useClearInstitutions";
import { useNavigate, useParams } from "react-router-dom";
import { GetRoles } from "../../../WebCalls";
import StraightTimeline from "./StraightTimeline";
import SerpentineTimeline from "./SerpentineTimeline";

export default function RoleApplication() {
  const idToken = useIdToken();
  const { id } = useParams();
  const navigate = useNavigate();

  const [showEduEdit, setshowEduEdit] = useState({
    showUG: false,
    showPG: false,
  });
  const [showExpEdit, setShowExpEdit] = useState({
    showIntern: false,
    showExp: false,
  });
  const [openDeclaration, setOpenDeclaration] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [deleteCareer, setDeleteCareer] = useState(false);
  const [loading, setloading] = useState(false);

  //Career header useStates
  const careerTimelineStates = useCareerTimelineState();
  const { dob, isCorrectAge, setIsCorrectAge } = careerTimelineStates;
  //cities and states
  const cityandState = useCityandState();

  //Education
  const educationState = useEducationState();
  const {
    setIsStudent,
    eduStartDate,
    setExpectedGraduation,
    univCity,
    education,
    setEducation,
    setDeleteUG,
  } = educationState;

  //Work experience
  const experienceState = useExperienceState();
  const { expStartDate, compCity, experience, setExperience } = experienceState;

  const [selectedCareerId, setSelectedCareerId] = useState<string>("");
  const [showSubcityConfirmation, setShowSubcityConfirmation] = useState(false);
  const [isOverlappingEdu, setIsOverlappingEdu] = useState<boolean>(false);
  const [isOverlappingExp, setIsOverlappingExp] = useState<boolean>(false);
  const [isOverlappingGap, setIsOverlappingGap] = useState<boolean>(false);
  const [hasOverlappingEdu, setHasOverlappingEdu] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [updateNotification, setUpdateNotification] = useState(false);
  const [roleIds, setRoleIds] = useState<string[]>();
  const [checkRoute, setCheckRoute] = useState<boolean>(true);
  const [showCV, setShowCV] = useState<boolean>(false);
  const [role, setRole] = useState<Role>();

  const { clearEduStates, clearExpStates } = useClearInstitutions(
    educationState,
    experienceState,
    cityandState,
    setSelectedCareerId,
  );
  const {
    addEducation,
    addExperience,
    editEducation,
    editExperience,
    editCareer,
    apply,
    submit,
    updateCandidate,
    applicationModalFields,
  } = useCareerAction(
    careerTimelineStates,
    educationState,
    experienceState,
    cityandState,
    setOpenError,
  );

  const modalConfigs = {
    openDeclaration,
    setOpenDeclaration,
    onSubmit,
    openError,
    setOpenError,
    showSubcityConfirmation,
    setShowSubcityConfirmation,
    isOverlappingEdu,
    setIsOverlappingEdu,
    isOverlappingExp,
    setIsOverlappingExp,
    deleteCareer,
    setDeleteCareer,
    removeCareer,
    hasOverlappingEdu,
    setHasOverlappingEdu,
    showCV,
    setShowCV,
    isCorrectAge,
    setIsCorrectAge,
    educationState,
    experienceState,
  };

  const modalFields = applicationModalFields(modalConfigs);

  useEffect(() => {
    GetRoles()
      .then((data) => {
        setRoleIds(data.filter((r) => r.isActive).map((r) => r.id));
        setRole(data.find((r) => r.id === id));
        const currentDate = new Date(data[0].currentDate);
        const isStudentRole = data.find((r) => r.id === id)?.isStudent;

        localStorage.setItem("currentDate", currentDate.toDateString());
        localStorage.setItem("isStudentRole", `${isStudentRole}`);
      })
      .finally(() => {
        setCheckRoute(false);
      });

    return () => {
      localStorage.removeItem("currentDate");
      localStorage.removeItem("isStudentRole");
    };
  }, []);

  if (!checkRoute && id && !roleIds?.includes(id)) {
    navigate("/notfound");
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [window.innerWidth]);

  const goToNextStep = () => {
    if (currentStep < 3) {
      if (
        currentStep === 1 &&
        validateStepFields(1, careerTimelineStates, education)
      ) {
        return;
      }

      if (currentStep === 2 && education.length === 0) {
        return;
      }
      setCurrentStep(currentStep + 1);
      clearEduStates();
      clearExpStates();
    }
  };

  const goToPreviousStep = () => {
    if (currentStep > 0 && currentStep <= 3) {
      setCurrentStep(currentStep - 1);
      clearEduStates();
      clearExpStates();
    }
  };

  const renderStep1 =
    windowWidth >= 1024 || (windowWidth < 1024 && currentStep === 1);
  const renderStep2 =
    windowWidth >= 1024 || (windowWidth < 1024 && currentStep === 2);

  useMemo(() => {
    if (
      eduStartDate &&
      isOverlappingEducation(selectedCareerId, eduStartDate, education)
    ) {
      setIsOverlappingEdu(true);
    }
  }, [eduStartDate]);

  useMemo(() => {
    if (
      expStartDate &&
      isOverlappingExperience(selectedCareerId, expStartDate, experience)
    ) {
      setIsOverlappingExp(true);
    }
  }, [expStartDate]);

  useMemo(() => {
    if (
      (compCity?.name.toLowerCase() === "other" ||
        univCity?.name.toLowerCase() === "other") &&
      !selectedCareerId
    ) {
      setShowSubcityConfirmation(true);
    }
  }, [compCity, univCity, selectedCareerId]);

  function onAddEdu() {
    addEducation(
      setIsOverlappingEdu,
      selectedCareerId,
      clearEduStates,
      setshowEduEdit,
    );
  }

  function onEditEdu() {
    editEducation(
      setIsOverlappingEdu,
      selectedCareerId,
      clearEduStates,
      setshowEduEdit,
    );
  }

  function onAddExp() {
    addExperience(selectedCareerId, clearExpStates, setShowExpEdit);
  }

  function onEditExp() {
    editExperience(selectedCareerId, clearExpStates, setShowExpEdit);
  }

  function onRemoveCareer(career: PreviewEducation | PreviewExperience) {
    if (
      education.length > 1 &&
      education.some((e) => e.id === career.id && !e.postGrad)
    ) {
      setDeleteUG(true);
      return;
    }
    setDeleteCareer(true);
    setSelectedCareerId(career.id);
    setshowEduEdit({ showUG: false, showPG: false });
    setShowExpEdit({ showIntern: false, showExp: false });
  }

  function removeCareer() {
    setEducation(education.filter((e) => e.id !== selectedCareerId));
    setExperience(experience.filter((e) => e.id !== selectedCareerId));
    setSelectedCareerId("");
    setDeleteCareer(false);
  }

  const onEditCareer = async (career: PreviewEducation | PreviewExperience) => {
    editCareer(career, setSelectedCareerId, setshowEduEdit, setShowExpEdit);
  };

  const timelineData = useMemo(
    () => combinedTimelineData(education, experience),
    [education, experience],
  );

  const timeline = useMemo(
    () => careerWithGap(education, experience),
    [education, experience],
  );

  const previewCareer = useMemo(
    () => previewCareereData(education, experience),
    [education, experience],
  );

  useMemo(() => {
    if (!hasExpectedGradDate(education)) {
      setExpectedGraduation(null);
      setIsStudent(false);
    }
  }, [previewCareer]);

  const dataLength = useMemo(
    () => education.length + experience.length,
    [education, experience],
  );

  function onApply() {
    apply(
      timelineData,
      setIsOverlappingGap,
      idToken,
      setOpenDeclaration,
      onEditSave,
      setHasOverlappingEdu,
      setShowCV,
      timeline,
      role?.isStudent!,
    );
  }

  function onSubmit() {
    submit(setOpenDeclaration, setloading);
  }

  async function onEditSave() {
    updateCandidate(idToken, setUpdateNotification);
  }

  if (checkRoute) {
    return (
      <div className="h-screen flex items-center justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <div className="lg:hidden pt-3">
        <Stepper currentStep={currentStep} />
      </div>
      <form className="px-10 pt-3 sm:pt-6 max-w-7xl mx-auto min-h-[calc(100dvh-180px)] lg:min-h-full">
        <div className="space-y-1">
          <div className="w-full flex flex-col lg:border-b border-gray-900/10">
            {renderStep1 && (
              <>
                <CareerTimelineHeader
                  careerTimelineStates={careerTimelineStates}
                  cityandState={cityandState}
                  openError={openError}
                  setOpenError={setOpenError}
                  timeline={timelineData}
                  isStudentRole={role?.isStudent!}
                />
              </>
            )}
            {windowWidth >= 1024 && (
              <div
                className={`hidden lg:block w-full text-xs ${
                  previewCareer.length === 0 ? "py-16" : "pb-6"
                }`}
              >
                {timelineData.length === 0 ? (
                  <DummyTimeline />
                ) : (
                  <>
                    {dataLength <= 3 ? (
                      <StraightTimeline
                        timeline={timelineData}
                        isMobile={window.innerWidth < 768}
                      />
                    ) : (
                      <SerpentineTimeline
                        timeline={timelineData}
                        isMobile={window.innerWidth < 768}
                      />
                    )}
                    <TimelineColorInfo />
                  </>
                )}
              </div>
            )}
          </div>

          {renderStep2 && (
            <CareerOverview
              previewCareer={previewCareer}
              onRemoveCareer={onRemoveCareer}
              onEditCareer={onEditCareer}
            />
          )}

          {renderStep2 && (
            <div
              className={`${
                previewCareer.length >= 4 && !renderStep2 ? "pt-0" : "pt-4"
              }`}
            >
              <ProfileHeader
                showEduEdit={showEduEdit}
                showExpEdit={showExpEdit}
                setshowEduEdit={setshowEduEdit}
                setShowExpEdit={setShowExpEdit}
                clearEduStates={clearEduStates}
                clearExpStates={clearExpStates}
                validatePersonalInfo={validatePersonalInfo(
                  careerTimelineStates,
                )}
                isStudentRole={role?.isStudent}
                educationState={educationState}
                experienceState={experienceState}
              />
              <div>
                {(showEduEdit.showUG || showEduEdit.showPG) && (
                  <div className="mx-auto">
                    <div className="rounded-md bg-gray-100 px-6 py-4">
                      <EducationFields
                        educationState={educationState}
                        cityandState={cityandState}
                        inEditMode={selectedCareerId.trim().length !== 0}
                        setOpenError={setOpenError}
                        selectedCareerId={selectedCareerId}
                        dob={dob}
                        isStudentRole={role?.isStudent!}
                      />
                      <div className="pt-8 flex gap-4 justify-end">
                        <button
                          type="button"
                          onClick={() => {
                            setshowEduEdit({ showUG: false, showPG: false });
                            setSelectedCareerId("");
                            clearEduStates();
                            window.scrollTo(0, 0);
                          }}
                          className="block rounded-md bg-red-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm"
                        >
                          Cancel
                        </button>
                        <button
                          disabled={validateEducation(educationState)}
                          type="button"
                          onClick={
                            selectedCareerId.trim().length === 0
                              ? onAddEdu
                              : onEditEdu
                          }
                          className={`block rounded-md bg-entntblue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange
                            ${
                              validateEducation(educationState)
                                ? "cursor-default bg-gray-300"
                                : "hover:bg-entntorange"
                            }
                            `}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div>
                {(showExpEdit.showIntern || showExpEdit.showExp) && (
                  <div className="mx-auto">
                    <div className="rounded-md bg-gray-100 px-6 py-4">
                      <WorkExperienceFields
                        experienceState={experienceState}
                        cityandState={cityandState}
                        inEditMode={selectedCareerId.trim().length !== 0}
                        setOpenError={setOpenError}
                        dob={dob}
                        selectedCareerId={selectedCareerId}
                      />
                      <div className="pt-4 flex gap-4 justify-end">
                        <button
                          type="button"
                          onClick={() => {
                            setShowExpEdit({
                              showIntern: false,
                              showExp: false,
                            });
                            setSelectedCareerId("");
                            clearExpStates();
                            window.scrollTo(0, 0);
                          }}
                          className="block rounded-md bg-red-500 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm"
                        >
                          Cancel
                        </button>
                        <button
                          disabled={validateExperience(experienceState)}
                          type="button"
                          onClick={
                            selectedCareerId.trim().length === 0
                              ? onAddExp
                              : onEditExp
                          }
                          className={`rounded-md bg-entntblue px-3 py-2 text-center text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange
                             ${
                               validateExperience(experienceState)
                                 ? "cursor-default bg-gray-300"
                                 : "hover:bg-entntorange"
                             }
                            `}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`hidden lg:flex items-center justify-center pt-10`}
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <button
                    hidden={
                      showEduEdit.showUG ||
                      showEduEdit.showPG ||
                      showExpEdit.showIntern ||
                      showExpEdit.showExp
                    }
                    type="button"
                    className="w-32 rounded-md bg-entntblue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-entntorange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange"
                    onClick={onApply}
                  >
                    {idToken?.candidateId ? "Save" : "Apply"}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>

        {windowWidth < 1024 && currentStep === 3 && (
          <>
            {timelineData.length === 0 ? (
              <div style={{ height: `${window.innerHeight - 200}px` }}>
                <DummyTimeline />
              </div>
            ) : (
              <>
                {dataLength <= 3 ? (
                  <StraightTimeline
                    timeline={timelineData}
                    isMobile={window.innerWidth < 768}
                  />
                ) : (
                  <SerpentineTimeline
                    timeline={timelineData}
                    isMobile={window.innerWidth < 768}
                  />
                )}
              </>
            )}
          </>
        )}
      </form>
      <div
        className="lg:hidden"
        hidden={
          showEduEdit.showUG ||
          showEduEdit.showPG ||
          showExpEdit.showExp ||
          showExpEdit.showIntern
        }
      >
        <ActionButtons
          currentStep={currentStep}
          goToNextStep={goToNextStep}
          goToPreviousStep={goToPreviousStep}
          onApply={onApply}
          disable={validateStepFields(
            currentStep,
            careerTimelineStates,
            education,
          )}
        />
      </div>

      {modalFields.map((modal) => (
        <ConfirmModal
          key={modal.modalId}
          modalId={modal.modalId}
          type={modal.type}
          open={modal.open}
          setOpen={modal.setOpen}
          title={modal.title}
          descriptionList={modal.descriptionList}
          description={modal.description}
          buttonContents={modal.buttonContents}
        />
      ))}
      <OverlappingGapConfirmModal
        open={isOverlappingGap}
        setOpen={setIsOverlappingGap}
        title="Clarifications in Education or Work Experience"
        type="danger"
        timelineData={timelineData}
        setOpenDeclaration={setOpenDeclaration}
        onEditSave={onEditSave}
        experienceState={experienceState}
        educationState={educationState}
        setHasOverlappingEdu={setHasOverlappingEdu}
        dob={dob}
        setShowCV={setShowCV}
      />
      <Notification
        show={updateNotification}
        setShow={setUpdateNotification}
        title="Profile updated successfully"
        type="info"
        description="Your profile has been updated successfully."
      />
    </div>
  );
}
