import useCityandState from "./useCityandState";
import useEducationState from "./useEducationState";
import useExperienceState from "./useExperienceState";

const useClearInstitutions = (
  educationState: ReturnType<typeof useEducationState>,
  experienceState: ReturnType<typeof useExperienceState>,
  cityandState: ReturnType<typeof useCityandState>,
  setSelectedCareerId: React.Dispatch<React.SetStateAction<string>>,
) => {
  const {
    setIsStudent,
    setUniversityName,
    setNewUniversityName,
    setEduStartDate,
    setEduEndDate,
    setIsPostgraduate,
    setIsRelevantEducation,
    setRelevantEducation,
    setnewUniversityUrl,
    setDegreeType,
    setUnivCity,
    setUnivClosestCity,
    setUnivSubCity,
    setShowDescription: setShowEduDescription,
  } = educationState;

  const {
    setCompanyName,
    setNewCompanyName,
    setExpStartDate,
    setExpEndDate,
    setRelevantExperience,
    setIsRelevantExperience,
    setTitle,
    setnewCompanyUrl,
    setisSelfEmployed,
    setnotMentioned,
    setnoUrl,
    setShowDescription,
    setCompCity,
    setCompClosestCity,
    setCompSubCity,
    setCurrentlyWorking,
    setCompLocation,
    setNewCompLocation,
    setCompLocationClosestCity,
    setCompLocationSubCity,
    setNoticePeriod,
    setSpecificExperienceId,
    setIsInternship,
  } = experienceState;

  const { setState, setTempState } = cityandState;

  const clearEduStates = () => {
    setUniversityName({ id: "", name: "" });
    setNewUniversityName("");
    setEduStartDate(null);
    setEduEndDate(null);
    setDegreeType(undefined);
    setIsPostgraduate(false);
    setIsRelevantEducation(true);
    setRelevantEducation("");
    setnewUniversityUrl("");
    setState(undefined);
    setSelectedCareerId("");
    setUnivCity({ id: "", name: "" });
    setUnivSubCity("");
    setUnivClosestCity(undefined);
    setIsStudent(false);
    setShowEduDescription(false);
    setTempState({ id: "", name: "" });
  };

  const clearExpStates = () => {
    setCompanyName({ id: "", name: "" });
    setNewCompanyName("");
    setExpStartDate(null);
    setExpEndDate(null);
    setIsRelevantExperience(true);
    setRelevantExperience("");
    setTitle("");
    setnewCompanyUrl("");
    setnoUrl(false);
    setisSelfEmployed(false);
    setnotMentioned(false);
    setState(undefined);
    setShowDescription(false);
    setSelectedCareerId("");
    setCompCity({ id: "", name: "" });
    setCompSubCity("");
    setCompClosestCity(undefined);
    setCurrentlyWorking(false);
    setCompLocation({ id: "", name: "" });
    setNewCompLocation({ id: "", name: "" });
    setTempState({ id: "", name: "" });
    setCompLocationClosestCity({ id: "", name: "" });
    setCompLocationSubCity("");
    setNoticePeriod(undefined);
    setSpecificExperienceId(undefined);
    setIsInternship(false);
  };

  return { clearEduStates, clearExpStates };
};

export default useClearInstitutions;
