import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RoleApplication from './pages/application/RoleApplication';
import ShippingApplication from './pages/ShippingApplication/shippingApplication';
import { Role } from '../Models';
import { GetRole } from '../WebCalls';
import { Spinner } from './shared/Spinner';
import Loading from './shared/Loading';

type RoleParams = {
    id: string;
  };
const RoleBasedApplication = () => {
    const { id } = useParams<RoleParams>();
    const [role, setRole] = useState<Role|null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchRole = async () => {
            try {
                if (id === undefined) return;
                const response = await GetRole(id);
                setRole(response);
            } catch (error) {
                console.error('Error fetching role data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchRole();
    }, [id]);

    if (loading) {
        return <Loading/>;
    }

    if (!role) {
        return(
            <>
      <main className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-12 lg:px-8 dark:bg-darkbglight">
        <div className="text-center">
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-entntorange sm:text-5xl">
            Application is Closed.
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-400">
            The Current Job Application is Closed. Better luck next time.
          </p>
        </div>
      </main>
    </>
        )
    }

    if (role.department === 'Shipping') {
        return <ShippingApplication />;
    }

    if (role.department === 'IT') {
        return <RoleApplication />;
    }

    return <div>Department not supported</div>;
};

export default RoleBasedApplication;
