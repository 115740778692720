import { ChangeEvent, useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { isSameMonth, addDays } from "date-fns";
import { CalendarDaysIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { Tooltip as ReactTooltip } from "react-tooltip";

import { InputField } from "../../../shared/InputField";
import ToggleSwitch from "../../../shared/ToggleSwitch";
import {
  City,
  dropDownValueType,
  RatingsByDepartment,
} from "../../../../Models";
import useCityandState from "../../../../hooks/useCityandState";
import useExperienceState from "../Hooks/useShippingCareerExp";
import useFetchInstitutions from "../../../../hooks/useFetchInstitutions";
import { FetchCities, GetCompanies } from "../../../../WebCalls";
import useFetchCities from "../../../../hooks/useFetchCities";
import SearchDropDown from "../../../shared/SearchDropDown";
import AddNewCompanyCity from "../../../shared/AddNewCompanyCity";
import NewInstitutionEntryModal from "../../../shared/NewInstitutionEntryModal";

type WorkExperienceType = {
  experienceState: ReturnType<typeof useExperienceState>;
  cityandState: ReturnType<typeof useCityandState>;
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>;
  inEditMode: boolean;
  dob: Date | null;
};

export const WorkExperience = ({
  experienceState,
  cityandState,
  setOpenError,
  inEditMode,
  dob,
}: WorkExperienceType) => {
  const [matchedNames, setMatchedNames] = useState<string[]>([]);
  const [showInstitutionEntry, setShowInstitutionEntry] = useState(false);
  const [selectedCompCities, setSelectedCompCities] = useState<any>([]);
  const [showAddNewCity, setShowAddNewCity] = useState(false);

  const {
    companyName,
    setCompanyName,
    newCompanyName,
    setNewCompanyName,
    expStartDate,
    setExpStartDate,
    expEndDate,
    setExpEndDate,
    noticePeriod,
    setNoticePeriod,
    relevantExperience,
    setRelevantExperience,
    newCompanyUrl,
    setnewCompanyUrl,
    companiesData,
    setCompaniesData,
    isSelfEmployed,
    setisSelfEmployed,
    notMentioned,
    setnotMentioned,
    noUrl,
    setnoUrl,
    compCity,
    setCompCity,
    compClosestCity,
    setCompClosestCity,
    compSubCity,
    setCompSubCity,
    currentlyWorking,
    setCurrentlyWorking,
    isMaritime,
    setIsMaritime,
    isOnboard,
    setIsOnboard,
    shipType,
    setShipType,
    shippingDepartment,
    setShippingDepartment,
    ratingState,
    setRatingState,
    compLocationClosestCity,
    setCompLocationClosestCity,
    compLocationSubCity,
    setCompLocationSubCity,
    ratingDepartmentState,
    setRatingDepartmentState,
    compLocation,
    setCompLocation,
    newCompLocation,
    setNewCompLocation,
    ratingDepartment,
    shipTypes,
    ratings,
    shippingDepartments,
    title,
    setTitle,
  } = experienceState;

  const { setState, setTempState, cities } = cityandState;
  const [errors, setErrors] = useState({
    period: {
      touched: false,
      error: "Please Enter the duration of Experience.",
    },
  });

  const {
    data: allCompanies,
    setPageNumber,
    setSearchTerm,
  } = useFetchInstitutions(GetCompanies);

  useEffect(() => {
    const uniqueCompanies = allCompanies.filter(
      (uni, index, self) =>
        index === self.findIndex((u) => u.name === uni.name),
    );
    setCompaniesData(uniqueCompanies);

    const companyCities = allCompanies
      .filter((c) => c.id === companyName?.id)
      .map((c) => c.city);

    let outsideIndiaCity;
    if (
      !companyCities.some(
        (c) => c?.name.trim().toLowerCase() === "outside india",
      )
    ) {
      outsideIndiaCity = cities.find(
        (city) => city.name.trim().toLowerCase() === "outside india",
      );
    }

    const sortedCities = companyCities.sort((a, b) => {
      if (!a?.name) return 1;
      if (!b?.name) return -1;
      return a.name.localeCompare(b.name);
    });

    setSelectedCompCities([...sortedCities, outsideIndiaCity]);
  }, [allCompanies, companyName]);

  useEffect(() => {
    setCompaniesData([]);
    if (!inEditMode) {
      setCompanyName(undefined);
      setIsOnboard(undefined);
      setCompanyName(undefined);
    }
  }, [isMaritime]);

  useEffect(() => {
    companyName?.name && setSearchTerm(companyName?.name);
  }, []);

  const handleStartDateChange = (date: Date | null) => {
    setExpStartDate(date);
  };
  const handleEndDateChange = (date: Date | null) => {
    setExpEndDate(date);
  };

  useMemo(() => {
    setExpEndDate(() => (currentlyWorking ? new Date() : expEndDate));
  }, [currentlyWorking]);

  const handleCompanuUrlClose = () => {
    if (newCompanyUrl.trim().length !== 0) {
      setnewCompanyUrl("");
      setMatchedNames([]);
      setNewCompanyName("");
    } else {
      setCompanyName({ id: "", name: "" });
      setnoUrl(false);
    }
  };
  const handleSubcityClose = () => {
    if (compSubCity.trim().length !== 0) {
      setNewCompanyName("");
      setnewCompanyUrl("");
      setCompSubCity("");
    } else {
      setCompCity({ id: "", name: "" });
      setCompanyName({ id: "", name: "" });
      setnoUrl(false);
    }
  };

  const handleNewCompCityClose = () => {
    setCompLocationSubCity("");
    setTempState({ id: "", name: "" });
    setCompCity({ id: "", name: "" });
    setCompLocation({ id: "", name: "" });
    setNewCompLocation({ id: "", name: "" });
    // setCompLocationClosestCity({ id: "", name: "" });
  };

  const {
    data: newcities,
    setPageNumber: setCityPageNumber,
    setSearchTerm: setCitySearchTerm,
  } = useFetchCities(FetchCities);

  const filteredCities = newcities.filter(
    (city) =>
      !selectedCompCities.some(
        (selectedCity: City) =>
          selectedCity?.id === city?.id || selectedCity?.name === city?.name,
      ),
  );

  const matchCompanyURL = (url: string): string[] => {
    const matchedCompanies = companiesData.filter(
      (company) => company.url === url,
    );
    return matchedCompanies.map((company) => company.name);
  };
  const handleNewCompNameClose = () => {
    setNewCompanyName("");
    setnewCompanyUrl("");
    setCompLocationSubCity("");
    setCompCity({ id: "", name: "" });
    setCompanyName({ id: "", name: "" });
    setState({ id: "", name: "" });
    setnoUrl(false);
    setnotMentioned(false);
    setisSelfEmployed(false);
  };

  const setLocationtoComp = (value: any) => {
    setCompLocation(value);
    if (value?.name.toLowerCase() !== "other") {
      if (value?.name.toLowerCase() === "outside India") {
        const outsideIndiaCity = cities.find(
          (city) => city.name === "Outside India",
        );
        setCompanyName({ ...companyName!, city: outsideIndiaCity });
      } else {
        setCompanyName(allCompanies.find((c) => c.city === value));
      }
    }
    setNewCompLocation({ id: "", name: "" });
  };

  useEffect(() => {
    const matchedCompanyNames = matchCompanyURL(newCompanyUrl);
    if (newCompanyUrl && matchedCompanyNames.length !== 0) {
      setMatchedNames(matchedCompanyNames);
    } else {
      setMatchedNames([]);
    }
  }, [newCompanyUrl]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-6 gap-x-6 gap-y-4">
      <div className={"flex items-end gap-2 col-span-6"}>
        <label
          htmlFor="isMaritime"
          className="flex items-end text-sm whitespace-nowrap font-medium leading-6 text-gray-900"
        >
          Is you work experience related to Maritime?
          <InformationCircleIcon
            data-tooltip-id="maritime-description-tooltip"
            className="w-6 h-6 ml-2"
          />
        </label>
        <ToggleSwitch
          id="isMaritime"
          checked={isMaritime}
          setState={setIsMaritime}
        />
      </div>
      {isMaritime ? (
        <>
          <div>
            <label
              htmlFor="job_category"
              className="block text-sm font-medium leading-6 text-gray-900 mb-2"
            >
              Category
            </label>
            <select
              className="font-sans block text-sm font-medium w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
              name="job_category"
              onChange={(event: ChangeEvent<HTMLSelectElement>) =>
                setIsOnboard(event.target.value === "Onboard")
              }
            >
              <option
                value=""
                className="font-sans text-base"
                selected
                disabled
              >
                Select Category
              </option>
              <option
                className="font-sans text-base"
                selected={inEditMode && isOnboard}
                value="Onboard"
              >
                Onboard
              </option>
              <option
                className="font-sans text-base"
                selected={inEditMode && !isOnboard}
                value="Ashore"
              >
                Ashore
              </option>
            </select>
          </div>
          {isOnboard !== undefined && (
            <>
              {isOnboard === true ? (
                <>
                  <div className="grid grid-cols-2 col-span-6 gap-3">
                    {/* Department DropDown */}
                    <SearchDropDown
                      label="Department"
                      placeHolder="Select Department"
                      dropDownValues={ratingDepartment}
                      state={ratingDepartmentState}
                      setState={setRatingDepartmentState}
                      hideOthers
                      error="Please Select a Department"
                    />
                    {/* rating dropdown */}
                    <SearchDropDown
                      label="Rank"
                      placeHolder="Select Rank"
                      dropDownValues={
                        ratingDepartmentState === undefined
                          ? []
                          : ratings[
                              ratingDepartmentState?.name as
                                | "Deck"
                                | "Engine"
                                | "Rating"
                            ]
                      }
                      state={ratingState}
                      setState={setRatingState}
                      mandatoryField
                      hideOthers
                      disable={ratingDepartmentState === undefined}
                      error="Please Select a Rank"
                    />
                    {/* ship type dropdown */}
                    <SearchDropDown
                      label="Ship Type"
                      placeHolder="Select Ship Type"
                      dropDownValues={shipTypes}
                      state={shipType}
                      setState={setShipType}
                      mandatoryField
                      hideOthers
                      disable={ratingState === undefined}
                      error="Please Select a Ship Type"
                    />
                    <div className="col-span-1">
                      <div>
                        <>
                          {/* Dropdown Companies */}
                          {companyName?.name.toLowerCase() !== "other" && (
                            <div className="">
                              <SearchDropDown
                                label="Company"
                                placeHolder="eg. entnt.in"
                                dropDownValues={companiesData}
                                state={companyName}
                                setState={(value) => {
                                  setCompanyName(value);
                                  handleNewCompCityClose();
                                }}
                                mandatoryField
                                hyperLink={{
                                  label:
                                    "Can't find yours? Click here to add it.",
                                  action: () => {
                                    setShowInstitutionEntry(true);
                                  },
                                }}
                                apiRequest={{
                                  setPageNumber,
                                  setSearchTerm,
                                }}
                                hideOthers
                                error="Please Select a Company"
                              />
                            </div>
                          )}
                          {companyName &&
                            companyName.name.toLowerCase() === "other" && (
                              <div className="relative col-span-2 flex items-end justify-end w-full">
                                <InputField
                                  label="Company"
                                  value={
                                    noUrl
                                      ? isSelfEmployed
                                        ? "Self Employed"
                                        : "Other"
                                      : newCompanyName
                                  }
                                  mandatoryField
                                  isDisabled
                                />
                                <XCircleIcon
                                  onClick={handleNewCompNameClose}
                                  className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
                                />
                              </div>
                            )}
                        </>
                      </div>
                      {matchedNames.length !== 0 &&
                        companyName?.name.toLowerCase() === "other" && (
                          <div className="h-4 w-full col-span-full"></div>
                        )}
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="grid grid-cols-2 col-span-6 gap-3">
                    <SearchDropDown
                      label="Department"
                      placeHolder="Select Department"
                      dropDownValues={shippingDepartments}
                      state={shippingDepartment}
                      setState={setShippingDepartment}
                      mandatoryField
                      error="Please Select a Department"
                    />
                    <div className="col-span-1">
                      <label
                        htmlFor="adddescription"
                        className="flex text-sm font-medium leading-6 text-gray-900"
                      >
                        Add Description
                        <span className="text-red-600 pl-1">*</span>
                        <InformationCircleIcon
                          data-tooltip-id="experience-description-tooltip"
                          className="w-6 h-6 ml-2"
                        />
                      </label>
                      <textarea
                        rows={1}
                        className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                        value={relevantExperience}
                        name="adddescription"
                        onChange={(e) => setRelevantExperience(e.target.value)}
                        placeholder="Please provide any comments here"
                      />
                    </div>

                    <div className="col-span-1">
                      <div>
                        <>
                          {/* Dropdown Companies */}
                          {companyName?.name.toLowerCase() !== "other" && (
                            <div className="">
                              <SearchDropDown
                                label="Company"
                                placeHolder="eg. entnt.in"
                                dropDownValues={companiesData}
                                state={companyName}
                                setState={(value) => {
                                  setCompanyName(value);
                                  handleNewCompCityClose();
                                }}
                                mandatoryField
                                hyperLink={{
                                  label:
                                    "Can't find yours? Click here to add it.",
                                  action: () => {
                                    setShowInstitutionEntry(true);
                                  },
                                }}
                                apiRequest={{
                                  setPageNumber,
                                  setSearchTerm,
                                }}
                                hideOthers
                                error="Please Select a Company"
                              />
                            </div>
                          )}
                          {companyName &&
                            companyName.name.toLowerCase() === "other" && (
                              <div className="relative col-span-2 flex items-end justify-end w-full">
                                <InputField
                                  label="Company"
                                  value={
                                    noUrl
                                      ? isSelfEmployed
                                        ? "Self Employed"
                                        : "Other"
                                      : newCompanyName
                                  }
                                  mandatoryField
                                  isDisabled
                                />
                                <XCircleIcon
                                  onClick={handleNewCompNameClose}
                                  className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
                                />
                              </div>
                            )}
                        </>
                      </div>
                      {matchedNames.length !== 0 &&
                        companyName?.name.toLowerCase() === "other" && (
                          <div className="h-4 w-full col-span-full"></div>
                        )}
                    </div>
                    <div className="col-span-1">
                      <div>
                        <InputField
                          label="Title"
                          placeHolder="Please Provide Title/Role"
                          value={title}
                          setValue={setTitle}
                          mandatoryField
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div
                className={`flex flex-col justify-end ${
                  currentlyWorking ? "col-span-3" : "col-span-3"
                }`}
              >
                <label className="text-sm font-medium leading-6 text-gray-900 col-span-6">
                  Period
                  <span className="text-red-600 pl-1">*</span>
                </label>
                <div className="flex flex-col sm:flex-row pt-2 gap-6">
                  <label className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
                    <span className="w-10 sm:w-fit flex items-center h-9">
                      From
                    </span>
                    <ReactDatePicker
                      className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                      selected={expStartDate}
                      onChange={(date) =>
                        date && handleStartDateChange(addDays(date, 1))
                      }
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      placeholderText="eg. 01/2023"
                      closeOnScroll
                      shouldCloseOnSelect={true}
                      minDate={
                        dob
                          ? new Date(
                              dob.getFullYear() + 17,
                              dob.getMonth(),
                              dob.getDate(),
                            )
                          : null
                      }
                      maxDate={new Date()}
                      showIcon
                      icon={
                        <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
                      }
                      calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                      onFocus={(e) => {
                        if (/iPhone|iPad/i.test(navigator.userAgent)) {
                          e.target.readOnly = true;
                        }
                      }}
                      onBlur={() =>
                        setErrors((prev) => ({
                          ...prev,
                          period: { ...prev.period, touched: true },
                        }))
                      }
                    />
                  </label>
                  {!currentlyWorking && (
                    <label className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
                      <span className="w-10 sm:w-fit flex items-center h-9">
                        To
                      </span>
                      <ReactDatePicker
                        className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                        selected={expEndDate}
                        onChange={(date) =>
                          date && handleEndDateChange(addDays(date, 1))
                        }
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        minDate={expStartDate}
                        filterDate={(date) => {
                          if (expStartDate) {
                            return !isSameMonth(date, expStartDate);
                          }
                          return true;
                        }}
                        placeholderText="eg. 01/2024"
                        closeOnScroll
                        shouldCloseOnSelect={true}
                        disabled={!expStartDate}
                        maxDate={new Date()}
                        showIcon
                        icon={
                          <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
                        }
                        calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                        onFocus={(e) => {
                          if (/iPhone|iPad/i.test(navigator.userAgent)) {
                            e.target.readOnly = true;
                          }
                        }}
                        onBlur={() =>
                          setErrors((prev) => ({
                            ...prev,
                            period: { ...prev.period, touched: true },
                          }))
                        }
                      />
                    </label>
                  )}
                  {(errors.period.touched && (!expStartDate || !expEndDate)) &&(
                    <p className="absolute bottom-[-8rem] text-xs text-red-500 mt-1">
                      {errors.period.error}
                    </p>
                  )}
                </div>
              </div>

              <div
                className={`flex items-end gap-2 ${
                  currentlyWorking ? "col-span-2" : "col-span-2"
                }`}
              >
                <label
                  htmlFor="currentcompany"
                  className="flex items-end text-sm whitespace-nowrap font-medium leading-6 text-gray-900"
                >
                  Currently Working
                  <InformationCircleIcon
                    data-tooltip-id="experience-tooltip"
                    className="w-6 h-6 ml-2"
                  />
                </label>
                <ToggleSwitch
                  id="currentcompany"
                  checked={currentlyWorking}
                  setState={setCurrentlyWorking}
                />
              </div>
              {currentlyWorking && (
                <div className="col-span-1 -ml-32 flex flex-col justify-end">
                  <label
                    htmlFor="notice"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    Notice Period (in months)
                  </label>
                  <input
                    type="number"
                    className="mt-2 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                    value={noticePeriod}
                    onChange={(e) => setNoticePeriod(parseInt(e.target.value))}
                    placeholder="Notice Period (in months)"
                  />
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <>
          <div
            className={`flex items-end ${noUrl ? "col-span-3" : "col-span-3"}`}
          >
            <div className="w-full">
              <>
                {/* Dropdown Companies */}
                {companyName?.name.toLowerCase() !== "other" &&
                  compCity?.name.toLowerCase() !== "other" && (
                    <div className="col-span-4">
                      <SearchDropDown
                        label="Company"
                        placeHolder="eg. entnt.in"
                        dropDownValues={companiesData}
                        state={companyName}
                        setState={(value) => {
                          setCompanyName(value);
                          handleNewCompCityClose();
                        }}
                        mandatoryField
                        hideOthers
                        hyperLink={{
                          label: "Can't find yours? Click here to add it.",
                          action: () => {
                            setShowInstitutionEntry(true);
                          },
                        }}
                        apiRequest={{
                          setPageNumber,
                          setSearchTerm,
                        }}
                      />
                    </div>
                  )}

                {companyName && companyName.name.toLowerCase() === "other" && (
                  <div className="relative col-span-4 flex items-end justify-end w-full">
                    <InputField
                      label="Company"
                      value={
                        noUrl
                          ? isSelfEmployed
                            ? "Self Employed"
                            : "Other"
                          : newCompanyName
                      }
                      mandatoryField
                      isDisabled
                    />
                    <XCircleIcon
                      onClick={handleNewCompNameClose}
                      className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
                    />
                  </div>
                )}
              </>
            </div>
          </div>

          <div className="col-span-3">
            <div>
              <InputField
                label="Title"
                placeHolder="Please Provide Title/Role"
                value={title}
                setValue={setTitle}
                mandatoryField
              />
            </div>
          </div>

          {(compLocationSubCity.trim() === "" ||
            compLocationSubCity === undefined) &&
            companyName?.name.toLowerCase() !== "other" && (
              <div className="col-span-3 flex items-end">
                {compLocation?.name.toLowerCase() === "other" && (
                  <SearchDropDown
                    label="Location"
                    placeHolder="Select City"
                    dropDownValues={filteredCities}
                    state={newCompLocation}
                    setState={setNewCompLocation}
                    hyperLink={{
                      label: "Can't find yours? Click here to add it.",
                      action: () => {
                        setShowAddNewCity(true);
                        setNewCompLocation({ id: "", name: "" });
                      },
                    }}
                    disable={companyName?.id.trim() === ""}
                    mandatoryField
                    hideOthers
                    apiRequest={{
                      setPageNumber: setCityPageNumber,
                      setSearchTerm: setCitySearchTerm,
                    }}
                    error="Please select a Location"
                  />
                )}

                {compLocation?.name.toLowerCase() !== "other" && (
                  <SearchDropDown
                    label="Location"
                    placeHolder="Select City"
                    dropDownValues={selectedCompCities}
                    state={compLocation}
                    setState={(value) => setLocationtoComp(value)}
                    disable={companyName === undefined}
                    mandatoryField
                    error="Please select a Location"
                  />
                )}
              </div>
            )}
          {compLocation?.name.toLowerCase() === "other" &&
            compLocationSubCity.trim() !== "" && (
              <div className="relative col-span-2 flex items-end justify-end w-full">
                <InputField
                  label="Location"
                  value={compLocationSubCity}
                  mandatoryField
                  isDisabled
                />
                <XCircleIcon
                  onClick={handleNewCompCityClose}
                  className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
                />
              </div>
            )}
          <div className="col-span-3">
            <label
              htmlFor="adddescription"
              className="flex text-sm font-medium leading-6 text-gray-900"
            >
              Add Description
              {!isMaritime && <span className="text-red-600 pl-1">*</span>}
              <InformationCircleIcon
                data-tooltip-id="experience-description-tooltip"
                className="w-6 h-6 ml-2"
              />
            </label>
            <textarea
              rows={1}
              className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
              value={relevantExperience}
              name="adddescription"
              onChange={(e) => setRelevantExperience(e.target.value)}
              placeholder="Please provide any comments here"
            />
          </div>
          <div
            className={`flex flex-col justify-end ${
              currentlyWorking ? "col-span-3" : "col-span-3"
            }`}
          >
            <label className="text-sm font-medium leading-6 text-gray-900 col-span-6">
              Period
              <span className="text-red-600 pl-1">*</span>
            </label>
            <div className="flex flex-col sm:flex-row pt-2 gap-6">
              <label className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
                <span className="w-10 sm:w-fit flex items-center h-9">
                  From
                </span>
                <ReactDatePicker
                  className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                  selected={expStartDate}
                  onChange={(date) =>
                    date && handleStartDateChange(addDays(date, 1))
                  }
                  dateFormat="MM/yyyy"
                  showMonthYearPicker
                  placeholderText="eg. 01/2023"
                  closeOnScroll
                  shouldCloseOnSelect={true}
                  minDate={
                    dob
                      ? new Date(
                          dob.getFullYear() + 17,
                          dob.getMonth(),
                          dob.getDate(),
                        )
                      : null
                  }
                  maxDate={new Date()}
                  showIcon
                  icon={
                    <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
                  }
                  calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                  onFocus={(e) => {
                    if (/iPhone|iPad/i.test(navigator.userAgent)) {
                      e.target.readOnly = true;
                    }
                  }}
                  onBlur={() =>
                    setErrors((prev) => ({
                      ...prev,
                      period: { ...prev.period, touched: true },
                    }))
                  }
                />
              </label>
              {!currentlyWorking && (
                <label className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
                  <span className="w-10 sm:w-fit flex items-center h-9">
                    To
                  </span>
                  <ReactDatePicker
                    className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                    selected={expEndDate}
                    onChange={(date) =>
                      date && handleEndDateChange(addDays(date, 1))
                    }
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    minDate={expStartDate}
                    filterDate={(date) => {
                      if (expStartDate) {
                        return !isSameMonth(date, expStartDate);
                      }
                      return true;
                    }}
                    placeholderText="eg. 01/2024"
                    closeOnScroll
                    shouldCloseOnSelect={true}
                    disabled={!expStartDate}
                    maxDate={new Date()}
                    showIcon
                    icon={
                      <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
                    }
                    calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                    onFocus={(e) => {
                      if (/iPhone|iPad/i.test(navigator.userAgent)) {
                        e.target.readOnly = true;
                      }
                    }}
                    onBlur={() =>
                      setErrors((prev) => ({
                        ...prev,
                        period: { ...prev.period, touched: true },
                      }))
                    }
                  />
                </label>
              )}
              {(errors.period.touched && (!expStartDate || !expEndDate)) &&(
                    <p className="absolute bottom-[-5rem] text-xs text-red-500 mt-1">
                      {errors.period.error}
                    </p>
                  )}
            </div>
          </div>

          <div
            className={`flex items-end gap-2 ${
              currentlyWorking ? "col-span-2" : "col-span-2"
            }`}
          >
            <label
              htmlFor="currentcompany"
              className="flex items-end text-sm whitespace-nowrap font-medium leading-6 text-gray-900"
            >
              Currently Working
              <InformationCircleIcon
                data-tooltip-id="experience-tooltip"
                className="w-6 h-6 ml-2"
              />
            </label>
            <ToggleSwitch
              id="currentcompany"
              checked={currentlyWorking}
              setState={setCurrentlyWorking}
            />
          </div>
          {currentlyWorking && (
            <div className="col-span-1 -ml-32 flex flex-col justify-end">
              <label
                htmlFor="notice"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                Notice Period (in months)
              </label>
              <input
                type="number"
                className="mt-2 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                value={noticePeriod}
                onChange={(e) => setNoticePeriod(parseInt(e.target.value))}
                placeholder="Notice Period (in months)"
              />
            </div>
          )}
        </>
      )}
      <ReactTooltip
        id="experience-tooltip"
        place="bottom"
        variant="dark"
        content="Indicate if you are currently working at this job or institution."
        className="!max-w-xs"
      />
      <ReactTooltip
        id="experience-description-tooltip"
        place="bottom"
        variant="dark"
        content="Add a Description of your Role in the Company."
        className="!max-w-xs"
      />
      <ReactTooltip
        id="maritime-description-tooltip"
        place="bottom"
        variant="dark"
        content="Is this job related to Maritime activities?"
        className="!max-w-xs"
      />
      <AddNewCompanyCity
        type="comp"
        open={showAddNewCity}
        setOpen={setShowAddNewCity}
        title="Add Company Location"
        cityandState={cityandState}
        setOpenError={setOpenError}
        newCompanyCityType={{
          compLocationClosestCity,
          setCompLocationClosestCity,
          compLocationSubCity,
          setCompLocationSubCity,
        }}
      />

      <NewInstitutionEntryModal
        title="Add Company Details"
        type="comp"
        open={showInstitutionEntry}
        setOpen={setShowInstitutionEntry}
        institutionType="company"
        cityandState={cityandState}
        setOpenError={setOpenError}
        inEditMode={inEditMode}
        institutionsFields={{
          institutionName: companyName,
          setInstitutionName: setCompanyName,
          newInstitutionName: newCompanyName,
          setNewInstitutionName: setNewCompanyName,
          newInstitutionUrl: newCompanyUrl,
          setNewInstitutionUrl: setnewCompanyUrl,
          institutionCity: compCity,
          setInstitutionCity: setCompCity,
          instClosestCity: compClosestCity,
          setInstClosestCity: setCompClosestCity,
          instSubCity: compSubCity,
          setInstSubCity: setCompSubCity,
        }}
        noUrlFields={{
          noUrl,
          setnoUrl,
          isSelfEmployed,
          setisSelfEmployed,
          notMentioned,
          setnotMentioned,
        }}
      />
    </div>
  );
};
