import "./App.css";
import React, { useEffect, useRef, useState } from "react";
import Home from "./components/pages/home/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Route, BrowserRouter, Routes, useLocation } from "react-router-dom";
import { NotFound } from "./components/NotFound";
import AssignmentSubmission from "./components/pages/application/Assignment/AssignmentSubmission";
import Login from "./components/pages/candidate/Login";
import CandidateProfile from "./components/pages/candidate/CandidateProfile";
import RoleOverview from "./components/pages/application/RoleOverview";
import RoleBasedApplication from "./components/RoleBasedApplication";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function ScrollToAnchor() {
  const location = useLocation();
  const lastHash = useRef("");

  // listen to location change using useEffect with location as dependency
  // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: "smooth", block: "start" });
        lastHash.current = "";
      }, 100);
    }
  }, [location]);

  return null;
}



function App() {
  const [idToken, setIdToken] = useState<string | null>(localStorage?.getItem('idToken'));
  const [candidateId, setCandidateId] = useState<string | null>(null);

  const isLoggedIn = () => {
    const token = localStorage?.getItem('idToken');
    setIdToken(token);

    try {
      if (token) {
        const parsedToken = JSON.parse(token);
        setCandidateId(parsedToken.candidateId);
      }
    } catch (error) {
      console.error("Failed to parse idToken:", error);
    }
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <ScrollToAnchor />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        {!candidateId && !idToken && <Route path="/candidate/login" element={<Login isLoggedIn={isLoggedIn} />} />}
        <Route path="/candidate" element={<CandidateProfile />} />
        <Route path="/candidate/active-roles" element={<CandidateProfile />} />
        <Route path="/candidate/profile" element={<CandidateProfile />} />
        <Route
          path="/assignment/:roleId/:candidateId"
          element={<AssignmentSubmission />}
        />
        <Route path="/role/:id/overview" element={<RoleOverview />} />
        <Route path="/role/:id/application" element={<RoleBasedApplication />} />
        <Route path="/assignment/:roleId/:candidateId" element={<AssignmentSubmission/>}/>
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
