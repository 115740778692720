import { useState } from "react";
import {
  City,
  Company,
  RatingsByDepartment,
  ShippingExperience,
  University,
  dropDownValueType,
  dropDownValuesComp,
} from "../../../../Models";
import { v4 as uuidv4 } from "uuid";

const useShippingExperienceState = () => {
  const [companyName, setCompanyName] = useState<dropDownValueType | Company | undefined>(
    { id: "", name: "" },
  );
  const [experience, setExperience] = useState<ShippingExperience[]>([]);
  const [newCompanyName, setNewCompanyName] = useState<string>("");
  const [expStartDate, setExpStartDate] = useState<Date | null>(null);
  const [expEndDate, setExpEndDate] = useState<Date | null>(null);
  const [noticePeriod, setNoticePeriod] = useState<number | undefined>();
  const [relevantExperience, setRelevantExperience] = useState<string>("");
  const [isRelevantExperience, setIsRelevantExperience] =
    useState<boolean>(true);
  const [title, setTitle] = useState<string>("");
  const [newCompanyUrl, setnewCompanyUrl] = useState<string>("");
  const [companiesData, setCompaniesData] = useState<Company[]|University[]>([]);
  const [isSelfEmployed, setisSelfEmployed] = useState(false);
  const [notMentioned, setnotMentioned] = useState(false);
  const [noUrl, setnoUrl] = useState(false);
  const [showDescription, setShowDescription] = useState(false);
  const [compCity, setCompCity] = useState<City>();
  const [compClosestCity, setCompClosestCity] = useState<City | undefined>();
  const [compSubCity, setCompSubCity] = useState<string>("");
  const [currentlyWorking, setCurrentlyWorking] = useState<boolean>(false);
  const [isOnboard, setIsOnboard] = useState<boolean>();
  const [isMaritime, setIsMaritime] = useState<boolean>(true);
  const [compLocationClosestCity, setCompLocationClosestCity] = useState<City>();
  const [compLocationSubCity, setCompLocationSubCity] = useState<string>("");
  const [ratingState, setRatingState] = useState<dropDownValueType | undefined>();
  const [ratingDepartmentState,setRatingDepartmentState] = useState<dropDownValueType | undefined>();
  const [compLocation, setCompLocation] = useState<City>();
  const [newCompLocation, setNewCompLocation] = useState<City>();
  const [shipType, setShipType] = useState<dropDownValueType | undefined>({
    id: "",
    name: "",
  });
  const [shippingDepartment, setShippingDepartment] = useState<
    dropDownValueType | undefined
  >({ id: "", name: "" });

  const [ratingDepartment, setRatingDepartment] = useState<dropDownValueType[]>(
    [
      { id: uuidv4(), name: "Deck" },
      { id: uuidv4(), name: "Engine" },
      { id: uuidv4(), name: "Rating" },
    ],
  );

  const [ratings, setRatings] = useState<RatingsByDepartment>({
    Deck: [
      { id: uuidv4(), name: "Master" },
      { id: uuidv4(), name: "Chief Officer" },
      { id: uuidv4(), name: "2nd Officer" },
      { id: uuidv4(), name: "3rd Officer" },
      { id: uuidv4(), name: "Junior 3rd or Cadet" },
    ],
    Engine: [
      { id: uuidv4(), name: "Chief Engineer" },
      { id: uuidv4(), name: "2nd Engineer" },
      { id: uuidv4(), name: "3rd Engineer" },
      { id: uuidv4(), name: "4th Engineer" },
      { id: uuidv4(), name: "Junior 4th or Engine Cadet" },
      { id: uuidv4(), name: "Electro-Technical Officer" },
    ],
    Rating: [
      { id: uuidv4(), name: "Bosun" },
      { id: uuidv4(), name: "Able Seaman" },
      { id: uuidv4(), name: "Cook" },
      { id: uuidv4(), name: "Stewart" },
      { id: uuidv4(), name: "Wiper" },
      { id: uuidv4(), name: "Fitter" },
      { id: uuidv4(), name: "Oiler" },
    ],
  });
  const [shipTypes, setShipTypes] = useState<dropDownValueType[]>([
    { id: uuidv4(), name: "LNG Carrier" },
    { id: uuidv4(), name: "LPG Carrier" },
    { id: uuidv4(), name: "Bulk Carrier" },
    { id: uuidv4(), name: "Tankers" },
    { id: uuidv4(), name: "Container" },
    { id: uuidv4(), name: "Offshore Vessel" },
    { id: uuidv4(), name: "General Cargo" },
    { id: uuidv4(), name: "Cruise" },
    { id: uuidv4(), name: "RORO/Car Carrier" },
  ]);
  const [shippingDepartments, setShippingDepartments] = useState<
    dropDownValueType[]
  >([
    { id: uuidv4(), name: "Technical" },
    { id: uuidv4(), name: "Purchasing" },
    { id: uuidv4(), name: "QHSE" },
    { id: uuidv4(), name: "Operations" },
    { id: uuidv4(), name: "Accounting" },
    { id: uuidv4(), name: "Crewing" },
    { id: uuidv4(), name: "Chartering" },
    { id: uuidv4(), name: "IT" },
    { id: uuidv4(), name: "PMS" },
    { id: uuidv4(), name: "Vessel Performance" },
  ]);

  return {
    companyName,
    setCompanyName,
    newCompanyName,
    setNewCompanyName,
    expStartDate,
    setExpStartDate,
    expEndDate,
    setExpEndDate,
    noticePeriod,
    setNoticePeriod,
    relevantExperience,
    setRelevantExperience,
    isRelevantExperience,
    setIsRelevantExperience,
    title,
    setTitle,
    newCompanyUrl,
    setnewCompanyUrl,
    companiesData,
    setCompaniesData,
    isSelfEmployed,
    setisSelfEmployed,
    notMentioned,
    setnotMentioned,
    noUrl,
    setnoUrl,
    showDescription,
    setShowDescription,
    compCity,
    setCompCity,
    compClosestCity,
    setCompClosestCity,
    compSubCity,
    setCompSubCity,
    currentlyWorking,
    setCurrentlyWorking,
    isOnboard,
    setIsOnboard,
    isMaritime,
    setIsMaritime,
    ratingState,
    setRatingState,
    shipType,
    setShipType,
    shippingDepartment,
    setShippingDepartment,
    compLocationClosestCity,
    setCompLocationClosestCity,
    compLocationSubCity,
    setCompLocationSubCity,
    ratingDepartmentState,
    setRatingDepartmentState,
    compLocation,
    setCompLocation,
    newCompLocation,
    setNewCompLocation,
    ratingDepartment,
    setRatingDepartment,
    shipTypes,
    setShipTypes,
    shippingDepartments,
    setShippingDepartments,
    ratings,
    setRatings,
    experience,
    setExperience
  };
};

export default useShippingExperienceState;
