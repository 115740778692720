import { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { isSameMonth, addDays, addMonths } from "date-fns";
import { CalendarDaysIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import SearchDropDown from "../../shared/SearchDropDown";
import { InputField } from "../../shared/InputField";
import ToggleSwitch from "../../shared/ToggleSwitch";
import useCityandState from "../../../hooks/useCityandState";
import useExperienceState from "../../../hooks/useExperienceState";
import useFetchInstitutions from "../../../hooks/useFetchInstitutions";
import { FetchCities, GetCompanies } from "../../../WebCalls";
import NewInstitutionEntryModal from "../../shared/NewInstitutionEntryModal";
import ReactTooltip from "../../shared/ReactTooltip";
import { City, Company } from "../../../Models";
import AddNewCompanyCity from "../../shared/AddNewCompanyCity";
import useFetchCities from "../../../hooks/useFetchCities";
import {
  addSeventeenYears,
  hasCurrentExperiences,
  hasNoCurrentExperiences,
  isWithinOneToTwelveMonths,
} from "../../../Helpers";

type WorkExperienceFieldsType = {
  experienceState: ReturnType<typeof useExperienceState>;
  cityandState: ReturnType<typeof useCityandState>;
  inEditMode: boolean;
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>;
  dob: Date | null;
  selectedCareerId: string;
};

export const WorkExperienceFields = ({
  experienceState,
  cityandState,
  inEditMode,
  setOpenError,
  dob,
  selectedCareerId,
}: WorkExperienceFieldsType) => {
  const storedDate = localStorage.getItem("currentDate");
  const currentDate = storedDate && new Date(storedDate);
  const [showInstitutionEntry, setShowInstitutionEntry] = useState(false);
  const [companiesData, setCompaniesData] = useState<Company[]>([]);
  const [selectedCompCities, setSelectedCompCities] = useState<any>([]);
  const [showAddNewCity, setShowAddNewCity] = useState(false);
  const [errors, setErrors] = useState({
    period: {
      touched: false,
      error: "Please enter the duration of your experience.",
    },
  });

  const {
    companyName,
    setCompanyName,
    newCompanyName,
    setNewCompanyName,
    expStartDate,
    setExpStartDate,
    expEndDate,
    setExpEndDate,
    noticePeriod,
    setNoticePeriod,
    relevantExperience,
    setRelevantExperience,
    isRelevantExperience,
    setIsRelevantExperience,
    title,
    setTitle,
    newCompanyUrl,
    setnewCompanyUrl,
    isSelfEmployed,
    setisSelfEmployed,
    notMentioned,
    setnotMentioned,
    noUrl,
    setnoUrl,
    showDescription,
    setShowDescription,
    compCity,
    setCompCity,
    compClosestCity,
    setCompClosestCity,
    compSubCity,
    setCompSubCity,
    currentlyWorking,
    setCurrentlyWorking,
    compLocation,
    setCompLocation,
    newCompLocation,
    setNewCompLocation,
    compLocationClosestCity,
    setCompLocationClosestCity,
    compLocationSubCity,
    setCompLocationSubCity,
    experience,
    internStatus,
    isInternship,
    setIsInternDurationCorrect,
  } = experienceState;

  const { setState, setTempState, cities } = cityandState;

  const tooltipConfigs = [
    {
      id: "relevant-experience-tooltip",
      content:
        "Is your experience directly related to the requirements of the job?",
    },
    {
      id: "experience-description-tooltip",
      content:
        "Would you like to provide a detailed description of your responsibilities and achievements in this role?",
    },
    {
      id: "currently-working-tooltip",
      content:
        "Indicate if you are currently working at this job or institution.",
    },
  ];

  const {
    data: allCompanies,
    setPageNumber,
    setSearchTerm,
    searchTerm,
    loading,
  } = useFetchInstitutions(GetCompanies);

  useEffect(() => {
    const uniqueCompanies = allCompanies.filter(
      (uni, index, self) =>
        index === self.findIndex((u) => u.name === uni.name),
    );
    setCompaniesData(uniqueCompanies);

    const companyCities = allCompanies
      .filter((c) => c.id === companyName?.id)
      .map((c) => c.city);

    let outsideIndiaCity;
    if (
      !companyCities.some(
        (c) => c?.name.trim().toLowerCase() === "outside india",
      )
    ) {
      outsideIndiaCity = cities.find(
        (city) => city.name.trim().toLowerCase() === "outside india",
      );
    }

    const sortedCities = companyCities.sort((a, b) => {
      if (!a?.name) return 1;
      if (!b?.name) return -1;
      return a.name.localeCompare(b.name);
    });

    setSelectedCompCities([...sortedCities, outsideIndiaCity]);
  }, [allCompanies, companyName]);

  useEffect(() => {
    companyName?.name && setSearchTerm(companyName?.name);
    if (!inEditMode) {
      if (
        !experience.some((e) => e.currentlyWorking) &&
        internStatus === "intern"
      ) {
        setCurrentlyWorking(true);
      } else if (
        !experience.some(
          (e) => e.currentlyWorking && internStatus === "completedintern",
        )
      ) {
        setCurrentlyWorking(false);
      }
    }
  }, []);

  useEffect(() => {
    setExpEndDate(() => (!inEditMode && currentlyWorking ? null : expEndDate));
    setNoticePeriod(() => (currentlyWorking ? noticePeriod : undefined));
  }, [currentlyWorking]);

  const {
    data: newcities,
    setPageNumber: setCityPageNumber,
    setSearchTerm: setCitySearchTerm,
    searchTerm: citySearchTerm,
    loading: cityLoading,
  } = useFetchCities(FetchCities);

  const filteredCities = newcities.filter(
    (city) =>
      !selectedCompCities.some(
        (selectedCity: City) =>
          selectedCity?.id === city?.id || selectedCity?.name === city?.name,
      ),
  );

  const setLocationtoComp = (value: any) => {
    setCompLocation(value);
    if (value?.name.toLowerCase() !== "other") {
      if (value?.name.toLowerCase() === "outside india") {
        const outsideIndiaCity = cities.find(
          (city) => city.name === "Outside India",
        );
        setCompanyName({ ...companyName!, city: outsideIndiaCity });
      } else {
        setCompanyName(allCompanies.find((c) => c.city === value));
      }
    }
    setNewCompLocation({ id: "", name: "" });
  };

  const handleNewCompNameClose = () => {
    setNewCompanyName("");
    setnewCompanyUrl("");
    setCompLocationSubCity("");
    setCompCity({ id: "", name: "" });
    setCompanyName({ id: "", name: "" });
    setState({ id: "", name: "" });
    setnoUrl(false);
    setnotMentioned(false);
    setisSelfEmployed(false);
    setCompLocation({ id: "", name: "" });
    setNewCompLocation({ id: "", name: "" });
    setCompLocationClosestCity({ id: "", name: "" });
  };
  const handleNewCompCityClose = () => {
    setCompLocationSubCity("");
    setTempState({ id: "", name: "" });
    setCompCity({ id: "", name: "" });
    setCompLocation({ id: "", name: "" });
    setNewCompLocation({ id: "", name: "" });
    // setCompLocationClosestCity({ id: "", name: "" });
  };

  const handleExpStartDate = (date: Date) => {
    if (
      isInternship &&
      expEndDate &&
      !isWithinOneToTwelveMonths(date, expEndDate)
    ) {
      setIsInternDurationCorrect(true);
      return;
    }
    setExpStartDate(addDays(date, 1));
  };

  const handleExpEndDate = (date: Date) => {
    if (
      isInternship &&
      expStartDate &&
      isWithinOneToTwelveMonths(expStartDate, date)
    ) {
      setExpEndDate(addDays(date, 1));
    } else if (
      isInternship &&
      expStartDate &&
      !isWithinOneToTwelveMonths(expStartDate, date)
    ) {
      setIsInternDurationCorrect(true);
    } else if (!isInternship) {
      setExpEndDate(addDays(date, 1));
    }
    setErrors((prev) => ({
      ...prev,
      period: {
        ...prev.period,
        error: !date ? "Please enter the duration of your experience." : "",
      },
    }));
  };

  return (
    <div className="space-y-4 lg:grid lg:space-y-0 lg:grid-cols-6 lg:gap-x-6 gap-y-4">
      {/* Dropdown Companies */}
      {companyName?.name.toLowerCase() !== "other" && (
        <div className="col-span-2 flex items-end">
          <SearchDropDown
            label="Company"
            placeHolder="Type at least 3 letters to search..."
            dropDownValues={companiesData}
            state={companyName}
            setState={(value) => {
              setCompanyName(value);
              handleNewCompCityClose();
            }}
            mandatoryField
            hideOthers
            hyperLink={{
              label: "Can't find yours? Click here to add it.",
              action: () => {
                setShowInstitutionEntry(true);
              },
            }}
            apiRequest={{
              setPageNumber,
              setSearchTerm,
              searchTerm,
              loading,
            }}
            error="Please select a company."
          />
        </div>
      )}

      {/* New Company Name or Location */}
      {companyName && companyName.name.toLowerCase() === "other" ? (
        <div className="relative col-span-2 flex items-end justify-end w-full">
          <InputField
            label="Company"
            value={
              noUrl
                ? isSelfEmployed
                  ? "Self Employed"
                  : "Other"
                : newCompanyName
            }
            mandatoryField
            isDisabled
          />
          <XCircleIcon
            onClick={handleNewCompNameClose}
            className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
          />
        </div>
      ) : (
        compLocation?.name.toLowerCase() === "other" &&
        compLocationSubCity.trim() !== "" && (
          <div className="relative col-span-2 flex items-end justify-end w-full">
            <InputField
              label="Location"
              value={compLocationSubCity}
              mandatoryField
              isDisabled
            />
            <XCircleIcon
              onClick={handleNewCompCityClose}
              className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
            />
          </div>
        )
      )}

      {(compLocationSubCity.trim() === "" ||
        compLocationSubCity === undefined) &&
        companyName?.name.toLowerCase() !== "other" && (
          <div className="col-span-2 flex items-end">
            {compLocation?.name.toLowerCase() !== "other" && (
              <SearchDropDown
                label="Location"
                placeHolder="Select City"
                dropDownValues={selectedCompCities}
                state={compLocation}
                setState={(value) => setLocationtoComp(value)}
                disable={companyName?.id.trim() === ""}
                mandatoryField
                error="Please select a company location."
              />
            )}
            {compLocation?.name.toLowerCase() === "other" && (
              <SearchDropDown
                label="Location"
                placeHolder="Select City"
                dropDownValues={filteredCities}
                state={newCompLocation}
                setState={setNewCompLocation}
                hyperLink={{
                  label: "Can't find yours? Click here to add it.",
                  action: () => {
                    setShowAddNewCity(true);
                    setNewCompLocation({ id: "", name: "" });
                  },
                }}
                disable={companyName?.id.trim() === ""}
                mandatoryField
                hideOthers
                apiRequest={{
                  setPageNumber: setCityPageNumber,
                  setSearchTerm: setCitySearchTerm,
                  searchTerm: citySearchTerm,
                  loading: cityLoading,
                }}
                error="Please select a company location."
              />
            )}
          </div>
        )}
      <div className="flex items-end col-span-2">
        <InputField
          label="Title"
          value={title}
          setValue={setTitle}
          placeHolder="Enter your title"
          mandatoryField
          error="Please enter your job title."
          validate={(v: string) =>
            v.trim() === "" ? "Please enter your job title." : ""
          }
        />
      </div>
      <div
        className={`flex flex-col justify-end relative ${
          currentlyWorking && internStatus !== "intern"
            ? "col-span-1"
            : "col-span-2"
        }`}
      >
        <label className="text-sm font-medium leading-6 text-gray-900 col-span-6">
          Period
          <span className="text-red-600 pl-1">*</span>
        </label>
        <div className="flex flex-col sm:flex-row pt-2 gap-6">
          <div className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
            <label htmlFor="exp-start-date">
              <span className="w-10 sm:w-fit flex items-center h-9">From</span>
            </label>
            <ReactDatePicker
              id="exp-start-date"
              className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
              selected={expStartDate}
              onChange={(date) => date && handleExpStartDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="eg. 01/2023"
              closeOnScroll
              shouldCloseOnSelect={true}
              minDate={dob && addSeventeenYears(dob)}
              maxDate={
                inEditMode && expEndDate && expEndDate < new Date(currentDate!)
                  ? expEndDate
                  : new Date(currentDate!)
              }
              showIcon
              icon={
                <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
              }
              calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
              onFocus={(e) => {
                if (/iPhone|iPad/i.test(navigator.userAgent)) {
                  e.target.readOnly = true;
                }
              }}
              onBlur={() =>
                setErrors((prev) => ({
                  ...prev,
                  period: {
                    ...prev.period,
                    touched: true,
                  },
                }))
              }
            />
          </div>
          {((internStatus === "intern" ||
            experience.some(
              (e) =>
                e.isInternship &&
                e.endDate &&
                e.endDate > new Date(currentDate!),
            )) &&
          currentlyWorking
            ? isInternship && internStatus === "intern"
            : !currentlyWorking) && (
            <div className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
              <label htmlFor="exp-end-data">
                <span className="w-10 sm:w-fit flex items-center h-9">To</span>
              </label>
              <ReactDatePicker
                id="exp-end-data"
                className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
                selected={expEndDate}
                onChange={(date) => {
                  date && handleExpEndDate(date);
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                minDate={
                  internStatus === "intern" &&
                  !experience.some(
                    (e) =>
                      e.isInternship &&
                      e.endDate &&
                      e.endDate > new Date(currentDate!),
                  )
                    ? addMonths(new Date(currentDate!), 1)
                    : expStartDate
                }
                filterDate={(date) => {
                  if (expStartDate) {
                    return !isSameMonth(date, expStartDate);
                  }
                  return true;
                }}
                placeholderText="eg. 01/2024"
                closeOnScroll
                shouldCloseOnSelect={true}
                disabled={!expStartDate}
                maxDate={
                  internStatus === "intern" &&
                  !experience.some(
                    (e) =>
                      e.isInternship &&
                      e.endDate &&
                      e.endDate > new Date(currentDate!),
                  )
                    ? null
                    : new Date(currentDate!)
                }
                showIcon
                icon={
                  <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
                }
                calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                onFocus={(e) => {
                  if (/iPhone|iPad/i.test(navigator.userAgent)) {
                    e.target.readOnly = true;
                  }
                }}
              />
            </div>
          )}
        </div>
        {(!expStartDate || (!currentlyWorking && !expEndDate)) &&
          errors.period.touched &&
          errors.period.error &&
          !currentlyWorking && (
            <p className="absolute -bottom-4 whitespace-nowrap text-xs text-red-500">
              {errors.period.error}
            </p>
          )}
      </div>

      {(hasNoCurrentExperiences(experience) ||
        (inEditMode && hasCurrentExperiences(experience, selectedCareerId))) &&
        (internStatus !== "completedintern" || !isInternship) && (
          <div
            className={`flex items-end gap-2 ${
              currentlyWorking ? "col-span-full sm:col-span-2" : "col-span-2"
            }`}
          >
            <label
              htmlFor="currentcompany"
              className="flex items-end text-sm whitespace-nowrap font-medium leading-6 text-gray-900"
            >
              Currently Working
              <InformationCircleIcon
                data-tooltip-id="currently-working-tooltip"
                className="w-6 h-6 ml-2"
              />
            </label>
            <ToggleSwitch
              id="currentcompany"
              checked={currentlyWorking}
              setState={(v) => {
                setCurrentlyWorking(v);
                setErrors((prev) => ({
                  ...prev,
                  period: {
                    ...prev.period,
                    error: "Please enter the duration of your experience.",
                    touched: true,
                  },
                }));
              }}
              disabled={internStatus === "intern"}
            />
          </div>
        )}
      {currentlyWorking && internStatus !== "intern" && (
        <div className="col-span-1 lg:-ml-20 flex flex-col justify-end">
          <label
            htmlFor="notice"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Notice Period (in months)
          </label>
          <input
            type="number"
            className="mt-2 block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange sm:text-sm sm:leading-6"
            value={noticePeriod}
            onChange={(e) => {
              const value = e.target.value;
              const intValue = parseInt(value);

              if (value === "") {
                setNoticePeriod(undefined);
              } else if (intValue <= 3) {
                setNoticePeriod(intValue);
              } else if (intValue > 3) {
                setNoticePeriod(3);
              }
            }}
            placeholder="Notice Period (in months)"
            max={3}
            min={0}
          />
        </div>
      )}

      <div className="col-span-2 flex flex-col justify-end">
        <div className="flex items-end w-full gap-4">
          <div className="flex flex-col gap-2 w-full">
            <label
              htmlFor="relevantexp"
              className="flex text-sm font-medium leading-6 text-gray-900"
            >
              Relevant to Job
              <InformationCircleIcon
                data-tooltip-id="relevant-experience-tooltip"
                className="w-6 h-6 ml-2"
              />
            </label>
            <ToggleSwitch
              id="relevantexp"
              checked={isRelevantExperience}
              setState={setIsRelevantExperience}
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <label
              htmlFor="adddescription"
              className="flex text-sm font-medium leading-6 text-gray-900"
            >
              Add Description
              {notMentioned && <span className="text-red-600 pl-1">*</span>}
              <InformationCircleIcon
                data-tooltip-id="experience-description-tooltip"
                className="w-6 h-6 ml-2"
              />
            </label>
            <ToggleSwitch
              id="adddescript"
              checked={showDescription}
              setState={setShowDescription}
            />
          </div>
        </div>
        {showDescription && (
          <textarea
            rows={1}
            className="mt-2 block w-full rounded-md border-0 py-1.5 sm:py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm sm:leading-6"
            value={relevantExperience}
            onChange={(e) => setRelevantExperience(e.target.value)}
            placeholder="Please provide any comments here"
          />
        )}
      </div>
      {tooltipConfigs.map((tooltip) => (
        <ReactTooltip
          key={tooltip.id}
          id={tooltip.id}
          content={tooltip.content}
        />
      ))}
      <AddNewCompanyCity
        type="comp"
        open={showAddNewCity}
        setOpen={setShowAddNewCity}
        title="Add Company Location"
        cityandState={cityandState}
        setOpenError={setOpenError}
        newCompanyCityType={{
          compLocationClosestCity,
          setCompLocationClosestCity,
          compLocationSubCity,
          setCompLocationSubCity,
        }}
      />
      <NewInstitutionEntryModal
        title="Add Company Details"
        type="comp"
        open={showInstitutionEntry}
        setOpen={setShowInstitutionEntry}
        institutionType="company"
        cityandState={cityandState}
        setOpenError={setOpenError}
        inEditMode={inEditMode}
        institutionsFields={{
          institutionName: companyName,
          setInstitutionName: setCompanyName,
          newInstitutionName: newCompanyName,
          setNewInstitutionName: setNewCompanyName,
          newInstitutionUrl: newCompanyUrl,
          setNewInstitutionUrl: setnewCompanyUrl,
          institutionCity: compCity,
          setInstitutionCity: setCompCity,
          instClosestCity: compClosestCity,
          setInstClosestCity: setCompClosestCity,
          instSubCity: compSubCity,
          setInstSubCity: setCompSubCity,
        }}
        noUrlFields={{
          noUrl,
          setnoUrl,
          isSelfEmployed,
          setisSelfEmployed,
          notMentioned,
          setnotMentioned,
        }}
      />
    </div>
  );
};
