import React, { useEffect, useMemo, useState } from "react";
import useCareerTimelineState from "./useCareerTimelineState";
import useShippingEducationStates from "../components/pages/ShippingApplication/Hooks/useShippingEducationStates";
import useShippingExperienceState from "../components/pages/ShippingApplication/Hooks/useShippingCareerExp";
import useCityandState from "./useCityandState";
import { useNavigate, useParams } from "react-router-dom";
import { Candidate, City, Company, Education, idToken, ShippingExperience, University } from "../Models";
import { v4 as uuidv4 } from "uuid";
import {
  GetCities,
  GetStates,
  PostCandidate,
  PostCity,
  PostCompany,
  PostUniversity,
  removeEducation,
  removeExperience,
  updatedCandidate,
} from "../WebCalls";
import { ResumeUpload } from "../components/pages/application/ResumeUpload";
import useCandidateProfile from "./useCandidateProfile";
import { hasCareerGap, hasNoCurrentExperiences, hasOverlappingEducation, hasOverlappingExpinEdu, transformEducationData, transformShippingExperienceData } from "../Helpers";

const useShippingCareerAction = (
  careerTimelineStates: ReturnType<typeof useCareerTimelineState>,
  educationState: ReturnType<typeof useShippingEducationStates>,
  experienceState: ReturnType<typeof useShippingExperienceState>,
  cityandState: ReturnType<typeof useCityandState>,
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { id } = useParams<{
    id: string;
  }>();
  const [transformedExperience, setTransformedExperience] = useState<any>([]);
  const [transformedEducation, setTransformedEducation] = useState<any>([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [existingCandidate, setExistingCandidate] = useState(false);
  const [isExceedingMaxGap, setIsExceedingMaxGap] = useState(false);
  const navigate = useNavigate();

  const {
    companyName,
    setCompanyName,
    newCompanyName,
    setNewCompanyName,
    expStartDate,
    setExpStartDate,
    expEndDate,
    setExpEndDate,
    noticePeriod,
    setNoticePeriod,
    relevantExperience,
    setRelevantExperience,
    isRelevantExperience,
    setIsRelevantExperience,
    title,
    setTitle,
    newCompanyUrl,
    setnewCompanyUrl,
    companiesData,
    setCompaniesData,
    isSelfEmployed,
    setisSelfEmployed,
    notMentioned,
    setnotMentioned,
    noUrl,
    setnoUrl,
    setShowDescription,
    compCity,
    setCompCity,
    compClosestCity,
    setCompClosestCity,
    compSubCity,
    setCompSubCity,
    currentlyWorking,
    setCurrentlyWorking,
    isMaritime,
    isOnboard,
    ratingState,
    shipType,
    shippingDepartment,
    ratingDepartmentState,
    experience,
    setExperience,
    setIsOnboard,
  } = experienceState;

  const {
    setCities,
    state,
    cities,
    setState,
    setStates,
    tempState,
    setTempState,
  } = cityandState;

  useEffect(() => {
    GetStates().then((data) => setStates(data));
    GetCities().then((data) => setCities(data));
  }, []);

  const {
    firstName,
    lastName,
    dob,
    email,
    cv,
    setCV,
    workLocations,
    linkedinProfile,
    setFirstName,
    setLastName,
    setDOB,
    setLinkedinProfile,
    setEmail,
    setInitialEmail,
    setWorkLocations
  } = careerTimelineStates;

  const {
    expectedGraduation,
    education,
    setEducation,
  } = educationState;

  let allUniversities: University[] = [];
  let allCompanies: Company[] = [];
  let eduexpCities: City[] = [];

  const candidate  = useCandidateProfile();

  useEffect(() => {
    if (!candidate) return;
    setExperience(candidate.experience as ShippingExperience[]);
    setEducation(candidate.education);
    setFirstName(candidate.firstName);
    setLastName(candidate.lastName);
    setDOB(candidate.dateOfBirth);
    setLinkedinProfile(candidate.linkedinProfile ?? "");
    setEmail(candidate.email);
    setInitialEmail(candidate.email);
    setCV(candidate.cv);
    setNoticePeriod(candidate.noticePeriod);
    const currentWorkLocations1 = cities.find(
      (city) => city.id === candidate.workLocation1CityId,
    );
    const currentWorkLocations2 = cities.find(
      (city) => city.id === candidate.workLocation2CityId,
    );
    const workLocationss = [
      currentWorkLocations1,
      currentWorkLocations2,
    ].filter((location): location is City => location !== undefined);
    setWorkLocations(workLocationss);
  }, [candidate, cities]);

  const createShippingModals = (modalConfigs: {
    openDeclaration: boolean;
    setOpenDeclaration: React.Dispatch<React.SetStateAction<boolean>>;
    onSubmit: () => void;
    openError: boolean;
    setOpenError: React.Dispatch<React.SetStateAction<boolean>>;
    showSubcityConfirmation: boolean;
    setShowSubcityConfirmation: React.Dispatch<React.SetStateAction<boolean>>;
    isOverlappingEdu: boolean;
    setIsOverlappingEdu: React.Dispatch<React.SetStateAction<boolean>>;
    isOverlappingExp: boolean;
    setIsOverlappingExp: React.Dispatch<React.SetStateAction<boolean>>;
    deleteCareer: boolean;
    setDeleteCareer: React.Dispatch<React.SetStateAction<boolean>>;
    removeCareer: () => void;
    hasOverlappingEdu: boolean;
    setHasOverlappingEdu: React.Dispatch<React.SetStateAction<boolean>>;
    showCV: boolean;
    setShowCV: React.Dispatch<React.SetStateAction<boolean>>;
    hasOverlappingExp: boolean;
    setHasOverlappingExp: React.Dispatch<React.SetStateAction<boolean>>;
    cvFileName: string;
    setCvFileName: React.Dispatch<React.SetStateAction<string>>;
  }) => [
    {
      modalId: "declaration-modal",
      type: "info",
      open: modalConfigs.openDeclaration,
      setOpen: modalConfigs.setOpenDeclaration,
      title: "Declaration",
      descriptionList: [
        {
          descId: 1,
          description:
            "I hereby submit my application to work as a contractor with ENTNT and acknowledge that this submission does not establish an employer-employee relationship with ENTNT and does not create any liability, responsibility, or commitment on behalf of ENTNT.",
        },
        {
          descId: 2,
          description:
            "I affirm that the information I have provided in this application is accurate and complete. I understand that any misrepresentation may lead to the termination of my contract without prior notice.",
        },
        {
          descId: 3,
          description:
            "I am aware that ENTNT may use the information I have supplied at its sole discretion for the purpose of conducting background checks before entering into a contract agreement with me.",
        },
        {
          descId: 4,
          description:
            "I hereby grant ENTNT the irrevocable and unconditional authorization to verify and obtain, as necessary, any information related to me during the application and, if applicable, contracting process, at its sole discretion.",
        },
      ],
      buttonContents: [
        {
          buttonId: 1,
          buttonLabel: "Submit",
          buttonAction: modalConfigs.onSubmit,
        },
        { buttonId: 2, buttonLabel: "Cancel", isOutline: true },
      ],
    },
    {
      modalId: "success-modal",
      type: "info",
      open: openSuccess,
      setOpen: setOpenSuccess,
      title: "Application successful",
      description:
        "Your application has been submitted successfully! You will receive an email with further instructions soon.",
      buttonContents: [
        { buttonId: 1, buttonLabel: "Go back to home", buttonNavigation: "/" },
      ],
    },
    {
      modalId: "error-modal",
      type: "danger",
      open: modalConfigs.openError,
      setOpen: modalConfigs.setOpenError,
      title: "Missing required information",
      description:
        "Please make sure you have filled in all mandatory fields (marked with a red asterisk *)",
      buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
    },
    {
      modalId: "existing-candidate-modal",
      type: "danger",
      open: existingCandidate,
      setOpen: setExistingCandidate,
      title: "Applicant Status",
      description:
        "Thank you for your interest! You've already applied for this role.",
      buttonContents: [
        { buttonId: 1, buttonLabel: "Go back to home", buttonNavigation: "/" },
      ],
    },
    {
      modalId: "subcity-modal",
      type: "success",
      open: modalConfigs.showSubcityConfirmation,
      setOpen: modalConfigs.setShowSubcityConfirmation,
      title: "Add State & Nearest City",
      description:
        "Please select the state and nearest city along with your city name.",
      buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
    },
    {
      modalId: "isoverlappingedu-modal",
      type: "danger",
      open: modalConfigs.isOverlappingEdu,
      setOpen: modalConfigs.setIsOverlappingEdu,
      title: "Review and Correct Education Entry",
      description:
        "This education overlaps with an existing entry. Please review and correct.",
      buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
    },
    {
      modalId: "isoverlappingexp-modal",
      type: "danger",
      open: modalConfigs.isOverlappingExp,
      setOpen: modalConfigs.setIsOverlappingExp,
      title: "Review and Correct Experience Entry",
      description:
        "This experience overlaps with an existing entry. Please review and correct it. If that is correct, you can save it.",
      buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
    },
    {
      modalId: "hasoverlappingedu-modal",
      type: "danger",
      open: modalConfigs.hasOverlappingEdu,
      setOpen: modalConfigs.setHasOverlappingEdu,
      title: "Review and Correct Education Entry",
      description:
        "Some of your education overlaps with an existing entry. Please review and correct.",
      buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
    },
    {
      modalId: "hasoverlappingexp-modal",
      type: "danger",
      open: modalConfigs.hasOverlappingExp,
      setOpen: modalConfigs.setHasOverlappingExp,
      title: "Review and Correct Experience Entry",
      description:
        "Some of your experience overlaps with an existing entry. Please review and correct.",
      buttonContents: [{ buttonId: 1, buttonLabel: "Back to application" }],
    },
    {
      modalId: "delete-career-modal",
      type: "danger",
      open: modalConfigs.deleteCareer,
      setOpen: modalConfigs.setDeleteCareer,
      title: "Delete Career",
      description:
        "Are you sure you want to delete? This action cannot be undone.",
      buttonContents: [
        {
          buttonId: 1,
          buttonLabel: "Delete",
          buttonAction: modalConfigs.removeCareer,
        },
        { buttonId: 2, buttonLabel: "Cancel", isOutline: true },
      ],
    },
    {
      modalId: "uploac-cv-modal",
      type: "info",
      open: modalConfigs.showCV,
      setOpen: () => {},
      title: "Upload your resume",
      description: (
        <div className="flex items-center">
          <ResumeUpload
            cvFileName={modalConfigs.cvFileName}
            setCvFileName={modalConfigs.setCvFileName}
            cv={cv}
            setCV={setCV}
          />
        </div>
      ),
      buttonContents: [
        {
          buttonId: 1,
          buttonLabel: "Continue to Submit",
          buttonAction: () => {
            if (cv.trim().length !== 0) {
              modalConfigs.setOpenDeclaration(true);
              modalConfigs.setShowCV(false);
            }
          },
          isDisabled: cv.trim().length === 0,
        },
      ],
    },
    {
      modalId: "check-max-gap-modal",
      type: "danger",
      open: isExceedingMaxGap,
      setOpen: setIsExceedingMaxGap,
      title: "Eligibility Notification",
      description: `Thank you for your interest. After reviewing your profile, we found that it does not meet our eligibility requirements for career progression. 
        We appreciate your understanding and encourage you to consider future opportunities with us.`,
      buttonContents: [
        { buttonId: 1, buttonLabel: "Go back to home", buttonNavigation: "/" },
      ],
    },
  ];


  function Apply(
    timelineData: any,
    setIsOverlappingGap: React.Dispatch<React.SetStateAction<boolean>>,
    idToken: idToken | null,
    setOpenDeclaration: React.Dispatch<React.SetStateAction<boolean>>,
    onEditSave: () => void,
    setHasOverlappingEdu: React.Dispatch<React.SetStateAction<boolean>>,
    setShowCV: React.Dispatch<React.SetStateAction<boolean>>,
    timeline: any,
  ) {
    if (
      firstName.trim().length === 0 ||
      lastName.trim().length === 0 ||
      email.trim().length === 0 ||
      workLocations.length === 0 ||
      education.length === 0 ||
      !dob
    ) {
      setOpenError(true);
    } else if (hasOverlappingEducation(education)) {
      setHasOverlappingEdu(true);
      return;
    } else if (timeline.some((t: any) => t.gap && t.totalMonths > 24)) {
      setIsExceedingMaxGap(true);
      return;
    } else if (
      hasCareerGap(timelineData) ||
      hasOverlappingExpinEdu(timelineData) ||
      experience.length>0 && hasNoCurrentExperiences(timelineData)
    ) {
      setIsOverlappingGap(true);
      return;
    } else {
      if (id) {
        if(!idToken) setShowCV(true);
      } else onEditSave();
    }
  }

  useMemo(() => {
    experience.length > 0 &&
    transformShippingExperienceData(experience).then((data) =>
        setTransformedExperience(data),
      );
    transformEducationData(education).then((data) =>
      setTransformedEducation(data),
    );
  }, [experience, education]);

  [...transformedEducation, ...transformedExperience].forEach(
    (career: Education | ShippingExperience) => {
      if ("university" in career) {
        allUniversities.push(career?.university);
        career?.university.city && eduexpCities.push(career.university.city);
      } else {
        allCompanies.push(career?.company);
        career?.company.city && eduexpCities.push(career?.company?.city);
      }
    },
  );


  async function Submit(
    setOpenDeclaration: React.Dispatch<React.SetStateAction<boolean>>,
    setloading: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    setloading(true);
    setOpenDeclaration(false);
    if (id === undefined) return;

    let yearsOfExp = 0;
    experience.forEach((exp) => {
      if (exp.startDate && exp.endDate) {
        const diffYears = Math.abs(
          exp.endDate.getFullYear() - exp.startDate.getFullYear(),
        );
        yearsOfExp += diffYears;
      }
    });

    const candidate: Candidate = {
      id: uuidv4(),
      firstName: firstName,
      lastName: lastName,
      dateOfBirth: dob,
      email: email,
      address: "",
      cv: cv,
      linkedinProfile: linkedinProfile,
      coverLetter: "",
      education: transformedEducation,
      experience: transformedExperience,
      noticePeriod: noticePeriod ?? 0,
      yearsOfExperience: yearsOfExp,
      expectedGraduationDate: expectedGraduation,
      workLocation1:
        workLocations[0].name.trim() !== ""
          ? workLocations[0].name
          : workLocations[0].nearestCity ?? "",
      workLocation1CityId: workLocations[0].id,
      workLocation2:
        workLocations[1] && workLocations[1].name.trim() !== ""
          ? workLocations[1].name ?? workLocations[1].nearestCity ?? ""
          : workLocations[1]?.nearestCity ?? "",
      workLocation2CityId: workLocations[1] && workLocations[1].id,
    };

    try {
      if ([...eduexpCities, ...workLocations].length !== 0) {
        await PostCity([...eduexpCities, ...workLocations]);
      }
      if (allUniversities.length !== 0) {
        await PostUniversity(allUniversities);
      }
      if (allCompanies.length !== 0) {
        await PostCompany(allCompanies);
      }
      await PostCandidate(candidate, id);
      setOpenSuccess(true);
      setloading(false);
    } catch (error) {
      setExistingCandidate(true);
    } finally {
      setTimeout(() => {
        navigate("/");
      }, 5000);
    }
  }

  async function updateShippingCandidate(
    idToken: idToken | null,
    setUpdateNotification: React.Dispatch<React.SetStateAction<boolean>>,
    setloading: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    setloading(true);
    if (idToken) {
      const { candidateId } = idToken;
      const currentEducation = candidate?.education;
      const currentExperience = candidate?.experience;

      const removedEducationIds = await currentEducation
        ?.filter(
          (initialEdu) => !education?.some((edu) => edu.id === initialEdu.id),
        )
        ?.map((edu) => edu.id);

      const removedExperienceIds = await currentExperience
        ?.filter(
          (initialExp) => !experience?.some((exp) => exp.id === initialExp.id),
        )
        ?.map((exp) => exp.id);

      if (removedEducationIds && removedEducationIds?.length > 0) {
        await removeEducation(candidateId, removedEducationIds);
      }
      if (removedExperienceIds && removedExperienceIds?.length > 0) {
        await removeExperience(candidateId, removedExperienceIds);
      }

      if ([...eduexpCities, ...workLocations].length !== 0) {
        await PostCity([...eduexpCities, ...workLocations]);
      }
      if (allUniversities.length !== 0) {
        await PostUniversity(allUniversities);
      }
      if (allCompanies.length !== 0) {
        await PostCompany(allCompanies);
      }

      let yearsOfExp = 0;
      experience.forEach((exp) => {
        if (exp.startDate && exp.endDate) {
          const diffYears = Math.abs(
            exp.endDate.getFullYear() - exp.startDate.getFullYear(),
          );
          yearsOfExp += diffYears;
        }
      });
      const currentCandidate: Candidate = {
        id: candidateId,
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dob,
        email: email,
        address: "",
        cv: cv,
        linkedinProfile: linkedinProfile,
        coverLetter: "",
        education: transformedEducation,
        experience: transformedExperience,
        noticePeriod: noticePeriod ?? 0,
        yearsOfExperience: yearsOfExp,
        expectedGraduationDate: expectedGraduation,
        workLocation1:
          workLocations[0]?.name.trim() !== ""
            ? workLocations[0]?.name
            : workLocations[0]?.nearestCity ?? "",
        workLocation1CityId: workLocations[0]?.id,
        workLocation2:
          workLocations[1] && workLocations[1]?.name.trim() !== ""
            ? workLocations[1]?.name ?? workLocations[1]?.nearestCity ?? ""
            : workLocations[1]?.nearestCity ?? "",
        workLocation2CityId: workLocations[1] && workLocations[1]?.id,
      };
      await updatedCandidate(currentCandidate)
        .then(() => {
          setUpdateNotification(true);
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((er) => {});
    }
    setloading(false)
  }

  return {
    createShippingModals,
    updateShippingCandidate,
    Apply,
    Submit
  };
};

export default useShippingCareerAction;
