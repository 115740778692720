import { Fragment, useEffect, useState } from "react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import { MinusCircleIcon, PlusCircleIcon } from "@heroicons/react/20/solid";

import ReactTooltip from "../../shared/ReactTooltip";
import { RadioButtons } from "../../shared/RadioButtons";
import useEducationState from "../../../hooks/useEducationState";
import useExperienceState from "../../../hooks/useExperienceState";

type ProfileHeaderType = {
  showEduEdit: { showUG: boolean; showPG: boolean };
  setshowEduEdit: React.Dispatch<
    React.SetStateAction<{ showUG: boolean; showPG: boolean }>
  >;
  showExpEdit: { showIntern: boolean; showExp: boolean };
  setShowExpEdit: React.Dispatch<
    React.SetStateAction<{ showIntern: boolean; showExp: boolean }>
  >;
  clearEduStates: () => void;
  clearExpStates: () => void;
  validatePersonalInfo: boolean;
  isStudentRole?: boolean;
  educationState: ReturnType<typeof useEducationState>;
  experienceState: ReturnType<typeof useExperienceState>;
};

export const ProfileHeader = ({
  showEduEdit,
  showExpEdit,
  setshowEduEdit,
  setShowExpEdit,
  clearEduStates,
  clearExpStates,
  validatePersonalInfo,
  isStudentRole,
  educationState,
  experienceState,
}: ProfileHeaderType) => {
  const [error, setError] = useState("");

  const {
    setIsStudent,
    setIsPostgraduate,
    setDegreeType,
    education,
    pgStatus,
    setPGStatus,
    setChangePGStatus,
    isInternshipAllowed,
    setIsInternshipAllowed,
  } = educationState;
  const {
    internStatus,
    setInternStatus,
    currentlyWorking,
    setCurrentlyWorking,
    experience,
    setChangeInternStatus,
    setIsDuplicateCurrentExp,
    isInternship,
    setIsInternship,
  } = experienceState;

  const tooltipConfigs = [
    {
      id: "education-tooltip",
      content:
        "Please provide all relevant studies for this current job opening and include past and present education. If your studies are not relevant to the job, kindly indicate by checking the appropriate box.",
    },
    {
      id: "workexperience-tooltip",
      content:
        "Please provide all work experience relevant to this current job opening. We do consider internships as job experience, so please include those as well. Any non-relevant job experience should be indicated by checking the relevant tickbox.",
    },
    {
      id: "internship-tooltip",
      content:
        "Please include all internship experiences relevant to the current job opening, as we consider internships to be valuable job experience. If you have any non-relevant job experience, kindly indicate this by selecting the relevant tickbox.",
    },
    {
      id: "intern-ques-tooltip",
      content:
        "Candidates who are available during regular working hours as part of their final-year internship will be given strong consideration.",
    },
  ];

  type renderProfileFieldsType = {
    label: string;
    handleClick: () => void;
    showEdit: boolean;
    tooltipId?: string;
    mandatoryField?: boolean;
    disabled?: boolean;
  };

  const renderProfileFields = ({
    label,
    handleClick,
    showEdit,
    tooltipId,
    mandatoryField,
    disabled,
  }: renderProfileFieldsType) => (
    <div className="flex items-center justify-between w-60">
      <h3 className="flex text-sm font-semibold text-gray-900">
        {label}
        {mandatoryField && <span className="text-red-600 pl-1">*</span>}
        <InformationCircleIcon
          data-tooltip-id={tooltipId}
          className="w-6 h-6 ml-2"
        />
      </h3>
      <button
        type="button"
        className={`rounded-full text-center text-sm font-semibold focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-entntorange ${
          showEdit
            ? "text-red-500"
            : `${
                disabled
                  ? "text-gray-300 cursor-default"
                  : "text-entntblue hover:text-entntorange"
              } `
        }`}
        onClick={handleClick}
        disabled={disabled}
      >
        {!showEdit ? (
          <PlusCircleIcon
            height={44}
            onClick={() => {
              setTimeout(() => {
                !validatePersonalInfo &&
                  window.scrollTo(0, document.body.scrollHeight);
              }, 0);
            }}
          />
        ) : (
          <MinusCircleIcon
            height={44}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          />
        )}
      </button>
    </div>
  );

  const renderInternshipQues = () => (
    <div className="py-2">
      <h3 className="text-sm font-semibold text-gray-900">
        As a final-year student, are you currently enrolled in a program that
        includes a 6-month internship, allowing you to be available during
        regular working hours?
        <span className="text-red-600 pl-1">
          *
          <span className="w-6 h-6 inline-block">
            <InformationCircleIcon
              data-tooltip-id="intern-ques-tooltip"
              className="!w-6 !h-6 ml-2 inline"
            />
          </span>
        </span>
      </h3>
      <div className="text-xs pt-2">
        <RadioButtons
          id="intern-allowed"
          label="Yes"
          name="intern-question"
          value="allowed"
          selectedValue={isInternshipAllowed}
          setSelectedValue={setIsInternshipAllowed}
        />
        <RadioButtons
          id="intern-not-allowed"
          label="No"
          name="intern-question"
          value="notallowed"
          selectedValue={isInternshipAllowed}
          setSelectedValue={setIsInternshipAllowed}
        />
      </div>
    </div>
  );

  const renderPGStatus = () => (
    <div className="py-2">
      <h3 className="text-sm font-semibold text-gray-900">
        Postgraduate Education
        <span className="text-red-600 pl-1">*</span>
      </h3>
      <div className="text-xs pt-2">
        <RadioButtons
          id="no-pg"
          label="No Postgraduate Education"
          name="pg-status"
          value="nopg"
          selectedValue={pgStatus}
          setSelectedValue={(v) => {
            if (education.some((e) => e.postGrad)) {
              setChangePGStatus(true);
              return;
            }
            setPGStatus(v);
          }}
        />
        <RadioButtons
          id="currently-studying"
          label="Currently Pursuing Postgraduate"
          name="pg-status"
          value="studyingpg"
          selectedValue={pgStatus}
          setSelectedValue={(v) => {
            if (education.some((e) => e.postGrad)) {
              setChangePGStatus(true);
              return;
            }
            setPGStatus(v);
            setIsStudent(true);
          }}
        />
        <RadioButtons
          id="completed-pg"
          label="Completed Postgraduate Degree"
          name="pg-status"
          value="completedpg"
          selectedValue={pgStatus}
          setSelectedValue={(v) => {
            if (education.some((e) => e.postGrad)) {
              setChangePGStatus(true);
              return;
            }
            setPGStatus(v);
            setIsStudent(false);
          }}
        />
      </div>
    </div>
  );

  const renderInternshipStatus = () => (
    <div className="py-2">
      <h3 className="text-sm font-semibold text-gray-900">
        Internship Experience
        <span className="text-red-600 pl-1">*</span>
      </h3>
      <div className="text-xs pt-2">
        <RadioButtons
          id="no-intern"
          label="No Internship Experience"
          name="intern-status"
          value="nointern"
          selectedValue={internStatus}
          setSelectedValue={(v) => {
            if (experience.some((e) => e.isInternship)) {
              setChangeInternStatus(true);
              return;
            }
            setInternStatus(v);
          }}
        />
        <RadioButtons
          id="pursuing-intern"
          label="Currently Pursuing Internship"
          name="intern-status"
          value="intern"
          selectedValue={internStatus}
          setSelectedValue={(v) => {
            if (experience.some((e) => e.currentlyWorking)) {
              setIsDuplicateCurrentExp(true);
              return;
            }
            if (experience.some((e) => e.isInternship)) {
              setChangeInternStatus(true);
              return;
            }
            setInternStatus(v);
            setCurrentlyWorking(true);
          }}
        />
        <RadioButtons
          id="completed-intern"
          label="Completed Internship"
          name="intern-status"
          value="completedintern"
          selectedValue={internStatus}
          setSelectedValue={(v) => {
            if (experience.some((e) => e.isInternship)) {
              setChangeInternStatus(true);
              return;
            }
            setInternStatus(v);
            setCurrentlyWorking(false);
          }}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (validatePersonalInfo) {
      setshowEduEdit({ showUG: false, showPG: false });
      setShowExpEdit({ showIntern: false, showExp: false });
    }
  }, [validatePersonalInfo]);

  const handleEducation = (degreeType: string) => {
    if (validatePersonalInfo) {
      setError("Please provide your personal information.");
      return;
    } else {
      setError("");
      clearEduStates();

      if (degreeType === "ug") {
        setshowEduEdit({ ...showEduEdit, showUG: !showEduEdit.showUG });
        setDegreeType({ id: "1", name: "Undergraduate" });
        setIsPostgraduate(false);
      } else {
        setshowEduEdit({ ...showEduEdit, showPG: !showEduEdit.showPG });
        setDegreeType({ id: "2", name: "Postgraduate" });
        setIsPostgraduate(true);
      }
      setShowExpEdit({ showIntern: false, showExp: false });
    }
  };
  const handleExperience = (expType: string) => {
    if (validatePersonalInfo) {
      setError("Please provide your personal information.");
      return;
    } else {
      setError("");
      clearExpStates();

      if (expType === "intern") {
        setShowExpEdit({ ...showExpEdit, showIntern: !showExpEdit.showIntern });
        setIsInternship(true);
      } else {
        setShowExpEdit({ ...showExpEdit, showExp: !showExpEdit.showExp });
        setIsInternship(false);
      }

      setshowEduEdit({ showUG: false, showPG: false });
    }
  };

  return (
    <>
      <h1 className="text-base font-semibold text-gray-900">Profile</h1>
      <div className="w-full flex flex-col items-start justify-center">
        <div className="py-2 rounded-md space-y-2">
          {isStudentRole && renderInternshipQues()}
          {!showExpEdit.showIntern &&
            !showExpEdit.showExp &&
            !showEduEdit.showPG &&
            renderProfileFields({
              label: "Under Graduate",
              handleClick: () => handleEducation("ug"),
              showEdit: showEduEdit.showUG,
              tooltipId: "education-tooltip",
              mandatoryField: true,
              disabled:
                education.length > 0 ||
                (isStudentRole && isInternshipAllowed === ""),
            })}

          {!isStudentRole &&
            !showEduEdit.showPG &&
            !showEduEdit.showUG &&
            !showExpEdit.showIntern &&
            !showExpEdit.showExp &&
            renderPGStatus()}

          {!isStudentRole &&
            !showExpEdit.showIntern &&
            !showExpEdit.showExp &&
            !showEduEdit.showUG &&
            renderProfileFields({
              label: "Post Graduate",
              handleClick: () => handleEducation("pg"),
              showEdit: showEduEdit.showPG,
              tooltipId: "education-tooltip",
              mandatoryField:
                pgStatus === "studyingpg" || pgStatus === "completedpg",
              disabled:
                education.length === 0 ||
                pgStatus === "nopg" ||
                pgStatus === "",
            })}

          {!showEduEdit.showPG &&
            !showEduEdit.showUG &&
            !showExpEdit.showIntern &&
            !showExpEdit.showExp &&
            renderInternshipStatus()}

          {!(showEduEdit.showUG || showEduEdit.showPG) &&
            !showExpEdit.showExp &&
            renderProfileFields({
              label: "Internship",
              handleClick: () => handleExperience("intern"),
              showEdit: showExpEdit.showIntern,
              tooltipId: "internship-tooltip",
              mandatoryField:
                internStatus === "intern" || internStatus === "completedintern",
              disabled: internStatus === "" || internStatus === "nointern",
            })}

          {!isStudentRole &&
            !(showEduEdit.showUG || showEduEdit.showPG) &&
            !showExpEdit.showIntern &&
            renderProfileFields({
              label: "Work Experience",
              handleClick: () => handleExperience("exp"),
              showEdit: showExpEdit.showExp,
              tooltipId: "workexperience-tooltip",
              disabled:
                ((internStatus === "intern" ||
                  internStatus === "completedintern") &&
                  experience.length === 0 &&
                  !experience.some((e) => e.isInternship)) ||
                internStatus === "",
            })}
        </div>
        {error && validatePersonalInfo && (
          <p className=" whitespace-nowrap text-xs text-red-500">{error}</p>
        )}
      </div>
      {tooltipConfigs.map((tooltip) => (
        <ReactTooltip
          key={tooltip.id}
          id={tooltip.id}
          content={tooltip.content}
        />
      ))}
    </>
  );
};
