import {
  Role,
  ExtractedCVData,
  Candidate,
  City,
  University,
  Company,
  CandidateStatus,
  RoleStatus,
  Assignment,
  dropDownValueType,
  idToken,
} from "./Models";
const APIURL = "https://entntcareersapi.azurewebsites.net/api";
// const APIURL = "https://localhost:7154/api";

export async function GetCities() {
  const res = await fetch(`${APIURL}/MasterData/Cities`);
  const cities: City[] = await res.json();
  return cities;
}

export async function GetUniversities(
  search = "",
  pageNumber = 1,
  pageSize = 15,
  urlSearch = "",
) {
  const res = await fetch(
    `${APIURL}/MasterData/ConfirmedUniversities?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&urlSearch=${urlSearch}`,
  );
  const unis = await res.json();
  return unis;
}

export async function GetCompanies(
  search = "",
  pageNumber = 1,
  pageSize = 15,
  urlSearch = "",
) {
  const res = await fetch(
    `${APIURL}/MasterData/ConfirmedCompanies?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}&urlSearch=${urlSearch}`,
  );
  const companies = await res.json();
  return companies;
}

export async function GetStates() {
  const res = await fetch(`${APIURL}/MasterData/States`);
  const states: dropDownValueType[] = await res.json();
  return states;
}

export async function GetRoles() {
  const res = await fetch(`${APIURL}/Roles`);
  const roles: Role[] = await res.json();
  return roles;
}

export async function GetRole(id: string) {
  const res = await fetch(`${APIURL}/Roles/${id}`);
  if (!res.ok) {
    throw new Error('Network response was not ok');
}
  const role: Role = await res.json();
  return role;
}

export async function ExtractCVData(cv: Blob) {
  const formData = new FormData();
  formData.append("cv", cv);

  const res = await fetch(`${APIURL}/AI/ExtractCV`, {
    method: "POST",
    body: formData,
  });

  const data: ExtractedCVData = await res.json();
  return data;
}

export async function PostCandidate(candidate: Candidate, roleId: string) {
  const res = await fetch(`${APIURL}/Candidates/${roleId}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(candidate),
  });
  if (res.status === 409) {
    const errorResponse = await res.json();
    throw new Error(errorResponse.error);
  }
}

export async function submitAssignmentLink(submissionData: any) {
  const response = await fetch(`${APIURL}/Assignments/assignmentLinkSub`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(submissionData),
  });

  if (!response.ok) {
    throw new Error("Failed to submit assignment");
  }
}

export async function GetCandidateData(candidateId: string, roleId: string) {
  try {
    const response = await fetch(
      `${APIURL}/Assignments/candidateData?candidateId=${candidateId}&roleId=${roleId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to check assignment submission");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw new Error(`Failed to check assignment submission:`);
  }
}

export async function fetchComments(candidateId: string) {
  try {
    const response = await fetch(
      `${APIURL}/Assignments/comment?candidateId=${candidateId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      },
    );

    if (!response.ok) {
      throw new Error("Failed to fetch comments");
    }

    const data = await response.json();
    return data.comments;
  } catch (error) {
    throw new Error(`Failed to fetch comments:`);
  }
}
export async function PostUniversity(universities: University[]) {
  const res = await fetch(`${APIURL}/MasterData/AddUniversities`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(universities),
  });

  if (!res.ok) {
    const errorResponse = await res.json();
    throw new Error(errorResponse.message);
  }
  const json = res.json();
  return json;
}
export async function PostCompany(companies: Company[]) {
  const res = await fetch(`${APIURL}/MasterData/AddCompanies`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(companies),
  });

  if (!res.ok) {
    const errorResponse = await res.json();
    throw new Error(errorResponse.message);
  }
}

export async function requestOtp(email: string) {
  const res = await fetch(`${APIURL}/Auth/generate`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(email),
  });

  if (!res.ok) {
    const json: string = await res.json();
    throw new Error(json);
  }
}

export async function candidateLogin({
  email,
  otp,
}: {
  email: string;
  otp: string;
}) {
  const res = await fetch(`${APIURL}/Auth/Login`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, otp }),
  });

  if (res.ok) {
    const json: idToken = await res.json();
    return json;
  } else {
    const json: string = await res.json();
    throw new Error(json);
  }
}

export async function GetRoleForCandidate(candidateId: string, token: string) {
  const res = await fetch(`${APIURL}/Candidates/${candidateId}/roles`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (res.ok) {
    const role: Role[] = await res.json();
    return role;
  }
}

export async function GetCandidateStatus(candidateId: string, token: string) {
  const res = await fetch(`${APIURL}/Candidates/${candidateId}/Status`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const CandidateStatus: CandidateStatus[] = await res.json();
  return CandidateStatus;
}

export async function GetStatusForRole(roleId: string, token: string) {
  const res = await fetch(`${APIURL}/Roles/${roleId}/Status`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const roleStatus: RoleStatus[] = await res.json();
  return roleStatus;
}

export async function GetCandidate(candidateId: string, token: string) {
  const res = await fetch(`${APIURL}/Candidates/${candidateId}/full`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const candidate: Candidate = await res.json();
  return candidate;
}

export async function GetCandidateScore(candidateId: string, token: string) {
  const res = await fetch(`${APIURL}/Candidates/${candidateId}/score`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  const score: { score: number; roleId: string }[] = await res.json();
  return score;
}

export async function GetAssignment(
  candidateId: string,
  roleId: string,
  // token: string,
) {
  const res = await fetch(
    `${APIURL}/Assignments/candidateData?candidateId=${candidateId}&roleId=${roleId}`,
    {
      headers: {
        // Authorization: `Bearer ${token}`,
      },
    },
  );
  const assignments: Assignment = await res.json();
  return assignments;
}
export async function PostCity(cities: City[]) {
  const res = await fetch(`${APIURL}/MasterData/AddCities`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(cities),
  });

  if (!res.ok) {
    const errorResponse = await res.json();
    throw new Error(errorResponse.message);
  }
}

export async function checkUniversityExists(id: string) {
  try {
    const response = await fetch(`${APIURL}/MasterData/UniversityExists/${id}`);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const exists = await response.json();
    return exists;
  } catch (error) {
    console.error(
      "There was a problem while checking the University data:",
      error,
    );
    return null;
  }
}

export const removeEducation = async (candidateId: string, educationIds: string[] | undefined) => {
  try {
    await fetch(`${APIURL}/MasterData/Education?Candidateid=${candidateId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(educationIds),
    });
    return;

  } catch (error) {
    console.error(
      "There was a problem while deleting the Education:",
      error,
    );
    return;
  }
}

export const removeExperience = async (candidateId: string, experienceIds: string[] | undefined) => {
  try {
    await fetch(`${APIURL}/MasterData/Experience?Candidateid=${candidateId}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(experienceIds),
    });
    return;

  } catch (error) {
    console.error(
      "There was a problem while deleting the experience:",
      error,
    );
    return;
  }
}

export const updatedCandidate = async (candidate: Candidate | undefined) => {
  try {
    await fetch(`${APIURL}/MasterData/Candidate/Update`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(candidate),
    });
    return;

  } catch (error) {
    console.error(
      "There was a problem while deleting the experience:",
      error,
    );
    return;
  }

}

export const emailExists = async (email: string) => {
  try {
    const res = await fetch(`${APIURL}/Candidates/CheckEmail?email=${email}`)
    const exists = await res.json();
    return exists;
  }
  catch (error) {
    console.error(
      "There was a problem while checking the Email:",
    )
  }
}
export async function checkCompanyURLorName(url: string, name: string) {
  try {
    const queryParams = new URLSearchParams({
      url: url,
      name: name,
    }).toString();

    const response = await fetch(
      `${APIURL}/MasterData/CheckCompanyURLorName?${queryParams}`,
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const exists = await response.json();
    return exists;
  } catch (error) {
    console.error(
      "There was a problem while checking the Company data:",
      error,
    );
    return null;
  }
}

export async function checkCompanyLocation(company: {
  name: string;
  cityId: string;
  url: string;
}) {
  try {
    const queryParams = new URLSearchParams({
      name: company.name,
      cityId: company.cityId,
      url: company.url,
    }).toString();

    const response = await fetch(
      `${APIURL}/MasterData/CheckCompanyWithoutId?${queryParams}`,
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const exists = await response.json();
    return exists;
  } catch (error) {
    console.error(
      "There was a problem while checking the Company data:",
      error,
    );
    return null;
  }
}

export async function CompanyLocationExists(companyId: string, cityId: string) {
  try {
    const response = await fetch(
      `${APIURL}/MasterData/CompanyLocationExists?companyId=${companyId}&cityId=${cityId}`,
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const exists = await response.json();
    return exists;
  } catch (error) {
    console.error(
      "There was a problem while checking the University data:",
      error,
    );
    return null;
  }
}

export async function FetchCities(search = "", pageNumber = 1, pageSize = 15) {
  const res = await fetch(
    `${APIURL}/MasterData/ConfirmedCities?pageNumber=${pageNumber}&pageSize=${pageSize}&search=${search}`,
  );
  const cities = await res.json();
  return cities;
}

export async function checkUnconfirmedCompany(
  companyName: string,
  cityName: string,
  url: string,
) {
  try {
    const queryParams = new URLSearchParams({
      companyName,
      cityName,
      url,
    }).toString();

    const response = await fetch(
      `${APIURL}/MasterData/CheckUnconfirmedCompany?${queryParams}`,
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const exists = await response.json();
    return exists;
  } catch (error) {
    console.error(
      "There was a problem while checking the Company data:",
      error,
    );
    return null;
  }
}

export async function checkUnconfirmedUniversity(
  universityName: string,
  cityName: string,
  url: string,
) {
  try {
    const queryParams = new URLSearchParams({
      universityName,
      cityName,
      url,
    }).toString();

    const response = await fetch(
      `${APIURL}/MasterData/CheckUnconfirmedUniversity?${queryParams}`,
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const exists = await response.json();
    return exists;
  } catch (error) {
    console.error(
      "There was a problem while checking the University data:",
      error,
    );
    return null;
  }
}

export const fetchCompanyLocations = async (companyName?: string) => {
  const response = await fetch(
    `${APIURL}/Quarantine/CompanyLocations?${companyName ? `companyName=${companyName}&` : ""}pageNumber=${1}&pageSize=${500}`
  );
  try {
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to Fetch CompanyLocations.");
    }
  }
  catch (error) {
    console.error("Error:", error);
  }
}

export const getRank = async (roleId:string, instituteId:string, isCompany:boolean) => {
       const response = await fetch(`${APIURL}/Candidates/${roleId}/Institute/${instituteId}/${isCompany}/GetRank`);
       try {
        if (response.ok) {
          const data = await response.json();
          return data;
        } else {
          throw new Error("Failed to Fetch Institute Rank.");
        }
      }
      catch (error) {
        console.error("Error:", error);
      }
}

export const CompanyExists = async(companyId:string) => {
  const url = `/CompanyExists/${companyId}`;
  try {
      const response = await fetch(url, { method: 'GET' });
      if (response.ok) {
          const exists = await response.json();
          return exists; 
      } else if (response.status === 404) {
          return false;
      } else {
          console.error("Unexpected response:", response);
          return false;
      }
  } catch (error) {
      console.error("Error calling CompanyExists API:", error);
      return false;
  }
}

// returns company id
export const CompanyExistsByUrlAndName = async (url:string, name:string) => {
  const apiUrl = `/CompanyExistsByUrlAndName?url=${encodeURIComponent(url)}&name=${encodeURIComponent(name)}`;
  try {
      const response = await fetch(apiUrl, { method: 'GET' });
      if (response.ok) {
          const companyId = await response.json();
          return companyId;
      } else if (response.status === 404) {
          return null;
      } else {
          return null;
      }
  } catch (error) {
      return null;
  }
};

