import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { isSameMonth, addDays, addMonths, addYears } from "date-fns";
import { CalendarDaysIcon, XCircleIcon } from "@heroicons/react/20/solid";
import { InformationCircleIcon } from "@heroicons/react/24/outline";

import SearchDropDown from "../../../shared/SearchDropDown";
import { InputField } from "../../../shared/InputField";
import ToggleSwitch from "../../../shared/ToggleSwitch";
import useCityandState from "../../../../hooks/useCityandState";
import NewInstitutionEntryModal from "../../../shared/NewInstitutionEntryModal";
import { GetUniversities } from "../../../../WebCalls";
import ReactTooltip from "../../../shared/ReactTooltip";
import { dropDownValueType, University } from "../../../../Models";
import useShippingEducationStates from "../Hooks/useShippingEducationStates";
import {
  addSeventeenYears,
  checkFirstEducation,
  isWithinOneToFourYears,
  isWithinOneToTwoYears,
  isWithinThreeToFourYears,
} from "../../../../Helpers";

type ShippingEducationFieldsType = {
  educationState: ReturnType<typeof useShippingEducationStates>;
  cityandState: ReturnType<typeof useCityandState>;
  inEditMode: boolean;
  setOpenError: React.Dispatch<React.SetStateAction<boolean>>;
  dob: Date | null;
};

export const ShippingEducation = ({
  educationState,
  cityandState,
  inEditMode,
  setOpenError,
  dob,
}: ShippingEducationFieldsType) => {
  const storedDate = localStorage.getItem("currentDate");
  const currentDate = storedDate && new Date(storedDate);
  const [showInstitutionEntry, setShowInstitutionEntry] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [universitiesData, setUniversitiesData] = useState<University[]>([]);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const [errors, setErrors] = useState({
    period: {
      touched: false,
      error: "",
    },
  });

  const [instituteTypeValues, setInstituteTypeValues] = useState<
    dropDownValueType[]
  >([
    { id: "1", name: "Maritime Academy" },
    { id: "2", name: "University" },
  ]);

  const {
    isStudent,
    setIsStudent,
    universityName,
    setUniversityName,
    newUniversityName,
    setNewUniversityName,
    eduStartDate,
    setEduStartDate,
    eduEndDate,
    setEduEndDate,
    expectedGraduation,
    setExpectedGraduation,
    weeklyAvailability,
    setWeeklyAvailability,
    setIsPostgraduate,
    isRelevantEducation,
    setIsRelevantEducation,
    relevantEducation,
    setRelevantEducation,
    newUniversityUrl,
    setnewUniversityUrl,
    degreeType,
    setDegreeType,
    univCity,
    setUnivCity,
    univClosestCity,
    setUnivClosestCity,
    univSubCity,
    setUnivSubCity,
    showDescription,
    setShowDescription,
    studyField,
    setStudyField,
    instituteName,
    setInstituteName,
    instituteType,
    setInstituteType,
    newStudyFieldName,
    setNewStudyFieldName,
    education,
    studyFieldValues
  } = educationState;

  const { states, tempState, setTempState } = cityandState;

  const tooltipConfigs = [
    {
      id: "relevant-education-tooltip",
      content:
        "Is your degree directly related to the requirements of the job?",
    },
    {
      id: "availability-tooltip",
      content:
        "Please provide a ballpark figure. Timings will be discussed more specifically during the interview.",
    },
    {
      id: "description-tooltip",
      content: "Provide additional details about your educational background.",
    },
  ];

  const eduMinStartDate = education.find((e) => {
    return !e.postGrad && e.endDate;
  })?.endDate;

  // const {
  //   data: universitiesData,
  //   setPageNumber,
  //   setSearchTerm,
  // } = useFetchInstitutions(GetUniversities);

  useEffect(() => {
    const fetchUniversities = async () => {
      const data: University[] = await GetUniversities(searchTerm, 1, 500);
      setUniversitiesData(data);
    };
    fetchUniversities();
  }, [tempState, searchTerm]);

  const handleSetDegreeType = (value: any) => {
    setDegreeType(value);
    setIsPostgraduate(value?.name.toLowerCase() === "postgraduate");
  };

  const handleNewUniNameClose = () => {
    setNewUniversityName("");
    setnewUniversityUrl("");
    setUnivSubCity("");
    setUnivCity({ id: "", name: "" });
    setUniversityName({ id: "", name: "" });
    setTempState({ id: "", name: "" });
  };

  const handleNewFieldNameClose = () => {
    setNewStudyFieldName("");
    setStudyField({ id: "", name: "" });
    setUnivSubCity("");
    setUnivCity({ id: "", name: "" });
    setUniversityName({ id: "", name: "" });
  };

  const handleEducationStartDateChange = (date: Date) => {
    if (!date) return;
    const isBefore21 = dob  && checkFirstEducation(dob, date);
    if (education.length===0 && isBefore21 === false) {
      setEduStartDate(null);
        setEduEndDate(null);
      setErrors((prevErrors) => ({
        ...prevErrors,
        period: {
          ...prevErrors.period,
          touched: true,
          error: "The first education should be before 21 years of age.",
        },
      }));
      return;
    }
  
    if (!eduEndDate) {
      setEduStartDate(date ? addDays(date, 1) : null);
      setErrors((prevErrors) => ({
        ...prevErrors,
        period: { ...prevErrors.period, touched: false, error: "" },
      }));
      return;
    }
  
    if (eduEndDate && instituteType?.name.toLowerCase() === "maritime academy") {
      if (isWithinOneToFourYears(date, eduEndDate)) {
        setEduStartDate(date ? addDays(date, 1) : null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: { ...prevErrors.period, touched: false, error: "" },
        }));
      } else {
        setEduStartDate(null);
        setEduEndDate(null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: {
            ...prevErrors.period,
            touched: true,
            error: "Maritime Education Period should be between 1 to 4 years",
          },
        }));
      }
      return;
    }
  
    if (eduEndDate && degreeType?.name.toLowerCase() === "undergraduate") {
      if (isWithinThreeToFourYears(date, eduEndDate)) {
        setEduStartDate(date ? addDays(date, 1) : null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: { ...prevErrors.period, touched: false, error: "" },
        }));
      } else {
        setEduStartDate(null);
        setEduEndDate(null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: {
            ...prevErrors.period,
            touched: true,
            error: "Undergraduate Education Period should be between 3 to 4 years",
          },
        }));
      }
      return;
    } else if (eduEndDate && degreeType?.name.toLowerCase() === "postgraduate") {
      if (isWithinOneToTwoYears(date, eduEndDate)) {
        setEduStartDate(date ? addDays(date, 1) : null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: { ...prevErrors.period, touched: false, error: "" },
        }));
      } else {
        setEduStartDate(null);
        setEduEndDate(null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: {
            ...prevErrors.period,
            touched: true,
            error: "Postgraduate Education Period should be between 1 to 2 years",
          },
        }));
      }
      return;
    }
  
    setErrors((prevErrors) => ({
      ...prevErrors,
      period: {
        ...prevErrors.period,
        touched: true,
        error: "Please enter the duration of your education.",
      },
    }));
  };
  

  const handleEducationEndDateChange = (date: Date) => {
    if (!date) return;

    if (!eduStartDate) {
      setEduStartDate(null);
      setEduEndDate(null);
      setErrors((prevErrors) => ({
        ...prevErrors,
        period: {
          ...prevErrors.period,
          touched: true,
          error: "Please enter a start date before setting the end date.",
        },
      }));
      return;
    }

    if (instituteType?.name.toLowerCase() === "maritime academy") {
      if (isWithinOneToFourYears(eduStartDate, date)) {
        setEduEndDate(date ? addDays(date, 1) : null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: { ...prevErrors.period, touched: false, error: "" },
        }));
      } else {
        setEduStartDate(null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: {
            ...prevErrors.period,
            touched: true,
            error: "Maritime Education Period should be between 1 to 4 years.",
          },
        }));
      }
      return;
    }

    if (degreeType?.name.toLowerCase() === "undergraduate") {
      if (isWithinThreeToFourYears(eduStartDate, date)) {
        setEduEndDate(date ? addDays(date, 1) : null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: { ...prevErrors.period, touched: false, error: "" },
        }));
      } else {
        setEduStartDate(null);
        setEduEndDate(null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: {
            ...prevErrors.period,
            touched: true,
            error:
              "Undergraduate Education Period should be between 3 to 4 years.",
          },
        }));
      }
      return;
    }else if (degreeType?.name.toLowerCase() === "postgraduate") {
      if (isWithinOneToTwoYears(eduStartDate, date)) {
        setEduEndDate(date ? addDays(date, 1) : null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: { ...prevErrors.period, touched: false, error: "" },
        }));
      } else {
        setEduStartDate(null);
        setEduEndDate(null);
        setErrors((prevErrors) => ({
          ...prevErrors,
          period: {
            ...prevErrors.period,
            touched: true,
            error:
              "PostGraduate Education Period should be between 1 to 2 years.",
          },
        }));
      }
      return;
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      period: {
        ...prevErrors.period,
        touched: true,
        error: "Please enter the duration of your education.",
      },
    }));
  };

  return (
    <div className="space-y-4 lg:grid lg:space-y-0 lg:grid-cols-6 lg:gap-x-6 gap-y-4">
      <div className="col-span-2 flex items-end w-full">
        <SearchDropDown
          label="Institute Type"
          placeHolder="Select Institute Type"
          dropDownValues={instituteTypeValues}
          state={instituteType}
          setState={(newvalue)=>{
            setEduEndDate(null);
            setEduStartDate(null);
            setInstituteType(newvalue)
          }}
          mandatoryField
          // apiRequest={{
          //   setPageNumber,
          //   setSearchTerm,
          // }}
          dropDownOnly
          hideOthers
          error="Please select an Institute Type"
        />
      </div>
      {/* Dropdown Universities */}
      {universityName && universityName.name.toLowerCase() !== "other" && (
        <div className="col-span-2 flex items-end w-full">
          <SearchDropDown
            label="Institute"
            placeHolder="Select your Academy or University..."
            dropDownValues={universitiesData}
            state={universityName}
            setState={setUniversityName}
            mandatoryField
            hyperLink={{
              label: "Can't find yours? Click here to add it.",
              action: () => {
                setShowInstitutionEntry(true);
              },
            }}
            apiRequest={{
              setPageNumber,
              setSearchTerm,
            }}
            hideOthers
            disable={instituteType == undefined}
            error="Please select an Institute."
          />
        </div>
      )}
      {universityName && universityName.name.toLowerCase() === "other" && (
        <div className="relative col-span-2 flex items-end justify-end w-full">
          <InputField
            label="University"
            value={newUniversityName}
            mandatoryField
            isDisabled
          />
          <XCircleIcon
            onClick={handleNewUniNameClose}
            className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
          />
        </div>
      )}
      {instituteType && instituteType.name === "University" && (
        <div
          className={`flex items-end ${
            isStudent ? "col-span-2" : "col-span-2"
          }`}
        >
          <SearchDropDown
            label="Degree Type"
            placeHolder="Choose Degree Type"
            dropDownValues={[
              { id: "1", name: "Undergraduate" },
              { id: "2", name: "Postgraduate" },
            ]}
            state={degreeType}
            setState={(newvalue)=>{
              setEduEndDate(null);
              setEduStartDate(null);
              setDegreeType(newvalue)
            }}
            dropDownOnly
            hideOthers
            mandatoryField
            error="Please select a degree type."
          />
        </div>
      )}
      {instituteType && instituteType.name === "Maritime Academy" && (
        <div
          className={`flex items-end ${
            isStudent ? "col-span-2" : "col-span-2"
          }`}
        >
          <SearchDropDown
            label="Field of Study"
            placeHolder="Choose Field of Study"
            dropDownValues={studyFieldValues}
            state={studyField}
            setState={setStudyField}
            dropDownOnly
            mandatoryField
            disable={universityName?.name === ""}
            error="Please select Field of Study."
          />
        </div>
      )}
      {studyField && studyField.name.toLowerCase() === "other" && (
        <div className="relative col-span-2 flex items-end justify-end w-full">
          <InputField
            label="Field of Study"
            value={newStudyFieldName}
            setValue={setNewStudyFieldName}
            mandatoryField
            placeHolder="Type new Field of Study"
            error="Please Type a new Field of Study"
          />
          <XCircleIcon
            onClick={handleNewFieldNameClose}
            className="w-6 h-6 bg-white rounded-full absolute bottom-1 right-0 mr-1 cursor-pointer  fill-red-600"
          />
        </div>
      )}
      <div
        className={`flex flex-col justify-end ${
          isStudent ? "col-span-1" : "col-span-2"
        }`}
      >
        <label className="text-sm font-medium text-gray-900">
          Period
          <span className="text-red-600 pl-1">*</span>
        </label>
        <div className="flex flex-col sm:flex-row pt-2 gap-6">
          <div className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
            <label htmlFor="edu-start-date">
              <span className="w-10 sm:w-fit flex items-center h-8">From</span>
            </label>
            <ReactDatePicker
              id="edu-start-date"
              className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm leading-5"
              selected={eduStartDate}
              maxDate={new Date()}
              minDate={
                dob
                  ? new Date(
                      dob.getFullYear() + 17,
                      dob.getMonth(),
                      dob.getDate(),
                    )
                  : null
              }
              onChange={(date) => {
                date && handleEducationStartDateChange(date);
              }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="eg. 01/2021"
              closeOnScroll
              showIcon
              icon={
                <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
              }
              calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
              onFocus={(e) => {
                if (/iPhone|iPad/i.test(navigator.userAgent)) {
                  e.target.readOnly = true;
                }
              }}
              onBlur={() =>
                setErrors((prev) => ({
                  ...prev,
                  period: { ...prev.period, touched: true },
                }))
              }
              disabled={
                instituteType === undefined ||
                (instituteType.name === "University" &&
                  degreeType === undefined)
              }
            />
          </div>
          {!isStudent && (
            <div className="text-sm w-full font-medium leading-6 text-gray-900 flex items-end gap-2">
              <label htmlFor="edu-end-date">
                <span className="w-10 sm:w-fit flex items-center h-8">To</span>
              </label>
              <ReactDatePicker
                id="edu-end-date"
                className="block w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm leading-5"
                selected={eduEndDate}
                onChange={(date) => {
                  date && handleEducationEndDateChange(date);
                }}
                dateFormat="MM/yyyy"
                showMonthYearPicker
                minDate={eduStartDate || null}
                maxDate={new Date()}
                filterDate={(date) => {
                  if (eduStartDate) {
                    return !isSameMonth(date, eduStartDate);
                  }
                  return true;
                }}
                placeholderText="eg. 06/2024"
                closeOnScroll
                showIcon
                icon={
                  <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
                }
                calendarClassName="!shadow-md !font-sans !border-1 !border-gray-200"
                disabled={!eduStartDate}
                onFocus={(e) => {
                  if (/iPhone|iPad/i.test(navigator.userAgent)) {
                    e.target.readOnly = true;
                  }
                }}
                onBlur={() =>
                  setErrors((prev) => ({
                    ...prev,
                    period: { ...prev.period, touched: true },
                  }))
                }
              />
            </div>
          )}
        </div>
        {errors.period.touched && (
          <p className="text-xs text-red-500 mt-1">{errors.period.error}</p>
        )}
      </div>

      {isStudent && (
        <div className="sm:col-span-2 xl:col-span-3 bg-slate- flex items-end gap-6">
          <div className="text-sm font-medium leading-6 text-gray-900">
            <label htmlFor="expected-grad">
              Expected date of Graduation
              <span className="text-red-600 pl-1">*</span>
            </label>
            <ReactDatePicker
              id="expected-grad"
              className="block mt-2 w-full rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm leading-5"
              selected={expectedGraduation}
              minDate={addMonths(new Date(), 1)}
              onChange={(date) => {
                date && setExpectedGraduation(addDays(date, 1));
                date && setEduEndDate(addDays(date, 1));
              }}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="eg. 04/2025"
              closeOnScroll
              showIcon
              icon={
                <CalendarDaysIcon className="text-gray-400 absolute bottom-0" />
              }
              disabled={!eduStartDate}
            />
          </div>
          <div className="w-full">
            <div className="flex gap-2">
              <label className="flex text-sm font-medium leading-6 text-gray-900">
                Weekly Availability
                <span className="text-red-600 pl-1">*</span>
                <InformationCircleIcon
                  data-tooltip-id="availability-tooltip"
                  className="w-6 h-6 ml-2"
                />
              </label>
            </div>
            <input
              type="number"
              className="mt-2 block w-full font-medium rounded-md border-0 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm leading-6"
              value={weeklyAvailability}
              onChange={(e) =>
                setWeeklyAvailability(() => parseInt(e.target.value))
              }
              placeholder="eg. 8"
            />
          </div>
        </div>
      )}
      <div
        className={`flex flex-col justify-end ${
          isStudent ? "sm:col-span-3 xl:col-span-2" : "col-span-3"
        }`}
      >
        <div className="flex flex-col sm:flex-row gap-4 sm:gap-0 items-end w-full">
          <div className="flex gap-2 w-full">
            <label
              htmlFor="relevanteedu"
              className="flex text-sm font-medium leading-6 text-gray-900"
            >
              Relevant to Job
              <InformationCircleIcon
                data-tooltip-id="relevant-education-tooltip"
                className="w-6 h-6 ml-2"
              />
            </label>
            <ToggleSwitch
              id="relevantedu"
              checked={isRelevantEducation}
              setState={setIsRelevantEducation}
            />
          </div>
          {/* <div className="flex gap-2 w-full flex-col">
            <label
              htmlFor="adddescription"
              className="flex text-sm font-medium leading-6 text-gray-900"
            >
              Add Description
              <InformationCircleIcon
                data-tooltip-id="description-tooltip"
                className="w-6 h-6 ml-2"
              />
            </label>
            <ToggleSwitch
              id="adddescript"
              checked={showDescription}
              setState={setShowDescription}
            />
          </div> */}
        </div>
        {showDescription && (
          <textarea
            rows={1}
            className="mt-2 block w-full font-medium rounded-md border-0 py-1.5 sm:py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-entntorange text-sm sm:leading-6"
            value={relevantEducation}
            onChange={(e) => setRelevantEducation(e.target.value)}
            placeholder="Please provide any comments here"
          />
        )}
      </div>
      {tooltipConfigs.map((tooltip) => (
        <ReactTooltip
          key={tooltip.id}
          id={tooltip.id}
          content={tooltip.content}
        />
      ))}
      <NewInstitutionEntryModal
        title="Add Institution Details"
        type="uni"
        open={showInstitutionEntry}
        setOpen={setShowInstitutionEntry}
        cityandState={cityandState}
        setOpenError={setOpenError}
        inEditMode={inEditMode}
        institutionsFields={{
          institutionName: universityName,
          setInstitutionName: setUniversityName,
          newInstitutionName: newUniversityName,
          setNewInstitutionName: setNewUniversityName,
          newInstitutionUrl: newUniversityUrl,
          setNewInstitutionUrl: setnewUniversityUrl,
          institutionCity: univCity,
          setInstitutionCity: setUnivCity,
          instClosestCity: univClosestCity,
          setInstClosestCity: setUnivClosestCity,
          instSubCity: univSubCity,
          setInstSubCity: setUnivSubCity,
        }}
      />
    </div>
  );
};
